import React from 'react';
import "../landing.scss";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';

import 'swiper/css/navigation';

function TestimonialSlider() {
    return (
        <>

            <section className="testimonials_section">
                {/* <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="commonHeading text-center">Players Love Ace Fantasy League</h2>
                            <div className="featureBack_title testiHeading">Testimonials</div>

                            <div className="position-relative testBlockParent">
                                <div className="testimonialBlock">
                                    <div className="swiper testSwiper">

                                        <Swiper
                                            modules={[Autoplay, Navigation]}
                                            slidesPerView={1}
                                            speed={100}
                                            autoplay={{
                                                delay: 5000,
                                                disableOnInteraction: false,
                                            }}
                                            className="mySwiper"
                                        >
                                            <SwiperSlide>
                                                <div className="swiper-slide" >
                                                    <div className="testimonial_card">
                                                        <img src="/images/website/user.jpg" alt="" />
                                                        <div className="userName">Racky Jhonson</div>
                                                        <div className="userDesgination">Director</div>
                                                        <p className="userContent_review">"I've tried several fantasy sports apps, but none compare to AFL. The variety of game modes and the chance to win reward coins keep me coming back for more!" - Mayank Kaushal</p>
                                                        <div className="userWinAmt">Won ₹25 Lakhs</div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>

                                            <SwiperSlide>
                                                <div className="swiper-slide" >
                                                    <div className="testimonial_card">
                                                        <img src="/images/website/user.jpg" alt="" />
                                                        <div className="userName">Racky Jhonson</div>
                                                        <div className="userDesgination">Director</div>
                                                        <p className="userContent_review">"I've tried several fantasy sports apps, but none compare to AFL. The variety of game modes and the chance to win reward coins keep me coming back for more!" - Mayank Kaushal</p>
                                                        <div className="userWinAmt">Won ₹25 Lakhs</div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>

                                            <SwiperSlide>
                                                <div className="swiper-slide" >
                                                    <div className="testimonial_card">
                                                        <img src="/images/website/user.jpg" alt="" />
                                                        <div className="userName">Racky Jhonson</div>
                                                        <div className="userDesgination">Director</div>
                                                        <p className="userContent_review">"I've tried several fantasy sports apps, but none compare to AFL. The variety of game modes and the chance to win reward coins keep me coming back for more!" - Mayank Kaushal</p>
                                                        <div className="userWinAmt">Won ₹25 Lakhs</div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>

                                        </Swiper>
                                    </div>
                                </div>

                                <div class="testiArrowGroup">
                                    <button class="backArrowBtn" type="button">
                                        <img src="./img/theme_2_arrow.svg" alt="" />
                                    </button>
                                    <button class="nextArrowBtn" type="button">
                                        <img src="./img/theme_2_arrow.svg" alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section >
        </>
    );
}

export default TestimonialSlider;