import { Link, useLocation } from "react-router-dom"
import { server } from "../../../common/Apis/axiosBaseURL";

const BannerView = () => {
    const { state } = useLocation();
    return (
        <section className='mainSection'>

            <div className='title_breadcrumb_section'>
                <div className='title_page'>Banner Detail</div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/banner-manager/list">Banner</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Banner detail</li>
                    </ol>
                </nav>
            </div>

            <div className="common_section_main">

            <div className="view_user_list_details">
                <div className="row g-md-5 g-4">
                    <div className="col-12 col-xl-6">
                        <div className="detailBlock">
                            <label> Sequence :</label>
                            <span> {state?.sequence} </span>
                        </div>
                    </div>

                    <div className="col-12 col-xl-6">
                        <div className="detailBlock">
                            <label> Match Name :</label>
                            <span> {state?.all_match?.localteam} vs {state?.all_match?.visitorteam} </span>
                        </div>
                    </div>

                    <div className="col-12 col-xl-6">
                        <div className="detailBlock">
                            <label> Series Name :</label>
                            <span>{state?.series?.name} </span>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label className="img_three_type"> Banner Image :</label>
                                    <span>
                                        <div className="view_img">
                                            <img src={`${server.baseURL}/${state?.image}`} className="img-fluid" alt="Banner" />
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>


        </section>
    )
}

export default BannerView