import { Table } from "react-bootstrap"
import TableHead from "../../Components/TableHead/TableHead"
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ScheduleAssignedListAction } from "../../../features/CommonSlice";
import NoDataFound from "../../Components/NoDataFound/NoDataFound";
import { BsEyeFill } from "react-icons/bs";

const ContestAssignedList = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [listDataArr, setListDataArr] = useState([]);

    useEffect(() => {
        ScheduleAssignedListAction({ match_id: id }, (response) => {
            if (response?.status === true) {
                setListDataArr(response?.data)
            }
        })();
    }, [ScheduleAssignedListAction])


    let tableHeader = [
        { label: "#", key: "", sort: false },
        { label: "Game Mode", key: "", sort: false },
        { label: "Category name", key: "", sort: false },
        { label: "Contest mode", key: "", sort: false },
        { label: "Winning amount INR", key: "", sort: false },
        { label: "Contest size", key: "", sort: false },
        { label: "Contest type", key: "", sort: false },
        { label: "Entry fee INR", key: "", sort: false },
        { label: "Bonus coin", key: "", sort: false },
        { label: "Contest status", key: "", sort: false },
        { label: "Action", key: "", sort: false }
    ]


    return (
        <section className='mainSection'>

            <div className='title_breadcrumb_section'>
                <div className='title_page'> Details For Added Contest </div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/upcoming-contest/list"> Upcoming Contest </Link></li>
                        <li className="breadcrumb-item active" aria-current="page"> Added Contest </li>
                    </ol>
                </nav>
            </div>

            <div className="common_section_main">

            <div className='tab_main_section_list'>
                <Table responsive className="themeTable">
                    <TableHead data={tableHeader} />
                    <tbody>
                        {listDataArr?.length > 0 ?
                            listDataArr?.map((value, index) => (
                                <tr key={index}>
                                    <td> {index + 1} </td>
                                    <td> {value?.gamemode?.mode_name ? value?.gamemode?.mode_name : "-"} </td>
                                    <td> {value?.category?.category_name ? value?.category?.category_name : "-"} </td>
                                    <td> {value?.contest_mode == 1 ? "Cash Contest" : "Coin Contest"} </td>
                                    <td> {value?.winning_amount ? value?.winning_amount : "-"} </td>
                                    <td> {value?.contest_size ? value?.contest_size : "-"} </td>
                                    <td> {value?.contest_type ? value?.contest_type : "-"} </td>
                                    <td> {value?.entry_fee ? value?.entry_fee : "-"} </td>
                                    <td> {value?.bonus_coin ? value?.bonus_coin : "-"} </td>
                                    <td> {value?.status ? value?.status == 0 ? "Not Active" : "Active" : "-"} </td>
                                    <td>
                                        <div className="btnTableGroup">
                                            <button
                                                type="button"
                                                onClick={() => navigate(`/admin/upcoming-contest/contest/assigned/detail/${id}/${value?.id}`, { state: value })}
                                            >
                                                <BsEyeFill />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                            :
                            <>
                                <tr>
                                    <td colSpan={7} className='not_found_data_td'>
                                        <NoDataFound />
                                    </td>
                                </tr>
                            </>
                        }
                    </tbody>
                </Table>
            </div>
            </div>

        </section>
    )
}

export default ContestAssignedList