import React from 'react';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from "yup";
import { FieldText } from '../../Components/InputText/InputText';
import TextErrorMsg from '../../Components/InputText/TextErrorMsg';
import { Button } from '../../Components/Button/Button';

const NotificationsSend = () => {

    return (
        <div className='addLeagueBlock notifications_send'>
            <div className='title_breadcrumb_section'>
                <div className='title_page'>Send Notifications</div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Send notifications</li>
                    </ol>
                </nav>
            </div>
            <div className='common_section_main'>
                <div className="addLeagueForm_block add_staff_page user_list_edit">
                    <div className="formAddBlock">
                        <Formik
                            initialValues={{
                                title: "",
                                description: "",
                            }}
                            validationSchema={() =>
                                Yup.object().shape({
                                    title: Yup.string().required("Title is required"),
                                    description: Yup.string().required("Description is required"),
                                })
                            }
                        >
                            {(formik) => {
                                return (
                                    <Form>
                                        <div className="row g-3 g-md-5">
                                            <div className="col-12">
                                                <label> Title </label>
                                                <FieldText
                                                    name="title"
                                                    type="text"
                                                    placeholder="Title"
                                                    value={formik.values.title}
                                                    label="Title"
                                                />
                                            </div>

                                            <div className='col-12'>
                                                <Field name="description">
                                                    {({ form, field }) => {
                                                        return (
                                                            <>
                                                                <label> Notification </label>
                                                                <ReactQuill
                                                                    className="cms-content-editor"
                                                                    name={field.name}
                                                                    value={formik.values.description}
                                                                    onChange={(value) => form.setFieldValue(field.name, value)}
                                                                />
                                                            </>
                                                        );
                                                    }}
                                                </Field>
                                                <ErrorMessage name="description" component={TextErrorMsg} />
                                            </div>

                                            <div className="col-12">
                                                <Button
                                                    className="themeBtn edit_page_btn"
                                                    text='Sent'
                                                    type="submit"
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>

                <div className='title_breadcrumb_section mt-4 mt-md-5'>
                    <div className='title_page'>Notification History</div>
                </div>
            </div>
        </div>
    )
}

export default NotificationsSend