import React, { useEffect, useState } from "react"
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FieldText } from "../../Components/InputText/InputText";
import { Button } from "../../Components/Button/Button";
import { CategoryListAction, ContestCreateAction, GameModeListAction } from "../../../features/CommonSlice";
import TextErrorMsg from "../../Components/InputText/TextErrorMsg";

const ContestAdd = () => {
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false);
    const [calculatedEntryFee, setCalculatedEntryFee] = useState("");
    const [categoryListData, setCategoryListData] = useState([]);
    const [gameModeListData, setGameModeListData] = useState([]);

    useEffect(() => {
        CategoryListAction("", (response) => {
            if (response?.status === true) {
                setCategoryListData(response?.data)
            }
        })();
    }, [CategoryListAction])

    useEffect(() => {
        GameModeListAction((response) => {
            if (response?.status === true) {
                setGameModeListData(response?.data)
            }
        })();
    }, [GameModeListAction])

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        useEffect(() => {
            const calculateEntryFee = () => {
                let winning_amount = Number(formikFrom.values.winning_amount)
                let contest_size = Number(formikFrom.values.contest_size)
                let admin_commission = Number(formikFrom.values.admin_commission)
                if (winning_amount !== undefined && contest_size !== undefined && admin_commission !== undefined) {
                    const entryFee = (winning_amount / contest_size) + (admin_commission / 100 * (winning_amount / contest_size));
                    setCalculatedEntryFee(entryFee.toFixed(2));
                    formikFrom.setFieldValue("entry_fee", entryFee.toFixed(2));
                }
            };
            calculateEntryFee();
        }, [formikFrom.values.winning_amount, formikFrom.values.contest_size, formikFrom.values.admin_commission]);
    }

    return (
        <div className="addLeagueBlock">
            <div className='title_breadcrumb_section'>
                <div className='title_page'> Add Contest </div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/contest-manager/list"> Contest </Link></li>
                        <li className="breadcrumb-item active" aria-current="page"> Add Contest </li>
                    </ol>
                </nav>
            </div>

            <div className="common_section_main">

            <div className="addLeagueForm_block add_staff_page user_list_edit">
                <div className="formAddBlock">
                    <Formik
                        initialValues={{
                            game_mode_id: "",
                            category_id: "",
                            contest_mode: "",
                            contest_type: "",
                            contest_size: "",
                            admin_commission: "",
                            winning_amount: "",
                            entry_fee: "",
                            confirmed_winning: false,
                            multiple_team: false,
                            auto_create: false,
                            bonus_coin: ""
                        }}
                        validationSchema={Yup.object().shape({
                            game_mode_id: Yup.string().required("Game mode is required!"),
                            category_id: Yup.string().required("Category name is required!"),
                            contest_mode: Yup.string().required("Contest mode is required!"),
                            contest_type: Yup.string().required("Contest type is required!"),
                            contest_size: Yup.string()
                                .required("Contest size is required!")
                                .test('is-greater-than-zero', 'Contest size must be greater than 0', value => parseInt(value) > 0),
                            admin_commission: Yup.string().required("Admin commission is required!"),
                            winning_amount: Yup.string().required("Winning amount is required!"),
                            entry_fee: Yup.string().required("Entry fee is required!")
                        })}
                        onSubmit={(value) => {
                            setLoader(true)
                            let data = {
                                ...value,
                                bonus_coin: value.bonus_coin ? value.bonus_coin : 0,
                                contest_size: value.contest_size.toString(),
                                admin_commission: value.contest_type === "Free" ? "0" : value.admin_commission.toString(),
                                winning_amount: value.contest_type === "Free" ? "0" : value.winning_amount.toString(),
                                entry_fee: value.contest_type === "Free" ? "0" : value.entry_fee.toString(),
                                confirmed_winning: value?.confirmed_winning ? 1 : 0,
                                multiple_team: value?.multiple_team ? 1 : 0,
                                auto_create: value?.auto_create ? 1 : 0
                            }
                            ContestCreateAction(data, (response) => {
                                if (response?.status === true) {
                                    if (value.contest_type == "Paid") {
                                        navigate(`/admin/contest-manager/contest-breakup/${response?.data?.id}`, { state: response?.data })
                                    } else {
                                        navigate(`/admin/contest-manager/list`)
                                    }
                                }
                                setLoader(false)
                            })()
                        }}
                    >
                        {(formik) => {
                            return (
                                <Form autoComplete='off'>
                                    <FormikFromFunc />
                                    <div className="row g-3 g-md-5">
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <label> Game Mode </label>
                                            <Field
                                                as="select"
                                                name="game_mode_id"
                                                className="select-control form-control w-100"
                                                value={formik.values.game_mode_id}
                                            >
                                                <option value=""> Select Game Mode </option>
                                                {gameModeListData?.filter(data => data.status)?.map((value, index) => (
                                                    <option value={value?.id} key={index}>{value?.mode_name}</option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="game_mode_id" component={TextErrorMsg} />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <label> Category Name</label>
                                            <Field
                                                as="select"
                                                name="category_id"
                                                className="select-control form-control w-100"
                                                value={formik.values.category_id}
                                            >
                                                <option value=""> Select Category </option>
                                                {categoryListData?.filter(data => data.status)?.map((value, index) => (
                                                    <option value={value?.id} key={index}>{value?.category_name}</option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="category_id" component={TextErrorMsg} />
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-12">
                                            <label> Contest Mode </label>
                                            <Field
                                                as="select"
                                                name="contest_mode"
                                                className="select-control form-control w-100"
                                                value={formik.values.contest_mode}
                                            >
                                                <option value=""> Select contest mode </option>
                                                {["Cash Contest", "Coin Contest"].map((value, index) => (
                                                    <option value={index + 1} key={index}> {value} </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="contest_mode" component={TextErrorMsg} />
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-12">
                                            <label> Contest Type </label>
                                            <Field
                                                as="select"
                                                name="contest_type"
                                                className="select-control form-control w-100"
                                                value={formik.values.contest_type}
                                                onChange={(e) => {
                                                    formik.setValues({
                                                        ...formik.values,
                                                        contest_type: e.target.value,
                                                        admin_commission: 0,
                                                        winning_amount: 0,
                                                        entry_fee: 0
                                                    });
                                                }}
                                            >
                                                <option value=""> Select contest type </option>
                                                {["Free", "Paid"].map((value, index) => (
                                                    <option value={value} key={index}> {value} </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="contest_type" component={TextErrorMsg} />
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-12">
                                            <FieldText
                                                name="contest_size"
                                                type="number"
                                                placeholder="Contest size (Team size)"
                                                value={formik.values.contest_size}
                                                label="Contest size (Team size)"
                                                showlabel={true}
                                            />
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-12">
                                            <FieldText
                                                name="winning_amount"
                                                type="number"
                                                placeholder="Winning amount (INR)"
                                                value={formik.values.winning_amount}
                                                label="Winning amount (INR)"
                                                showlabel={true}
                                                disabled={formik.values.contest_type === "Free"}
                                            />
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-12">
                                            <FieldText
                                                name="admin_commission"
                                                type="number"
                                                placeholder="Admin commission"
                                                value={formik.values.admin_commission}
                                                label="Admin commission (%)"
                                                showlabel={true}
                                                disabled={formik.values.contest_type === "Free"}
                                            />
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-12">
                                            <FieldText
                                                name="entry_fee"
                                                type="number"
                                                placeholder="Entry fee (INR) "
                                                label="Entry fee (INR) "
                                                showlabel={true}
                                                value={calculatedEntryFee}
                                                disabled={formik.values.contest_type === "Free" || calculatedEntryFee}
                                            />
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-12">
                                            <FieldText
                                                name="bonus_coin"
                                                type="number"
                                                placeholder="Bonus coin"
                                                label="Bonus coin (optional)"
                                                showlabel={true}
                                            />
                                        </div>

                                        <div className="col-12">
                                            <div className="label_check_box">
                                                <label htmlFor="confirm_1"> Confirmed winning even if the contest remains unfilled. </label>
                                                <Field className="common_check_box" type="checkbox" id="confirm_1" name="confirmed_winning" />
                                            </div>
                                            <div className="label_check_box">
                                                <label htmlFor="multiple_team_1"> Join with multiple teams. </label>
                                                <Field className="common_check_box" type="checkbox" id="multiple_team_1" name="multiple_team" />
                                            </div>
                                            <div className="label_check_box">
                                                <label htmlFor="auto_create_1"> Auto create </label>
                                                <Field className="common_check_box" type="checkbox" id="auto_create_1" name="auto_create" />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <Button
                                                className="themeBtn edit_page_btn"
                                                text='Submit'
                                                type="submit"
                                                loader={loader}
                                                disabled={!(formik.isValid && formik.dirty) || loader}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>

            </div>
        </div>
    )
}

export default ContestAdd