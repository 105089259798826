import { Table } from "react-bootstrap"
import TableHead from "../../Components/TableHead/TableHead"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ContestListAction, ScheduleAssignmentAction } from "../../../features/CommonSlice";
import NoDataFound from "../../Components/NoDataFound/NoDataFound";
import { Button } from "../../Components/Button/Button";
import { ToastOverError } from "../../../common/Toast/ToastOver";

const UpcomingContestAssign = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { state } = useLocation();
    const [loader, setLoader] = useState(false)
    const [listDataArr, setListDataArr] = useState([]);
    const [selectedContests, setSelectedContests] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        ContestListAction({}, (response) => {
            if (response?.status === true) {
                setListDataArr(response?.data)
                let listing = response?.data.sort((a, b) => a.id - b.id);
                let matchContestIds = state.match_contest_ids.sort((a, b) => a - b);
                if (state) {
                    const matchedObjects = listing.map(value => {
                        const matchedId = matchContestIds.find(id => id === value.id);
                        return matchedId ? listing.find(obj => obj.id === matchedId) : null;
                    });
                    setSelectedContests(matchedObjects)
                }
            }
        })();
    }, [ContestListAction])

    const filterArray = (data) => {
        const filteredData = data.filter(item => item !== null && item !== undefined);
        return filteredData.length;
    }

    useEffect(() => {
        setSelectAll(filterArray(selectedContests) === listDataArr.length);
    }, [selectedContests, listDataArr]);

    const handleSelectAllChange = () => {
        if (!selectAll) {
            setSelectedContests([...listDataArr]);
        } else {
            setSelectedContests([]);
        }
        setSelectAll(!selectAll);
    };

    let tableHeader = [
        {
            label: (
                <input
                    type="checkbox"
                    name="select_all"
                    className="common_check_box"
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                />
            ),
            key: "select_all",
            sort: false,
        },
        { label: "Game Mode", key: "", sort: false },
        { label: "Category name", key: "", sort: false },
        { label: "Contest mode", key: "", sort: false },
        { label: "Winning amount INR", key: "", sort: false },
        { label: "Contest size", key: "", sort: false },
        { label: "Contest type", key: "", sort: false },
        { label: "Entry fee INR", key: "", sort: false },
        { label: "Bonus coin", key: "", sort: false },
    ]


    const handleCheckboxChange = (index) => {
        const updatedSelectedContests = [...selectedContests];
        if (!updatedSelectedContests[index]) updatedSelectedContests[index] = listDataArr[index];
        else updatedSelectedContests[index] = undefined;
        setSelectedContests(updatedSelectedContests);
    };

    const handleSubmitContest = () => {
        const finalObj = selectedContests.filter(item => item !== null && item !== undefined);
        const data = finalObj.map(value => value.id)
        if (data?.length > 0) {
            setLoader(true)
            ScheduleAssignmentAction({ match_id: id, contest_id: data }, (response) => {
                if (response?.status === true) {
                    navigate("/admin/upcoming-contest/list")
                }
                setLoader(false)
            })();
        } else {
            ToastOverError("Please select any one contest")
        }

    }

    return (
        <section className='mainSection'>

            <div className='title_breadcrumb_section'>
                <div className='title_page'> Add Contest For a match </div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/upcoming-contest/list"> Upcoming Contest </Link></li>
                        <li className="breadcrumb-item active" aria-current="page"> Add Contest </li>
                    </ol>
                </nav>
            </div>

            <div className="common_section_main">

            <div className='searchAddRowTable'>
                <Button
                    className="themeBtn view_page_btn contest_btn"
                    text='Save Contest'
                    type="submit"
                    loader={loader}
                    disabled={loader}
                    onClick={handleSubmitContest}
                />
            </div>

            <div className='tab_main_section_list'>
                <Table responsive className="themeTable contest_table">
                    <TableHead data={tableHeader} />
                    <tbody>
                        {listDataArr?.length > 0 ?
                            listDataArr.sort((a, b) => a.id - b.id)?.map((value, index) => (
                                <tr key={index}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            name="select_contest"
                                            checked={selectedContests[index] || false}
                                            onChange={() => handleCheckboxChange(index)}
                                            className="common_check_box"
                                        />
                                    </td>
                                    <td> {value?.gamemode?.mode_name ? value?.gamemode?.mode_name : "-"} </td>
                                    <td> {value?.category?.category_name ? value?.category?.category_name : "-"} </td>
                                    <td> {value?.contest_mode == 1 ? "Cash Contest" : "Coin Contest"} </td>
                                    <td> {value?.winning_amount ? value?.winning_amount : "-"} </td>
                                    <td> {value?.contest_size ? value?.contest_size : "-"} </td>
                                    <td> {value?.contest_type ? value?.contest_type : "-"} </td>
                                    <td> {value?.entry_fee ? value?.entry_fee : "-"} </td>
                                    <td> {value?.bonus_coin ? value?.bonus_coin : "-"} </td>
                                </tr>
                            ))
                            :
                            <>
                                <tr>
                                    <td colSpan={7} className='not_found_data_td'>
                                        <NoDataFound />
                                    </td>
                                </tr>
                            </>
                        }
                    </tbody>
                </Table>
            </div>
            </div>

        </section>
    )
}

export default UpcomingContestAssign