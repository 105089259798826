const HandleError = error => {
    if (error?.message === "Unauthorized please login again!" || error?.message === "Unauthorized") {
        localStorage.removeItem("afl_security_data");
        // toast.error(error.message)
        setTimeout(() => {
            window.location.href = "/"
        }, 700);
    }
};

export default HandleError;