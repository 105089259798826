import React, { useEffect, useState } from "react";
import "../Style.scss";
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { FieldText } from "../../Components/InputText/InputText";
import { Button } from "../../Components/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { SettingsUpdateAction, SettinsListAction } from '../../../features/CommonSlice';

const Settings = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false)
    const [listDataArr, setListDataArr] = useState({});
    useEffect(() => {
        SettinsListAction((response) => {
            if (response?.status === true) {
                setListDataArr(response?.data)
            }
        })();
    }, [SettinsListAction])

    console.log(listDataArr);
    return (
        <div className="addLeagueBlock">
            <div className='title_breadcrumb_section'>
                <div className='title_page'>Settings</div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Settings</li>
                    </ol>
                </nav>
            </div>
            <div className="common_section_main">

            <div className="addLeagueForm_block add_staff_page user_list_edit">
                <div className="formAddBlock">
                    {Object.keys(listDataArr)?.length > 0 ?
                        <Formik
                            initialValues={{
                                admin_email: listDataArr?.admin_email,
                                usable_bonus_percentage: listDataArr?.usable_bonus_percentage,
                                referral_bouns_amount: listDataArr?.referral_bouns_amount,
                                tds: listDataArr?.tds,
                                contest_commission: listDataArr?.contest_commission,
                                min_withdraw_amount: listDataArr?.min_withdraw_amount,
                                max_withdraw_amount: listDataArr?.max_withdraw_amount,
                                max_deposit_amount: listDataArr?.max_deposit_amount,
                                min_deposit_amount: listDataArr?.min_deposit_amount,
                                unannounced_player_point: listDataArr?.unannounced_player_point
                            }}
                            validationSchema={Yup.object().shape({
                                admin_email: Yup.string().required("Email is required!"),
                                usable_bonus_percentage: Yup.string().required("Usable Bonus Percentage is required!"),
                                referral_bouns_amount: Yup.string().required("Cash Bouns Amount is required!"),
                                tds: Yup.string().required("TDS is required!"),
                                contest_commission: Yup.string().required("Contest Commission is required!"),
                                min_withdraw_amount: Yup.string().required("Minimum Withdraw Amount is required!"),
                                max_withdraw_amount: Yup.string().required("Maximum Withdraw Amount is required!"),
                                max_deposit_amount: Yup.string().required("Maximum Deposit Amount is required!"),
                                min_deposit_amount: Yup.string().required("Minimum Deposit Amount is required!"),
                                unannounced_player_point: Yup.string().required("Unannounced player point is required!"),
                            })}
                            onSubmit={(value) => {
                                setLoader(true)
                                SettingsUpdateAction(value, (response) => {
                                    // if (response?.status) {

                                    // }
                                    setLoader(false)
                                })();
                            }}
                        >
                            {(formik) => {
                                return (
                                    <Form>
                                        <div className="row g-3 g-md-5">

                                            <div className="col-12 col-md-6">
                                                <FieldText
                                                    showlabel={true}
                                                    label="Admin Email"
                                                    name="admin_email"
                                                    type="email"
                                                    placeholder="Admin Email"
                                                    value={formik.values.admin_email}
                                                />
                                            </div>


                                            <div className="col-12 col-md-6">
                                                <FieldText
                                                    showlabel={true}
                                                    label="Usable Bonus Percentage"
                                                    name="usable_bonus_percentage"
                                                    type="number"
                                                    placeholder="Usable Bonus Percentage"
                                                    value={formik.values.usable_bonus_percentage}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <FieldText
                                                    showlabel={true}
                                                    label="Cash Bouns Amount (INR)"
                                                    name="referral_bouns_amount"
                                                    type="number"
                                                    placeholder="Cash Bouns Amount"
                                                    value={formik.values.referral_bouns_amount}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <FieldText
                                                    showlabel={true}
                                                    label="Contest Commission (In percentage)"
                                                    name="contest_commission"
                                                    type="number"
                                                    placeholder="Contest Commission"
                                                    value={formik.values.contest_commission}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <FieldText
                                                    showlabel={true}
                                                    label="Unannounced player point (Bed Apple)"
                                                    name="unannounced_player_point"
                                                    type="number"
                                                    placeholder="Unannounced player point"
                                                    value={formik.values.unannounced_player_point}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <FieldText
                                                    showlabel={true}
                                                    label="Minimum Withdraw Amount (INR)"
                                                    name="min_withdraw_amount"
                                                    type="number"
                                                    placeholder="Minimum Withdraw Amount"
                                                    value={formik.values.min_withdraw_amount}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <FieldText
                                                    showlabel={true}
                                                    label="Maximum Withdraw Amount (INR)"
                                                    name="max_withdraw_amount"
                                                    type="number"
                                                    placeholder="Maximum Withdraw Amount"
                                                    value={formik.values.max_withdraw_amount}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <FieldText
                                                    showlabel={true}
                                                    label="Maximum Deposit Amount (INR)"
                                                    name="max_deposit_amount"
                                                    type="number"
                                                    placeholder="Maximum Deposit Amount"
                                                    value={formik.values.max_deposit_amount}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <FieldText
                                                    showlabel={true}
                                                    label="Minimum Deposit Amount (INR)"
                                                    name="min_deposit_amount"
                                                    type="number"
                                                    placeholder="Minimum Deposit Amount"
                                                    value={formik.values.min_deposit_amount}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <FieldText
                                                    showlabel={true}
                                                    label="TDS (On minimum 30,000 INR)"
                                                    name="tds"
                                                    type="number"
                                                    placeholder="TDS"
                                                    value={formik.values.tds}
                                                />
                                            </div>

                                            <div className="col-12">
                                                <Button
                                                    className="themeBtn edit_page_btn"
                                                    text='Submit'
                                                    type="submit"
                                                    loader={loader}
                                                    disabled={!(formik.isValid && formik.dirty) || loader}
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                        :
                        "Loading..."
                    }
                </div>
            </div>
            </div>
        </div>
    )
}

export default Settings;