
import React, { useEffect, useState } from 'react';
import { PointSystemAction, cmsDetailsAction } from '../../../features/webCommonSlice';
import { Tab, Tabs } from 'react-bootstrap';
import NoDataFound from '../../Components/NoDataFound/NoDataFound';

const CricketPointSystem = () => {
    const [loader, setLoader] = useState(false)
    const [pointSystemDetails, setPointSystemDetails] = useState({});
    const [cmsDetails, setCmsDetails] = useState({});

    useEffect(() => {
        setLoader(true)
        cmsDetailsAction({ slug: "point-system" }, (response) => {
            if (response?.status === true) {
                setCmsDetails(response?.data)
            }
        })();
        PointSystemAction(1, (response) => {
            if (response?.status === true) {
                setPointSystemDetails(response?.data)
            }
            setLoader(false)
        })();
    }, [cmsDetailsAction, PointSystemAction])

    return (
        <div className='cms_landing_page'>
            <div className='banner'> Fantasy Point System  </div>

            <div className='top_content_points'>
                <div className='heading_points'>
                    {Object.keys(cmsDetails)?.length > 0 ?
                        <div dangerouslySetInnerHTML={{ __html: cmsDetails.description }}></div>
                        :
                        null
                    }
                </div>
            </div>
            <div className='points_system_table'>
                <div className='tab_list_points'>
                    <Tabs defaultActiveKey="Cricket" className='sportsTab'>
                        <Tab eventKey="Cricket" title={<span><img src="/images/icon_cricket.svg" alt="" /> Cricket</span>}>
                            <div className='mini-container'>
                                <Tabs defaultActiveKey="T20" className='matchType'>
                                    <Tab eventKey="T20" title="T20">
                                        <div className='point_table_box_inner'>
                                            <div className='point_table_header'>
                                                <h3>Batting Points</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit, ea.</p>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Run </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T20?.run} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Boundary Bonus </h5>
                                                    </div>
                                                    <div className="green pointbox">{pointSystemDetails?.T20?.fours}</div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Six Bonus </h5>
                                                    </div>
                                                    <div className="green pointbox">{pointSystemDetails?.T20?.sixes}</div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext">30 runs scored by a batsman in a single innings</h5>
                                                    </div>
                                                    <div className="green pointbox">{pointSystemDetails?.T20?.thirty_run}</div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Half-Century Bonus </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T20?.half_century} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Century Bonus </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T20?.century} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext">Dismissal for a duck <small> (Batter, Wicket-Keeper, & All-Rounder) </small></h5>
                                                    </div>
                                                    <div className="orange pointbox"> {pointSystemDetails?.T20?.duck}  </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='point_table_box_inner'>
                                            <div className='point_table_header bowling'>
                                                <h3>Bowling Points</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit, ea.</p>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Every wicket taken (excluding run out) </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T20?.wicket} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext">3 wickets</h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T20?.three_wicket} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext">4 wickets</h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T20?.four_wicket} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext">5 wickets</h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T20?.five_wicket} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Maiden Over </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T20?.maiden_over} </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='point_table_box_inner'>
                                            <div className='point_table_header fielding'>
                                                <h3>Fielding Points</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit, ea.</p>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext">Catch taken</h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T20?.catch} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> 3 Catch taken </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T20?.three_catch} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Stumping </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T20?.stumping} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Run Out (direct) </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T20?.directrunout} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Run Out (Stumping) </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T20?.runoutstumping} </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='point_table_box_inner'>
                                            <div className='point_table_header other'>
                                                <h3>Other Points</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit, ea.</p>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Captain </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T20?.captain} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Vice-captain </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T20?.vice_captain} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Being a part of the starting XI </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T20?.starting11} </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="ODI" title="ODI">
                                        <div className='point_table_box_inner'>
                                            <div className='point_table_header'>
                                                <h3>Batting Points</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit, ea.</p>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Run </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.ODI?.run} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Boundary Bonus </h5>
                                                    </div>
                                                    <div className="green pointbox">{pointSystemDetails?.ODI?.fours}</div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Six Bonus </h5>
                                                    </div>
                                                    <div className="green pointbox">{pointSystemDetails?.ODI?.sixes}</div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Half-Century Bonus </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.ODI?.half_century} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Century Bonus </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.ODI?.century} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext">Dismissal for a duck <small>(Batter, Wicket-Keeper, & All-Rounder)</small></h5>
                                                    </div>
                                                    <div className="orange pointbox"> {pointSystemDetails?.ODI?.duck}  </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='point_table_box_inner'>
                                            <div className='point_table_header bowling'>
                                                <h3>Bowling Points</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit, ea.</p>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Every wicket taken (excluding run out) </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.ODI?.wicket} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext">4 wickets</h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.ODI?.four_wicket} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext">5 wickets</h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.ODI?.five_wicket} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Maiden Over </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.ODI?.maiden_over} </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='point_table_box_inner'>
                                            <div className='point_table_header fielding'>
                                                <h3>Fielding Points</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit, ea.</p>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext">Catch taken</h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.ODI?.catch} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> 3 Catch taken </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T20?.three_catch} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Stumping </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.ODI?.stumping} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Run Out (direct) </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.ODI?.directrunout} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Run Out (Stumping) </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.ODI?.runoutstumping} </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='point_table_box_inner'>
                                            <div className='point_table_header other'>
                                                <h3>Other Points</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit, ea.</p>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Captain </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.ODI?.captain} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Vice-captain </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.ODI?.vice_captain} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Being a part of the starting XI </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.ODI?.starting11} </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="TEST" title="TEST">
                                        <div className='point_table_box_inner'>
                                            <div className='point_table_header'>
                                                <h3>Batting Points</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit, ea.</p>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Run </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.TEST?.run} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Boundary Bonus </h5>
                                                    </div>
                                                    <div className="green pointbox">{pointSystemDetails?.TEST?.fours}</div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Six Bonus </h5>
                                                    </div>
                                                    <div className="green pointbox">{pointSystemDetails?.TEST?.sixes}</div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Half-Century Bonus </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.TEST?.half_century} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Century Bonus </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.TEST?.century} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext">Dismissal for a duck <small> (Batter, Wicket-Keeper, & All-Rounder) </small></h5>
                                                    </div>
                                                    <div className="orange pointbox"> {pointSystemDetails?.TEST?.duck}  </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='point_table_box_inner'>
                                            <div className='point_table_header bowling'>
                                                <h3>Bowling Points</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit, ea.</p>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Every wicket taken (excluding run out) </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.TEST?.wicket} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext">4 wickets</h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.TEST?.four_wicket} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext">5 wickets</h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.TEST?.five_wicket} </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='point_table_box_inner'>
                                            <div className='point_table_header fielding'>
                                                <h3>Fielding Points</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit, ea.</p>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext">Catch taken</h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.TEST?.catch} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Stumping </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.TEST?.stumping} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Run Out (direct) </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.TEST?.directrunout} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Run Out (Stumping) </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.TEST?.runoutstumping} </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='point_table_box_inner'>
                                            <div className='point_table_header other'>
                                                <h3>Other Points</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit, ea.</p>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Captain </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.TEST?.captain} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Vice-captain </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.TEST?.vice_captain} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Being a part of the starting XI </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.TEST?.starting11} </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="T10" title="T10">
                                        <div className='point_table_box_inner'>
                                            <div className='point_table_header'>
                                                <h3>Batting Points</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit, ea.</p>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Run </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T10?.run} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Boundary Bonus </h5>
                                                    </div>
                                                    <div className="green pointbox">{pointSystemDetails?.T10?.fours}</div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Six Bonus </h5>
                                                    </div>
                                                    <div className="green pointbox">{pointSystemDetails?.T10?.sixes}</div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext">30 runs scored by a batsman in a single innings</h5>
                                                    </div>
                                                    <div className="green pointbox">{pointSystemDetails?.T10?.thirty_run}</div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Half-Century Bonus </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T10?.half_century} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext">Dismissal for a duck <small> (Batter, Wicket-Keeper, & All-Rounder) </small></h5>
                                                    </div>
                                                    <div className="orange pointbox"> {pointSystemDetails?.T10?.duck}  </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='point_table_box_inner'>
                                            <div className='point_table_header bowling'>
                                                <h3>Bowling Points</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit, ea.</p>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Every wicket taken (excluding run out) </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T10?.wicket} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext">2 wickets</h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T10?.two_wicket} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext">3 wickets</h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T10?.three_wicket} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Maiden Over </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T10?.maiden_over} </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='point_table_box_inner'>
                                            <div className='point_table_header fielding'>
                                                <h3>Fielding Points</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit, ea.</p>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext">Catch taken</h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T10?.catch} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> 3 Catch taken </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T10?.three_catch} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Stumping </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T10?.stumping} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Run Out (direct) </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T10?.directrunout} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Run Out (Stumping) </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T10?.runoutstumping} </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='point_table_box_inner'>
                                            <div className='point_table_header other'>
                                                <h3>Other Points</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit, ea.</p>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Captain </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T10?.captain} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Vice-captain </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T10?.vice_captain} </div>
                                                </li>
                                                <li>
                                                    <div className='w-100'>
                                                        <h5 className="pointtext"> Being a part of the starting XI </h5>
                                                    </div>
                                                    <div className="green pointbox"> {pointSystemDetails?.T10?.starting11} </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </Tab>
                        <Tab eventKey="Football" title={<span><img src="/images/icon_football.svg" alt="" /> Football</span>}>
                            <div className='mini-container'>
                                <NoDataFound />
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default CricketPointSystem