import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Table } from 'react-bootstrap';
import { BiSearch } from 'react-icons/bi';
import { Link, useNavigate } from 'react-router-dom';
import { ScheduleListAction, SeriesListAction } from '../../../features/CommonSlice';
import { HasConsecutiveSpaces, RemoveEmptyObjKey, debounceSearch } from '../../../utils/Function';
import NoDataFound from '../../Components/NoDataFound/NoDataFound';
import { Pagination } from '../../Components/Hooks/Pagination';
import moment from 'moment';

const UpcomingContest = () => {
    const navigate = useNavigate()
    const [statusUpdate, setStatusUpdate] = useState(1)
    const [loader, setLoader] = useState(false)
    const [listDataArr, setListDataArr] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [perPageCount, setPerPageCount] = useState(30);
    const [searchString, setSearchString] = useState("");
    const [selectedTabID, setSelectedTabID] = useState(1);
    const [selectedSportTabStatus, setSelectedSportTabStatus] = useState(1);
    const [seriesID, setSeriesID] = useState("");
    const [seriesListArr, setSeriesListArr] = useState([])

    useEffect(() => {
        let payload = {
            page: currentPage,
            pageSize: perPageCount,
            searchText: searchString,
            sports_id: selectedTabID,
            match_type: selectedSportTabStatus,
            series_id: seriesID,
        }
        setLoader(true)
        ScheduleListAction(RemoveEmptyObjKey(payload), (response) => {
            if (response?.status === true) {
                setListDataArr(response)
            }
            setLoader(false)
        })();
    }, [ScheduleListAction, perPageCount, currentPage, searchString, selectedTabID, statusUpdate, selectedSportTabStatus, seriesID])

    useEffect(() => {
        SeriesListAction({ page: 0, sports_id: selectedTabID }, (response) => {
            if (response?.status === true) {
                setSeriesListArr(response?.data)
            }
        })();
    }, [SeriesListAction, selectedTabID])

    const handleFilters = debounceSearch((e) => {
        if (HasConsecutiveSpaces(e.target.value)) return <></>;
        setSearchString(e.target.value)
    }, 300);

    console.log("listDataArr", listDataArr)

    return (
        <>
            <section className='mainSection upcoming_page_main'>
                <div className='title_breadcrumb_section'>
                    <div className='title_page'>Schedule Contest</div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Schedule contest</li>
                        </ol>
                    </nav>
                </div>

                <div className='common_section_main'>

                    <div className='tab_main_section_list'>
                        <Tabs defaultActiveKey={1} onSelect={(e) => {
                            setSelectedTabID(Number(e))
                            setSeriesID("")
                        }}>
                            {["Cricket", "Football"].map((value, index) => (
                                <Tab eventKey={index + 1} title={value} key={index}>
                                    <Tabs className='second_tab_section' defaultActiveKey={1} onSelect={(e) => {
                                        setSelectedSportTabStatus(Number(e))
                                        setSeriesID("")
                                    }}>
                                        {["Upcoming", "Live", "Result"].map((data, index) => (
                                            <Tab
                                                eventKey={
                                                    data == "Upcoming" ? 1 :
                                                        data == "Live" ? 3 :
                                                            2
                                                }
                                                title={data}
                                                key={index}
                                            >
                                                <div className='searchAddRowTable'>
                                                    <div className='inputGroupSearch search_input'>
                                                        <input type='text' className='form-control' onChange={handleFilters} placeholder='Search here ..' />
                                                        <button type='button'><BiSearch /></button>
                                                    </div>

                                                    <div className='inputGroupSearch search_input'>
                                                        <select className='form-control select_white' onChange={(e) => setSeriesID(e.target.value)}>
                                                            <option value=""> Select Series </option>
                                                            {seriesListArr.sort((a, b) => a.name.localeCompare(b.name))?.map((value, index) => (
                                                                <option value={value?.cid} key={index}>{value?.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <Table responsive className="themeTable contest_table">
                                                    <thead>
                                                        <tr>
                                                            <th> #</th>
                                                            <th>Team 1 vs Team 2 </th>
                                                            <th>Series Name </th>
                                                            <th>Date </th>
                                                            <th>Time </th>
                                                            {data == "Upcoming" && <th>Modified </th>}
                                                            {data != "Upcoming" && <th> Active contest </th>}
                                                            <th>No of contest </th>
                                                            {data == "Upcoming" && <th>Add contest </th>}
                                                            {data == "Upcoming" && <th>Auto contest count</th>}
                                                            {data == "Result" && <th> Distribute Winning Amount</th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!loader ?
                                                            listDataArr?.data?.list?.length > 0 ?
                                                                listDataArr?.data?.list?.map((value, index) => (
                                                                    <tr key={index}>
                                                                        <td> {(currentPage - 1) * Number(listDataArr?.data?.perpage_count) + (index + 1)}. </td>
                                                                        <td> {value?.localteam} vs {value?.visitorteam} </td>
                                                                        <td> {value["series.name"] ? value["series.name"] : "-"} </td>
                                                                        <td> {value?.date_ist ? moment(value?.date_ist).format("DD-MM-YYYY") : "-"} </td>
                                                                        <td> {value?.time_ist ? moment(value?.time_ist, ["HH:mm"]).format("hh:mm") : "-"} </td>
                                                                        {data == "Upcoming" && <td> {value?.updatedAt ? moment(value?.updatedAt).format("DD-MM-YYYY") : "-"} </td>}
                                                                        {data !== "Upcoming" && <td> {value?.active_contest_count ? value?.active_contest_count : "0"} </td>}
                                                                        <td> <button className='table_btn contest_no' type="button" onClick={() => navigate(`/admin/upcoming-contest/contest/assigned/list/${value.match_id}`)}> {value?.match_contest_count} Contest </button> </td>
                                                                        {data == "Upcoming" && <td> <button className='table_btn contest_no2' type="button" onClick={() => navigate(`/admin/upcoming-contest/contest/assign/${value.match_id}`, { state: { match_contest_ids: value?.match_contest_ids } })}> Add Contest </button> </td>}
                                                                        {data == "Upcoming" && <td>
                                                                            {value?.auto_contest_create_count || 0}
                                                                        </td>
                                                                        }
                                                                        {data == "Result" && <td>
                                                                            {
                                                                                value?.win_flag == 0 ? "Distributed" : "Not Distributed"
                                                                            }
                                                                        </td>}
                                                                    </tr>
                                                                ))
                                                                :
                                                                <tr>
                                                                    <td colSpan={10} className='not_found_data_td'>
                                                                        <NoDataFound />
                                                                    </td>
                                                                </tr>
                                                            :
                                                            <tr>
                                                                <td colSpan={12}>Loading...</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </Table>

                                                {listDataArr?.data?.list?.length > 0 ?
                                                    <div className='pagination_entries_section'>
                                                        <div className='entries_text'>
                                                            <select className='common_input_filed' onChange={(e) => setPerPageCount(e.target.value)}>
                                                                <option value={30}>30</option>
                                                                <option value={60}>60</option>
                                                                <option value={80}>80</option>
                                                                <option value={100}>100</option>
                                                            </select>
                                                        </div>

                                                        <Pagination
                                                            className="pagination-bar"
                                                            currentPage={currentPage}
                                                            totalCount={listDataArr?.data?.totalcount}
                                                            pageSize={listDataArr?.data?.perpage_count}
                                                            onPageChange={(page) => setCurrentPage(page)}
                                                        />
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </Tab>
                                        ))}
                                    </Tabs>

                                </Tab>
                            ))}
                        </Tabs>
                    </div>
                </div>

            </section>
        </>
    )
}

export default UpcomingContest