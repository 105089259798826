import React, { useEffect, useState } from "react"
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';
import * as Yup from "yup";
import { FieldText } from "../../Components/InputText/InputText";
import { Button } from "../../Components/Button/Button";
import { GameModeCreateAction, GameModeUpdateAction } from "../../../features/CommonSlice";
import { ToastOverError } from "../../../common/Toast/ToastOver";
import TextErrorMsg from "../../Components/InputText/TextErrorMsg";
import { server } from "../../../common/Apis/axiosBaseURL";

const GameModeAddEdit = ({ data, hide, setStatusUpdate }) => {
    const [loader, setLoader] = useState(false)
    const [formDataSaved, setFormDataSaved] = useState(false);

    const SUPPORTED_FILE_FORMAT = ["image/jpeg", "image/jpg", "image/png"]

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        useEffect(() => {
            if (!formDataSaved && data) {
                formikFrom.setValues({
                    sequence: data?.sequence,
                    mode_name: data?.mode_name,
                    description: data?.description,
                    image: data?.image
                })
                setFormDataSaved(true)
            }
        }, [data])
    }

    return (
        <div className='delete_modal_main'>
            <div className=''>
                <Formik
                    initialValues={{
                        sequence: "",
                        mode_name: "",
                        image: "",
                        description: ""
                    }}
                    validationSchema={Yup.object().shape({
                        sequence: Yup.number().required("Sequence is required!"),
                        mode_name: Yup.string().required("Game mode name is required!"),
                        description: Yup.string().required("Description is required"),
                        image: Yup.mixed().nullable().notRequired()
                            .test(
                                "FILE_FORMAT",
                                "Unsupported file format",
                                (value) => !value || (value && (typeof value === "object" ? SUPPORTED_FILE_FORMAT.includes(value?.type) : true))
                            ),
                    })}
                    onSubmit={(value) => {
                        setLoader(true)
                        const formData = new FormData();
                        for (const key in value) {
                            formData.append(key, value[key]);
                        }
                        if (data?.mode == "edit") {
                            formData.append("id", data?.id);
                            GameModeUpdateAction(formData, (response) => {
                                if (response?.status === true) {
                                    hide(false)
                                    setStatusUpdate(prevCount => prevCount + 1);
                                }
                                setLoader(false)
                            })()
                        } else {
                            GameModeCreateAction(formData, (response) => {
                                if (response?.status === true) {
                                    hide(false)
                                    setStatusUpdate(prevCount => prevCount + 1);
                                }
                                setLoader(false)
                            })()
                        }
                    }}
                >
                    {(formik) => {
                        return (
                            <Form autoComplete='off'>
                                <FormikFromFunc />
                                <div className="row g-3 g-md-5">
                                    <div className="col-12">
                                        <label className="commmon_label"> Gamemode icon </label>
                                        <div className="banner_img_box">
                                            <Field name="image">
                                                {({ form, field }) => {
                                                    return (
                                                        <>
                                                            <input name={field.name} type="file"
                                                                onChange={(e) => {
                                                                    if (SUPPORTED_FILE_FORMAT.includes(e.target.files[0]?.type)) {
                                                                        form.setFieldValue(field.name, e.target.files[0])
                                                                    } else {
                                                                        form.setFieldValue(field.name, "")
                                                                        e.target.value = null
                                                                        ToastOverError("Unsupported file format")
                                                                    }
                                                                }} />
                                                        </>
                                                    )
                                                }}
                                            </Field>
                                            <ErrorMessage name="image" component={TextErrorMsg} />

                                            <div className="d-block edit_img_modal">
                                                {
                                                    (data?.mode == "edit" && typeof formik?.values?.image == "string") &&
                                                    <img src={`${server.baseURL}/${formik?.values?.image}`} className="img-fluid" alt={formik.values.mode_name} />
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <FieldText
                                            name="mode_name"
                                            type="text"
                                            placeholder="Game Mode Name"
                                            value={formik.values.mode_name}
                                            label="Game Mode Name"
                                            showlabel={true}
                                            maxLength={20}
                                        />
                                    </div>

                                    <div className="col-12">
                                        <FieldText
                                            name="sequence"
                                            type="number"
                                            placeholder="Sequence Number"
                                            value={formik.values.sequence}
                                            label="Sequence"
                                            showlabel={true}
                                        />
                                    </div>

                                    <div className="col-12">
                                        <FieldText
                                            showlabel={true}
                                            name="description"
                                            as="textarea"
                                            placeholder="Description"
                                            value={formik.values.description}
                                            label="Description"
                                            rows={4}
                                            maxLength={300}
                                        />
                                        <small> 300 characters limit </small>
                                    </div>

                                    <div className="col-12">
                                        <Button
                                            className="themeBtn edit_page_btn"
                                            text='Submit'
                                            type="submit"
                                            loader={loader}
                                            disabled={!(formik.isValid && formik.dirty) || loader}
                                        />
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default GameModeAddEdit