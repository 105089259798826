import React, { useEffect, useState } from 'react';
import { GiTrophyCup } from 'react-icons/gi';
import { RxArrowTopRight, RxArrowBottomLeft } from 'react-icons/rx';
import { BiChip } from 'react-icons/bi';
import { AiFillAndroid, AiFillApple } from 'react-icons/ai';
import { FaUsers } from 'react-icons/fa';
import { TbClick } from 'react-icons/tb';
import { RiAdvertisementLine } from 'react-icons/ri';
import { faker } from '@faker-js/faker';
import '../Dashboard/Dashboard.scss';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { MdEmojiEvents, MdEventSeat, MdOutlinePayment, MdFemale, MdMale, MdTransgender, MdOutlineArrowDropUp } from 'react-icons/md';
import { DashboardAction } from "../../../features/CommonSlice";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Line Chart',
            color: 'white',
        },
    },

    scales: {
        x: {
            ticks: {
                color: 'white',
            },
        },
        y: {
            ticks: {
                color: 'white',
            },
        },
    },

};
const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'Octomber', 'November', 'December'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: labels.map(() => faker.number.int({ min: 100, max: 1000 })),
            borderColor: '#FDAA09',
            backgroundColor: '#144272',
            textcolor: '#fff',
        }

    ],
};
const Dashboard = () => {
    const [dashboardDataStats, setDashboardDataStats] = useState({});

    useEffect(() => {
        DashboardAction((response) => {
            if (response?.status === true) {
                setDashboardDataStats(response?.data)
            }
        })();
    }, [DashboardAction])

    return (
        <>
            <div className='container-fluid'>
                <div className='row g-5'>
                    <div className='col-12  col-md-6 col-lg-12 col-xxl-4'>
                        <div className="stats_card">
                            <div className='stats_card_top'>
                                <div className='stats_card_iconParnet'>
                                    <div className='stats_card_icon'><FaUsers /></div>
                                    <div className='infoGrap'>
                                        <div className='activeInfo'> Active</div>
                                        <div className='inactiveInfo'> Inactive</div>
                                    </div>
                                </div>
                                <div className='stats_card_title'>
                                    <span>Total Users</span>
                                    <h4> {dashboardDataStats?.user?.total || 0} </h4>
                                </div>
                            </div>
                            <div className='stats_card_divider'></div>
                            <div className='stats_card_bottom'>
                                <p>
                                    <strong>Users :</strong>
                                    <span className='activeBadge'> {dashboardDataStats?.user?.active || 0}</span>
                                    <span className='closeBadge'> {dashboardDataStats?.user?.inactive || 0} </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='col-12  col-md-6 col-lg-12 col-xxl-4'>
                        <div className="stats_card">
                            <div className='stats_card_top'>
                                <div className='stats_card_iconParnet'>
                                    <div className='stats_card_icon'><MdEmojiEvents /></div>
                                    <div className='infoGrap'>
                                        <div className='activeInfo'>Paid</div>
                                        <div className='inactiveInfo'>Free</div>
                                    </div>
                                </div>
                                <div className='stats_card_title'>
                                    <span> Total Contest </span>
                                    <h4> {dashboardDataStats?.contest?.total || 0} </h4>
                                </div>
                            </div>
                            <div className='stats_card_divider'></div>
                            <div className='stats_card_bottom'>
                                <p>
                                    <strong>Contests :</strong>
                                    <span className='activeBadge'> {dashboardDataStats?.contest?.paid || 0} </span>
                                    <span className='closeBadge'> {dashboardDataStats?.contest?.free || 0} </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='col-12  col-md-12 col-lg-12 col-xxl-4'>
                        <div className="stats_card">
                            <div className='stats_card_top'>
                                <div className='stats_card_iconParnet'>
                                    <div className='stats_card_icon'> <MdEventSeat />
                                    </div>
                                    <div className='infoGrap'>
                                        <div className='activeInfo'> Active </div>
                                        <div className='inactiveInfo'> Inactive </div>
                                    </div>
                                </div>
                                <div className='stats_card_title'>
                                    <span> Total Game Mode </span>
                                    <h4> {dashboardDataStats?.game_mode?.total || 0}</h4>
                                </div>
                            </div>
                            <div className='stats_card_divider'></div>
                            <div className='stats_card_bottom'>
                                <p>
                                    <strong>Bed Apple :</strong>
                                    <span className='activeBadge'> {dashboardDataStats?.game_mode?.bed_apple_active || 0}</span>
                                    <span className='closeBadge'> {dashboardDataStats?.game_mode?.bed_apple_inactive || 0} </span>
                                </p>
                                <p>
                                    <strong>Other :</strong>
                                    <span className='activeBadge'> {dashboardDataStats?.game_mode?.other_mode_active || 0} </span>
                                    <span className='closeBadge'> {dashboardDataStats?.game_mode?.other_mode_inactive || 0} </span>
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className='col-12  col-md-6 col-lg-12 col-xxl-6 map_col_dashboard'>
                        <div className='signupUserCard'>
                            <div className='signupUserCard_header'>
                                <div className='titleSignupUser'>
                                    Total Sign Up Users
                                    <span>135</span>
                                </div>
                            </div>
                            <div className='signupUserCard_body'>
                                <div className='mapImg'>
                                    <Line options={options} data={data} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-12  col-md-6 col-lg-12 col-xxl-3 mobile_col_dashboard'>
                        <div className='signupUserCard'>
                            <div className='signupUserCard_header'>
                                <div className='titleSignupUser'> Users By Mobile Device </div>
                            </div>
                            <div className='signupUserCard_body'>
                                <div className='deviceCard_mobile'>
                                    <div className='deviceCard_icon'><AiFillAndroid /></div>
                                    <div className='deviceCard_content'>
                                        <div className='deviceTitle'>Android Users</div>
                                        <div className='statsDevice'><FaUsers /> {dashboardDataStats?.device_user?.android || 0}</div>
                                    </div>
                                </div>

                                <div className='deviceCard_mobile'>
                                    <div className='deviceCard_icon'><AiFillApple />
                                    </div>
                                    <div className='deviceCard_content'>
                                        <div className='deviceTitle'>IOS Users</div>
                                        <div className='statsDevice'><FaUsers /> {dashboardDataStats?.device_user?.iphone || 0}</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* <div className='col-12  col-md-6 col-lg-12 col-xxl-3 activity_col_dashboard'>
                        <div className='signupUserCard'>
                            <div className='signupUserCard_header'>
                                <div className='titleSignupUser'>
                                    Our Activities
                                </div>
                            </div>
                            <div className='signupUserCard_body'>
                                <div className='activityParent'>
                                    <div className='actvitList'>
                                        <div className='actiIcon'>
                                            <TbClick />
                                        </div>
                                        <div className='actiTitle'>Clicks On The Ad Section</div>
                                        <div className='countAct'>
                                            12,345 <span className='upGrowth'>0.3%</span>
                                        </div>
                                    </div>

                                    <div className='actvitList'>
                                        <div className='actiIcon'>
                                            <RiAdvertisementLine />
                                        </div>
                                        <div className='actiTitle'>Users Bidding For Ads </div>
                                        <div className='countAct'>
                                            12,345 <span className='upGrowth'>0.3%</span>
                                        </div>
                                    </div>

                                    <div className='actvitList'>
                                        <div className='actiIcon'>
                                            <GiTrophyCup />
                                        </div>
                                        <div className='actiTitle'>Players Abandoning Leagues </div>
                                        <div className='countAct'>
                                            12,345 <span className='downGrowth'>0.3%</span>
                                        </div>
                                    </div>

                                    <div className='actvitList'>
                                        <div className='actiIcon'>
                                            <MdOutlinePayment />
                                        </div>
                                        <div className='actiTitle'>Payouts Per Week/End Of Season  </div>
                                        <div className='countAct'>
                                            12,345 <span className='upGrowth'>0.3%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}


                    {/* <div className='col-12  col-md-6 col-lg-12 col-xxl-4 mostSelect_col_dashboard'>
                        <div className='topPerformingClub'>
                            <div className='mostSelectCLub'>
                                Most selected club per game week
                                <span>233</span>
                            </div>
                            <div className='clubGroup_row'>
                                <div className='clubGroup_col'>
                                    <div className='clubGroup_col_icon'>
                                        <RxArrowTopRight />
                                    </div>
                                    <div className='clubGroup_col_title'>Top-performing club</div>
                                    <div className='clubGroup_col_count'>4</div>
                                </div>
                                <div className='clubGroup_col'>
                                    <div className='clubGroup_col_icon lowPerformingIcon'>
                                        <RxArrowBottomLeft />
                                    </div>
                                    <div className='clubGroup_col_title'>Low-performing club</div>
                                    <div className='clubGroup_col_count'>7</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-12  col-md-6 col-lg-12 col-xxl-5 chip_col_dashboard'>
                        <div className='topPerformingClub'>
                            <div className='mostSelectCLub'>
                                Total activated chips per game week 234324
                                <span>323</span>
                            </div>
                            <div className='chipsRow'>
                                <div className='chipsRowHeading'><BiChip /> Chip Purchases </div>
                                <div className='chipsRow_col'>
                                    Daily
                                    <span>54</span>
                                </div>
                                <div className='chipsRow_col'>
                                    Last 7 days
                                    <span>67</span>
                                </div>
                                <div className='chipsRow_col'>
                                    Last 30 days
                                    <span>78</span>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className='col-12 col-md-6 col-lg-12 col-xxl-3 gender_col_dashboard'>
                        <div className='topPerformingClub'>
                            <div className='mostSelectCLub'> Total Gender Users <span> {dashboardDataStats?.gender?.total || 0} </span> </div>
                            <div className='clubGroup_row'>
                                <div className='clubGroup_col gender_col'>
                                    <div className='clubGroup_col_icon'> <MdMale />  </div>
                                    <div className='parentMale'>
                                        <div className='clubGroup_col_title'>Male</div>
                                        <div className='clubGroup_col_count'> {dashboardDataStats?.gender?.male || 0} </div>
                                    </div>
                                </div>
                                <div className='clubGroup_col gender_col'>
                                    <div className='clubGroup_col_icon lowPerformingIcon'>  <MdFemale /> </div>
                                    <div className='parentMale'>
                                        <div className='clubGroup_col_title'>Female</div>
                                        <div className='clubGroup_col_count'> {dashboardDataStats?.gender?.female || 0} </div>
                                    </div>
                                </div>

                            </div>
                            <div className='transgenderCol'>
                                <div className='clubGroup_col_icon'>  <MdTransgender /> </div>
                                <div className='clubGroup_col_title'>Other</div>
                                <div className='clubGroup_col_count ms-auto'> 0 </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className='col-12  col-md-6 col-lg-12 col-xxl-3 favTeam_col_dashboard'>
                        <div className='topPerformingClub'>
                            <div className='mostSelectCLub'>
                                Most Favourite Team
                                <span>232</span>
                            </div>
                        </div>
                    </div>

                    <div className='col-12  col-md-6 col-lg-12 col-xxl-4 contribute_col_dashboard'>
                        <div className='topPerformingClub'>
                            <div className='mostSelectCLub'>
                                Total amount contributed across paid leagues
                                <span>512 </span>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

        </>
    )
}

export default Dashboard;
