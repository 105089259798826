import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './features/store';
const root = ReactDOM.createRoot(document.getElementById('root'));

const scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  
root.render(
    <Provider onClick={scrollToTop} store={store}>
        <App />
    </Provider>
);