import React from 'react'
import { Link } from 'react-router-dom';

function TheFooter() {
    return (
        <>
            <footer className="footer_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="footerLogo">
                                <img src="/images/logo.png" alt="" />
                            </div>
                            <div className="footerAddress">
                                <div className="footerAddress_content">
                                    <Link to="acefantasyleague@gmail.com">
                                    <img src="/images/website/mail_icon.svg" alt="" />
                                    acefantasyleague@gmail.com
                                    </Link>
                                </div>
                                <div className="footerAddress_content">
                                    <img src="/images/website/location_icon.svg" alt="" />
                                    Ace Fantasy League 20/A Civil Lines Delhi 110054
                                </div>
                            </div>

                            <div className="footerLinkMenu">
                                <div className='footer_menu_main'>
                                    <Link className='footer_link' to="/">Download App</Link>
                                    <Link className='footer_link' to="/how-it-works">How to Play</Link>
                                    <Link className='footer_link' to='/points-system'>Points System</Link>
                                    <Link className='footer_link' to="/fair-and-play">Fair Play Policy</Link>
                                    <Link className='footer_link' to="/about-us">About Us</Link>
                                    <Link className='footer_link' to="/faq">FAQs</Link>
                                    <Link className='footer_link' to="/rules">Rules</Link>
                                    <Link className='footer_link' to="/contact-us">Contact Us</Link>
                                </div>
                            </div>


                            <div className="copyRightBlock">
                                <div className="copyRightText">
                                    &copy; {new Date().getFullYear()} Ace Fantasy Leagues. All Rights Reserved
                                </div>
                                <ul className="footerLinkMenu ms-md-auto my-0">
                                    <div className='footer_menu_main'>
                                        <Link className='footer_link' to="/privacy-policy"> Privacy Policy </Link>
                                        <Link className='footer_link' to="/refund-cancellation"> Refunds and Cancellations </Link>
                                        <Link className='footer_link' to="/terms-conditions"> Terms & Conditions </Link>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <Link to="/" className="back_top">
                <img src="/images/website/theme_2_arrow.svg" alt="" />
            </Link>
        </>
    );
}

export default TheFooter;