import React, { useEffect, useState } from "react"
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';
import * as Yup from "yup";
import { FieldText } from "../../Components/InputText/InputText";
import TextErrorMsg from "../../Components/InputText/TextErrorMsg";
import { Button } from "../../Components/Button/Button";
import { ToastOverError } from "../../../common/Toast/ToastOver";
import { BannerCreateDetailsAction, BannerUpdateDetailsAction, MatchesListAction, SeriesListAction } from "../../../features/CommonSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { server } from "../../../common/Apis/axiosBaseURL";
import moment from "moment";

const BannerAddEdit = () => {
    const { state } = useLocation();
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [seriesListArr, setSeriesListArr] = useState([])
    const [matchListArr, setMatchListArr] = useState([])
    const [formDataSaved, setFormDataSaved] = useState(false);

    const SUPPORTED_FILE_FORMAT = ["image/jpeg", "image/jpg", "image/png"]

    const handleOnChange = (e, data) => {
        const { name, value } = e.target
        if (name == "sports_id") {
            SeriesListAction({ page: 0, sports_id: value }, (response) => {
                if (response?.status === true) {
                    setSeriesListArr(response?.data)
                }
            })();
        } else if (name == "series_id") {
            let payload = { page: 0, sports_id: data.values.sports_id, series_id: value }
            MatchesListAction(payload, (response) => {
                if (response?.status === true) {
                    setMatchListArr(response?.data)
                }
            })();
        }
    }

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        useEffect(() => {
            if (!formDataSaved && state) {
                SeriesListAction({ page: 0, sports_id: state?.sports_id }, (response) => {
                    if (response?.status === true) {
                        setSeriesListArr(response?.data)
                    }
                })();
                MatchesListAction({ page: 0, sports_id: state?.sports_id, series_id: state?.series_id }, (response) => {
                    if (response?.status === true) {
                        setMatchListArr(response?.data)
                    }
                })();

                formikFrom.setValues({
                    sequence: state?.sequence,
                    sports_id: state?.sports_id,
                    match_id: state?.match_id,
                    series_id: state?.series_id,
                    image: state?.image
                })
                setFormDataSaved(true)
            }
        }, [state])
    }

    return (
        <div className="addLeagueBlock">
            <div className='title_breadcrumb_section'>
                <div className='title_page'>Banner {state?.mode == "edit" ? "Edit" : "Add"}</div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/banner-manager/list">Banner</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Banner {state?.mode == "edit" ? "Edit" : "Add"}</li>
                    </ol>
                </nav>
            </div>

            <div className="common_section_main">
                <div className="addLeagueForm_block add_staff_page user_list_edit">
                    <div className="formAddBlock">
                        <Formik
                            initialValues={{
                                sequence: "",
                                sports_id: "",
                                match_id: "",
                                series_id: "",
                                image: ""
                            }}
                            validationSchema={Yup.object().shape({
                                sequence: Yup.number().required("Sequence is required!"),
                                sports_id: Yup.number().required("Sport type is required!"),
                                series_id: Yup.string().required("Series is required!"),
                                match_id: Yup.string().required("Match is required!"),
                                image: Yup.mixed().nullable().notRequired()
                                    .test(
                                        "FILE_FORMAT",
                                        "Unsupported file format",
                                        (value) => !value || (value && (typeof value === "object" ? SUPPORTED_FILE_FORMAT.includes(value?.type) : true))
                                    ),
                            })}
                            onSubmit={(value) => {
                                setLoader(true)
                                const formData = new FormData();
                                for (const key in value) {
                                    formData.append(key, value[key]);
                                }
                                if (state?.mode == "edit") {
                                    formData.append("id", state?.id);
                                    BannerUpdateDetailsAction(formData, (response) => {
                                        if (response?.status === true) {
                                            setLoader(false)
                                            navigate("/admin/banner-manager/list")
                                        } else {
                                            setLoader(false)
                                        }
                                    })()
                                } else {
                                    BannerCreateDetailsAction(formData, (response) => {
                                        if (response?.status === true) {
                                            setLoader(false)
                                            navigate("/admin/banner-manager/list")
                                        } else {
                                            setLoader(false)
                                        }
                                    })()
                                }
                            }}
                        >
                            {(formik) => {
                                return (
                                    <Form autoComplete='off' onChange={(e) => handleOnChange(e, formik)}>
                                        <FormikFromFunc />
                                        <div className="row g-3 g-md-5">
                                            <div className="col-12 col-md-6">
                                                <label> Sports Type </label>
                                                <Field
                                                    as="select"
                                                    name="sports_id"
                                                    className="select-control form-control w-100"
                                                    value={formik.values.sports_id}
                                                >
                                                    <option value=""> Select Sports Type </option>
                                                    {["Cricket", "Football"].map((value, index) => {
                                                        return (
                                                            <option value={index + 1} key={index}> {value} </option>
                                                        );
                                                    })}
                                                </Field>
                                                <ErrorMessage name="sports_id" component={TextErrorMsg} />
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <FieldText
                                                    name="sequence"
                                                    type="text"
                                                    placeholder="Banner Sequence"
                                                    value={formik.values.sequence}
                                                    label="Sequence"
                                                    showlabel={true}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <label> Series </label>
                                                <Field
                                                    as="select"
                                                    name="series_id"
                                                    className="select-control form-control w-100"
                                                    value={formik.values.series_id}
                                                >
                                                    <option value=""> Select Series </option>
                                                    {seriesListArr?.map((value, index) => {
                                                        return (
                                                            <option value={value.cid} key={index}> {value?.name} </option>
                                                        );
                                                    })}
                                                </Field>
                                                <ErrorMessage name="series_id" component={TextErrorMsg} />
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <label> Match </label>
                                                <Field
                                                    as="select"
                                                    name="match_id"
                                                    className="select-control form-control w-100"
                                                    value={formik.values.match_id}
                                                >
                                                    <option value=""> Select Match </option>
                                                    {matchListArr?.map((value, index) => {
                                                        return (
                                                            <option value={value?.match_id} key={index}> {value?.localteam} vs {value?.visitorteam} @ {moment(value?.date).format("DD-MM-YYYY")}, {moment(value?.time_ist, ["HH:mm"]).format("hh:mm")} </option>
                                                        );
                                                    })}
                                                </Field>
                                                <ErrorMessage name="match_id" component={TextErrorMsg} />
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <label> Banner Image </label>
                                                <div className="banner_img_box">
                                                    <Field name="image">
                                                        {({ form, field }) => {
                                                            return (
                                                                <>
                                                                    <input name={field.name} type="file"
                                                                        onChange={(e) => {
                                                                            if (SUPPORTED_FILE_FORMAT.includes(e.target.files[0]?.type)) {
                                                                                form.setFieldValue(field.name, e.target.files[0])
                                                                            } else {
                                                                                form.setFieldValue(field.name, "")
                                                                                e.target.value = null
                                                                                ToastOverError("Unsupported file format")
                                                                            }
                                                                        }} />
                                                                </>
                                                            )
                                                        }}
                                                    </Field>
                                                    <ErrorMessage name="image" component={TextErrorMsg} />

                                                    <div className="image_banner_edit">
                                                        {
                                                            (state?.mode == "edit" && typeof formik?.values?.image == "string") &&
                                                            <img src={`${server.baseURL}/${formik?.values?.image}`} className="img-fluid" alt="Banner" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <Button
                                                    className="themeBtn edit_page_btn"
                                                    text='Submit'
                                                    type="submit"
                                                    loader={loader}
                                                    disabled={!(formik.isValid && formik.dirty) || loader}
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerAddEdit