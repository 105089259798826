import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './login.scss';
import LoginSlider from '../../Components/LoginSlider/loginslider';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { LoginAction } from '../../../features/Auth/authSlice';
import { FieldText } from '../../Components/InputText/InputText';
import { Button } from '../../Components/Button/Button';


const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { login, loading } = useSelector((state) => state?.auth)

    useEffect(() => {
        if (Object.keys(login)?.length > 0 && (login?.role_id == 1 || login?.role_id == 3)) {
            navigate("/admin/dashboard")
        }
    }, [login])

    return (
        <>
            <div className='loginBg'>
                <div className='loginRow'>

                    <div className='loginCol_2'>
                        <div className='sliderLoginBlock'>
                            <div className='loginLogo'>
                                <img alt="Logo" src='images/logo.png' />
                            </div>
                            <LoginSlider />
                        </div>
                    </div>
                    <div className='loginCol_1'>
                        <div className='loginFormBlock'>
                            <div className='loginLogo'>
                                <img alt="Logo" src='images/logo.png' />
                            </div>

                            <div className='loginForm'>
                                <div className='loginFormMiddle'>
                                    <div className='loginTitle'>Login</div>
                                    <div className='loginDesciription'>Welcome back! please enter your credentials.</div>

                                    <Formik
                                        initialValues={{
                                            email: '',
                                            password: '',
                                        }}
                                        validationSchema={Yup.object().shape({
                                            email: Yup.string().email('Invalid email').required('Email is required!'),
                                            password: Yup.string().required('Password is required!'),
                                        })}
                                        onSubmit={(value) => {
                                            dispatch(LoginAction(value))
                                        }}
                                    >
                                        {(formik) => {
                                            return (
                                                <Form autoComplete='off'>
                                                    <FieldText
                                                        name="email"
                                                        type="email"
                                                        placeholder="Email"
                                                    />

                                                    <div className='show_password_tag'>
                                                        <FieldText
                                                            name="password"
                                                            type="password"
                                                            showHide={true}
                                                            placeholder="Password"
                                                        />
                                                    </div>

                                                    <div className='rememFlex'>
                                                        <Link type='button' className='forgot_pass' to="/forgot-password"> Forgot Password? </Link>
                                                    </div>

                                                    <Button
                                                        className="themeBtn mt-40"
                                                        text='Submit'
                                                        type="submit"
                                                        loader={loading}
                                                        disabled={!(formik.isValid && formik.dirty) || loading}
                                                    />


                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                            </div>
                        </div>


                        <span className='tringleSparkle'></span>
                        <span className='tringleSparkle tringleSparkle_2'></span>
                        <span className='tringleSparkle tringleSparkle_3'></span>
                        <span className='tringleLongSparkle'></span>
                        <span className='tringleLongSparkle longSparke_2'></span>
                        <img src="/images/dotsSparkle.svg" className='dotSparkle' alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;