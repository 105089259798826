import React, { useEffect, useState } from "react"
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FieldText } from "../../Components/InputText/InputText";
import { Button } from "../../Components/Button/Button";
import { CategoryListAction, ContestCreateAction, FAQCreateAction, FAQUpdateAction, GameModeListAction } from "../../../features/CommonSlice";
import TextErrorMsg from "../../Components/InputText/TextErrorMsg";

const FAQAddEdit = () => {
    const navigate = useNavigate();
    const { state } = useLocation()
    const [loader, setLoader] = useState(false)
    const [formDataSaved, setFormDataSaved] = useState(false);

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        useEffect(() => {
            if (!formDataSaved && state) {
                formikFrom.setValues({
                    question: state?.question,
                    answer: state?.answer
                })
                setFormDataSaved(true)
            }
        }, [state])
    }

    return (
        <div className="addLeagueBlock">
            <div className='title_breadcrumb_section'>
                <div className='title_page'> {state?.mode == "edit" ? "Edit" : "Add"} FAQ </div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/content-management-system/faq/list"> FAQs </Link></li>
                        <li className="breadcrumb-item active" aria-current="page"> {state?.mode == "edit" ? "Edit" : "Add"} FAQ </li>
                    </ol>
                </nav>
            </div>

            <div className="common_section_main">

                <div className="addLeagueForm_block add_staff_page user_list_edit">
                    <div className="formAddBlock">
                        <Formik
                            initialValues={{
                                question: "",
                                answer: ""
                            }}
                            validationSchema={Yup.object().shape({
                                question: Yup.string().required("Question is required!"),
                                answer: Yup.string().required("Answer is required!"),
                            })}
                            onSubmit={(value) => {
                                setLoader(true)
                                if (state?.mode == "edit") {
                                    FAQUpdateAction({ ...value, faq_id: state?.id }, (response) => {
                                        if (response?.status == true) {
                                            navigate("/admin/content-management-system/faq/list")
                                        }
                                        setLoader(false)
                                    })()
                                } else {
                                    FAQCreateAction(value, (response) => {
                                        if (response?.status == true) {
                                            navigate("/admin/content-management-system/faq/list")
                                        }
                                        setLoader(false)
                                    })()
                                }
                            }}
                        >
                            {(formik) => {
                                return (
                                    <Form autoComplete='off'>
                                        <FormikFromFunc />
                                        <div className="row g-3 g-md-5">
                                            <div className="col-12">
                                                <FieldText
                                                    name="question"
                                                    type="text"
                                                    placeholder="Question"
                                                    value={formik.values.question}
                                                    label="Question"
                                                    showlabel={true}
                                                    maxLength={200}
                                                />
                                                <small> 200 characters limit </small>
                                            </div>

                                            <div className="col-12">
                                                <FieldText
                                                    showlabel={true}
                                                    name="answer"
                                                    as="textarea"
                                                    placeholder="Answer"
                                                    value={formik.values.answer}
                                                    label="Answer"
                                                    rows={15}
                                                    maxLength={3000}
                                                />
                                                <small> 3000 characters limit </small>
                                            </div>

                                            <div className="col-12">
                                                <Button
                                                    className="themeBtn edit_page_btn"
                                                    text='Submit'
                                                    type="submit"
                                                    loader={loader}
                                                    disabled={!(formik.isValid && formik.dirty) || loader}
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQAddEdit