import React from 'react';
import HowToPlaySlider from './HowToPlaySlider';
import FeatureSlider from './FeatureSlider';
import "../landing.scss";
import TestimonialSlider from './TestimonialSlider';
import ClientSlider from './ClientSlider';
import { ToastOverError } from "../../../common/Toast/ToastOver";
import { server } from "../../../common/Apis/axiosBaseURL";
function Home() {

    const handleDownloadAPK = (type) => {
        if (type == "android") {
            const apkUrl = (type == "android" ? `${server.baseURL}/apk/afl-android.apk` : "");
            const link = document.createElement('a');
            link.href = apkUrl;
            link.download = "afl-android.apk";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }else if(type == "ios") {
            const apkUrl = (type == "ios" ? `${server.baseURL}/apk/afl-ios.ipa` : "");
            const link = document.createElement('a');
            link.href = apkUrl;
            link.download = "afl-ios.ipa";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else{
            ToastOverError("APK is currently under development, so please try again later.")
        }
    };

    return (
        <>
            <section className="banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner_part">
                                <div className="banner_details">
                                    <h1 className="banner_title" id="textHero">WELCOME TO <br />
                                        <span className="rocky_text" id="textHero_2">
                                            <span className="rocky11">Ace Fantasy</span>
                                            <span className="fantasy">League</span>
                                        </span>
                                    </h1>
                                    <button type="button" onClick={() => handleDownloadAPK("android")} className="themeBtn2">Download Now  </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="heroShape" id="hero_shape">
                    <img src="/images/website/hero_shape.png" alt='' />
                </div>
                <div className="heroArrow" id="hero_Arrow">
                    <img src="/images/website/arrowHero.png" alt="" />
                </div>
            </section>

            <section className="about_us" >
                <div id="about_usId" className="top_min"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="light_heading" id="aboutHeading">About Us</h2>
                        </div>
                    </div>
                    <div className="row align-items-center row_spece gx-2">
                        <div className="col-md-7 order-last order-md-first">
                            <div className="aboutHeading_content">
                                <div className="heading">
                                    Know About Ace Fantasy League
                                </div>
                                <p>At AFL - Ace Fantasy league, we're passionate about sports and the thrill of competition. We've created a dynamic fantasy sports platform that brings the excitement of the game to your fingertips. Whether you're a seasoned sports enthusiast or just looking for a fun way to engage with your favorite teams, we've got you covered.</p>
                            </div>
                            <button type="button" className="themeBtn2_2">
                                Learn More
                                <img src="/images/website/theme_2_arrow.svg" alt="aero" />
                            </button>
                        </div>
                        <div className="col-md-5 order-first order-md-last">
                            <div className="mobile_img">
                                <img src="/images/website/mobile.png" alt="mobile" />
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <HowToPlaySlider />

            <svg viewBox="0 0 100 100" className="d-none" xmlns="http://www.w3.org/2000/svg">
                <filter id="clientOver">
                    <feColorMatrix type="matrix"
                        values="0 0 0 0 0.6078
            0 0 0 0 0.6078
            0 0 0 0 0.6078
            0 0 0 1 0" />
                </filter>
            </svg>

            <ClientSlider />

            <FeatureSlider />

            <TestimonialSlider />


            <section className="getAppSection">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-7 col-lg-6">
                            <div className="appGetcontnt">
                                <h2 className="commonHeading">Get the App Now</h2>
                                <p>Ready to start winning? Download the AFL app now and experience the thrill of fantasy sports like never before!</p>

                                <div className="getAppGroup">
                                    <button type='button' onClick={() => handleDownloadAPK("android")}>
                                        <img src="/images/website/googlePlay.png" alt="" />
                                    </button>
                                    <button type='button' onClick={() => handleDownloadAPK("ios")}>
                                        <img src="/images/website/appStore.png" alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-5 col-lg-6 align-self-end">
                            <div className="getAppImg">
                                <img src="/images/website/getAppImg.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;