import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import TableHead from '../../Components/TableHead/TableHead';
import { MatchesListAction, MatchesStatusUpdateAction } from '../../../features/CommonSlice';
import NoDataFound from "./../../Components/NoDataFound/NoDataFound";
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

const SeriesMatchesList = () => {
    const { sports_id, series_id } = useParams()
    const [statusUpdate, setStatusUpdate] = useState(1)
    const [listDataArr, setListDataArr] = useState({});

    useEffect(() => {
        let payload = {
            page: 0,
            sports_id: sports_id,
            series_id: series_id
        }
        MatchesListAction(payload, (response) => {
            if (response?.status === true) {
                setListDataArr(response)
            }
        })();
    }, [MatchesListAction, statusUpdate])

    const handleStatusUpdate = (id) => {
        MatchesStatusUpdateAction({ id }, (response) => {
            if (response?.status === true) {
                setStatusUpdate(statusUpdate + 1)
            }
        })();
    }

    let tableHeader = [
        { label: "#", key: "", sort: false },
        { label: "Match", key: "", sort: false },
        { label: "Type", key: "", sort: false },
        { label: "Date", key: "", sort: false },
        { label: "Time", key: "", sort: false },
        { label: "Modified", key: "", sort: false },
        { label: "Status", key: "", sort: false },
    ]

    return (
        <section className='mainSection'>

            <div className='title_breadcrumb_section'>
                <div className='title_page'>Matches List</div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/series-manager/list">Series list</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Matches list</li>
                    </ol>
                </nav>
            </div>

            <div className='common_section_main'>

                <Table responsive className="themeTable">
                    <TableHead data={tableHeader} />
                    <tbody>
                        {listDataArr?.data?.length > 1 ?
                            listDataArr?.data?.map((value, index) => (
                                <tr key={index}>
                                    <td> {(index + 1)}. </td>
                                    <td> {value?.localteam} vs {value?.visitorteam} </td>
                                    <td> {value?.type ? value?.type : "-"} </td>
                                    <td> {value?.date ? moment(value?.date).format("DD-MM-YYYY") : "-"} </td>
                                    <td> {value?.time_ist ? moment(value?.time_ist, ["HH:mm"]).format("hh:mm") : "-"} </td>
                                    <td> {value?.updatedAt ? moment(value?.updatedAt).format("DD-MM-YYYY") : "-"} </td>
                                    <td className='text-end'>
                                        <button
                                            type='button'
                                            className={`table_btn ${value?.platform_status == 1 ? "active" : "inactive"}`}
                                            onClick={() => handleStatusUpdate(value.match_id)}
                                        >
                                            {value?.platform_status == 1 ? "Active" : "Inactive"}
                                        </button>
                                    </td>
                                </tr>
                            ))
                            :
                            <>
                                <tr>
                                    <td colSpan={7} className='not_found_data_td'>
                                        <NoDataFound />
                                    </td>
                                </tr>
                            </>
                        }
                    </tbody>
                </Table>
            </div>

        </section>
    )
}

export default SeriesMatchesList