import React, { useState } from 'react';
import "../../Components/Modal/CommonModal.scss";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from "yup";
import { FieldText } from '../../Components/InputText/InputText';
import { Button } from '../../Components/Button/Button';
import { PlayerUpdateAction } from '../../../features/CommonSlice';
import TextErrorMsg from '../../Components/InputText/TextErrorMsg';
import { ToastOverError } from '../../../common/Toast/ToastOver';
import { server } from '../../../common/Apis/axiosBaseURL';
import { isValidHttpUrl } from '../../../utils/Function';

const EditPlayerDetails = ({ data, hide, setStatusUpdate }) => {
    const [loader, setLoader] = useState(false)
    const SUPPORTED_FILE_FORMAT = ["image/jpeg", "image/jpg", "image/png"]
    return (
        <div className='delete_modal_main'>
            <div className=''>
                <Formik
                    initialValues={{
                        player_role: data?.player_role ? data?.player_role : '',
                        player_id: data?.player_id ? data?.player_id : '',
                        player_credit: data?.player_credit ? data?.player_credit : '',
                        image: data?.image ? data?.image : '',
                    }}
                    validationSchema={Yup.object().shape({
                        player_role: Yup.string().required('Role is required!'),
                        player_credit: Yup.string().required('Points is required!'),
                        image: Yup.mixed().nullable().notRequired()
                            .test(
                                "FILE_FORMAT",
                                "Unsupported file format",
                                (value) => !value || (value && (typeof value === "object" ? SUPPORTED_FILE_FORMAT.includes(value?.type) : true))
                            ),
                    })}
                    onSubmit={(value) => {
                        setLoader(true)
                        const formData = new FormData();
                        for (const key in value) {
                            formData.append(key, value[key]);
                        }
                        PlayerUpdateAction(formData, (response) => {
                            if (response?.status) {
                                hide(false)
                                setStatusUpdate(prevCount => prevCount + 1);
                            }
                            setLoader(false)
                        })();
                    }}
                >
                    {(formik) => {
                        return (
                            <Form autoComplete='off'>
                                <FieldText
                                    name="player_credit"
                                    type="number"
                                    placeholder="Player Credit"
                                    showlabel={true}
                                    label="Player Credit"
                                    value={formik.values.player_credit}
                                    maxLength={2}
                                />

                                <div className="col-12 mt-4">
                                    <label className='commmon_label'>Player Role </label>
                                    <Field
                                        as="select"
                                        name="player_role"
                                        className="form-control"
                                        value={formik.values.player_role}
                                    >
                                        <option value=""> Select Player Role </option>
                                        {["bat", "all", "bowl", "wk"].map((value, index) => {
                                            return (
                                                <option value={value} key={index}>
                                                    {value == "bat" ? "Batsman" :
                                                        value == "all" ? "Allrounder" :
                                                            value == "bowl" ? "Bowler" :
                                                                value == "wk" ? "Wicketkeeper" :
                                                                    ""}
                                                </option>
                                            );
                                        })}
                                    </Field>
                                    <ErrorMessage name="player_role" component={TextErrorMsg} />
                                </div>


                                <div className="col-12 col-md-6 mt-4">
                                    <label className='commmon_label'> Image </label>
                                    <div className='banner_img_box'>
                                        <Field name="image">
                                            {({ form, field }) => {
                                                return (
                                                    <>
                                                        <input name={field.name} type="file"
                                                            onChange={(e) => {
                                                                if (SUPPORTED_FILE_FORMAT.includes(e.target.files[0]?.type)) {
                                                                    form.setFieldValue(field.name, e.target.files[0])
                                                                } else {
                                                                    form.setFieldValue(field.name, "")
                                                                    e.target.value = null
                                                                    ToastOverError("Unsupported file format")
                                                                }
                                                            }} />
                                                    </>
                                                )
                                            }}
                                        </Field>
                                        <ErrorMessage name="image" component={TextErrorMsg} />
                                    </div>
                                </div>
                                <div className='edit_img_modal'>
                                    {
                                        (typeof formik?.values?.image == "string") ?
                                            isValidHttpUrl(formik?.values?.image) ?
                                                <img src={formik?.values?.image} className="img-fluid" alt="Player" />
                                                :
                                                formik?.values?.image ?
                                                    <img src={`${server.baseURL}/${formik?.values?.image}`} className="img-fluid" alt="Player" />
                                                    : null
                                            :
                                            ""
                                    }
                                </div>

                                <div className='tow_button_card'>
                                    <button className='themeBtn w-auto m-0' type='button' onClick={() => hide(false)}> No</button>
                                    <Button
                                        className="themeBtn w-auto"
                                        text='Submit'
                                        type="submit"
                                        loader={loader}
                                        disabled={!(formik.isValid && formik.dirty) || loader}
                                    />
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default EditPlayerDetails;