
import { useEffect, useState } from 'react';
import { ContactUsSubmit } from '../../../features/CommonSlice';
const ContactUs = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
        message: ''
    });
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = () => {
        const dataToSubmit = { ...formData };
        if (!dataToSubmit.mobile_number) {
            delete dataToSubmit.mobile_number;
        }
        ContactUsSubmit(dataToSubmit, (response) => {
            if (response?.status === true) {
                setFormData({
                first_name: '',
                last_name: '',
                email: '',
                mobile_number: '',
                message: ''
            })
            }
        })();
    }

    return (
        <>

            <section className='cms_landing_page'>
                <div className='banner'>
                    Contact Us
                </div>
            </section>

            {/* <section className="contactSection">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h4 className="headingAbout_content">
                                Get In Touch
                            </h4>
                        </div>
                    </div>
                    <div className="row gy-5 mt-md-5">
                        <div className="col-12  col-lg-4">
                            <div className="contactCard">
                                <div className="contactIcon_card">
                                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
                                </div>
                                <div className="contactCard_detail">
                                    <span>Email</span>
                                    acefantasyleague@gmail.com
                                </div>
                            </div>
                        </div>

                        <div className="col-12  col-lg-4">
                            <div className="contactCard">
                                <div className="contactIcon_card">
                                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                </div>
                                <div className="contactCard_detail">
                                    <span>Phone</span>
                                    +91-XXXXXXX
                                </div>
                            </div>
                        </div>

                        <div className="col-12  col-lg-4">
                            <div className="contactCard">
                                <div className="contactIcon_card">
                                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                </div>
                                <div className="contactCard_detail">
                                    <span>Address</span>
                                    Ace Fantasy League 20/A Civil Lines Delhi 110054
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            <div className='support_contact_section'>
                <div className='container'>
                    <div className='row g-5 align-items-center'>
                        <div className='col-md-5'>
                            <div className="contactCard">
                                <div className="contactIcon_card">
                                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
                                </div>
                                <div className="contactCard_detail">
                                    <span>Email</span>
                                    acefantasyleague@gmail.com
                                </div>
                            </div>
                            <div className="contactCard">
                                <div className="contactIcon_card">
                                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                </div>
                                <div className="contactCard_detail">
                                    <span>Phone</span>
                                    +91-XXXXXXX
                                </div>
                            </div>
                            <div className="contactCard">
                                <div className="contactIcon_card">
                                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                </div>
                                <div className="contactCard_detail">
                                    <span>Address</span>
                                    Ace Fantasy League 20/A Civil Lines Delhi 110054
                                </div>
                            </div>
                        </div>
                        <div className='col-md-7'>
                            <div className='contact_form'>
                            <form>
                                <div className='row g-4'>
                                    <div className='col-md-6'>
                                        <label className='common_label' htmlFor="name"> <span className='required_star'>*</span>First Name</label>
                                        <input type="text" name="first_name" className='common_input'value={formData.first_name} onChange={handleChange} placeholder='First Name' />
                                    </div>
                                    <div className='col-md-6'>
                                        <label className='common_label' htmlFor="lastname"><span className='required_star'>*</span>Last Name</label>
                                        <input type="text" name="last_name" className='common_input' value={formData.last_name} onChange={handleChange} placeholder='Last Name' />
                                    </div>
                                    <div className='col-md-6'>
                                        <label className='common_label' htmlFor="email"><span className='required_star'>*</span>Email </label>
                                        <input type="email" name="email" className='common_input'value={formData.email} onChange={handleChange} placeholder='Email ' />
                                    </div>
                                    <div className='col-md-6'>
                                        <label className='common_label' htmlFor="number">Phone number </label>
                                        <input type="text" name="mobile_number" className='common_input'value={formData.mobile_number} onChange={handleChange} placeholder='Phone Number (Optional)' />
                                    </div>
                                    <div className='col-md-12'>
                                        <label className='common_label' htmlFor="message"><span className='required_star'>*</span>Message </label>
                                        <textarea className='common_input text_area' name="message" value={formData.message} onChange={handleChange} rows="10">Message</textarea>
                                    </div>

                                    <div className='col-md-12'>
                                        <button type="button" className="themeBtn2_2" onClick={handleSubmit}>
                                            SUBMIT
                                            <img src="/images/website/theme_2_arrow.svg" alt="aero" />
                                        </button>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="getAppSection">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-7 col-lg-6">
                            <div className="appGetcontnt">
                                <h2 className="commonHeading">Get the App Now</h2>
                                <p>Ready to start winning? Download the AFL app now and experience the thrill of fantasy sports like never before!</p>

                                <div className="getAppGroup">
                                    <a href="#!">
                                        <img src="/images/website/googlePlay.png" alt="" />
                                    </a>
                                    <a href="#!">
                                        <img src="/images/website/appStore.png" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-5 col-lg-6 align-self-end">
                            <div className="getAppImg">
                                <img src="/images/website/getAppImg.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUs