import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import TableHead from '../../Components/TableHead/TableHead';
import { MdModeEditOutline } from 'react-icons/md';
import { BsEyeFill } from 'react-icons/bs';
import { UserListAction, UserStatusUpdateAction } from '../../../features/CommonSlice';
import moment from 'moment';
import NoDataFound from "./../../Components/NoDataFound/NoDataFound";
import { Pagination } from "./../../Components/Hooks/Pagination"
import { HasConsecutiveSpaces, RemoveEmptyObjKey } from '../../../utils/Function';
import { Link, useNavigate } from 'react-router-dom';
import { BiReset, BiSearch } from "react-icons/bi";

const UserList = () => {
    const navigate = useNavigate()
    const [statusUpdate, setStatusUpdate] = useState(1)
    const [listDataArr, setListDataArr] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [perPageCount, setPerPageCount] = useState(15);
    const [searchName, setSearchName] = useState("");
    const [searchEmail, setSearchEmail] = useState("");
    const [searchPhone, setSearchPhone] = useState("");

    useEffect(() => {
        let payload = {
            name: searchName,
            email: searchEmail,
            phone: searchPhone,
            pageSize: perPageCount,
            page: currentPage,
        }
        UserListAction(RemoveEmptyObjKey(payload), (response) => {
            if (response?.status === true) {
                setListDataArr(response)
            }
        })();
    }, [UserListAction, perPageCount, currentPage, statusUpdate])

    const handleFilters = (e) => {
        const { value } = e.target
        if (HasConsecutiveSpaces(value)) return <></>;
        if (e.target.name == "name") setSearchName(value)
        if (e.target.name == "email") setSearchEmail(value)
        if (e.target.name == "phone") setSearchPhone(value)
    };

    const handleStatusUpdate = (id) => {
        UserStatusUpdateAction({ id }, (response) => {
            if (response?.status === true) {
                setStatusUpdate(statusUpdate + 1)
            }
        })();
    }

    let tableHeader = [
        { label: "#", key: "", sort: false },
        { label: "Name", key: "", sort: false },
        { label: "Email", key: "", sort: false },
        { label: "Phone Number", key: "", sort: false },
        { label: "Registration date", key: "", sort: false },
        { label: "Referal Count", key: "", sort: false },
        { label: "Status", key: "", sort: false },
        { label: "Action", key: "", sort: false }
    ]
    return (
        <section className='mainSection'>

            <div className='title_breadcrumb_section'>
                <div className='title_page'>User List</div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">User list</li>
                    </ol>
                </nav>
            </div>

            <div className='common_section_main'>
                <form className='searchAddRowTable responsive_common'
                    onSubmit={(e) => {
                        e.preventDefault()
                        setStatusUpdate(statusUpdate + 1)
                    }}
                >
                    <div className='inputGroupSearch'>
                        <input
                            placeholder="Enter Name"
                            type="text"
                            name="name"
                            className="form-control"
                            onChange={handleFilters}
                            value={searchName}
                        />
                    </div>

                    <div className='inputGroupSearch'>
                        <input
                            placeholder="Email Address"
                            type="email"
                            name="email"
                            className="form-control"
                            onChange={handleFilters}
                            value={searchEmail}
                        />
                    </div>

                    <div className='inputGroupSearch'>
                        <input
                            placeholder="Phone Number"
                            type="number"
                            name="phone"
                            className="form-control"
                            onChange={handleFilters}
                            value={searchPhone}
                        />
                    </div>

                    <div className='flexBtnGroup ms-auto list_top_filter'>
                        <button className='themeBtn_2' type='submit'>
                            <BiSearch />
                            Search
                        </button>

                        <button className='themeBtn_2' type='button'
                            onClick={() => {
                                setSearchName("")
                                setSearchEmail("")
                                setSearchPhone("")
                                setStatusUpdate(statusUpdate + 1)
                            }}
                        >
                            <BiReset />
                            Reset
                        </button>
                    </div>

                </form>

                <Table responsive className="themeTable">
                    <TableHead data={tableHeader} />
                    <tbody>
                        {listDataArr?.data?.list?.length > 0 ?
                            listDataArr?.data?.list?.map((value, index) => (
                                <tr key={index}>
                                    <td> {(currentPage - 1) * Number(listDataArr?.data?.perpage_count) + (index + 1)}. </td>
                                    <td>
                                        {value?.first_name ? <>
                                            {`${value?.first_name} ${value?.last_name}`}
                                        </> : "-"}
                                    </td>
                                    <td> {value?.email ? value?.email : "-"} </td>
                                    <td> {value?.phone ? value?.phone : "-"} </td>
                                    <td> {value?.createdAt ? moment(value?.createdAt).format("YYYY-MM-DD") : "-"} </td>
                                    <td> - </td>
                                    <td>
                                        <button
                                            type='button'
                                            className={`table_btn ${value?.status == 1 ? "active" : "inactive"}`}
                                            onClick={() => handleStatusUpdate(value.id)}
                                        >
                                            {value?.status == 1 ? "Active" : "Inactive"}
                                        </button>
                                    </td>
                                    <td>
                                        <div className="btnTableGroup">
                                            <button type="button" onClick={() => navigate(`/admin/user/edit/${value?.id}`)}>
                                                <MdModeEditOutline />
                                            </button>
                                            <button type="button" onClick={() => navigate(`/admin/user/detail/${value?.id}`)}><BsEyeFill /></button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                            :
                            <>
                                <tr>
                                    <td colSpan={8} className='not_found_data_td'>
                                        <NoDataFound />
                                    </td>
                                </tr>
                            </>
                        }
                    </tbody>
                </Table>

                {listDataArr?.data?.list?.length > 0 ?
                    <div className='pagination_entries_section'>
                        <div className='entries_text'>
                            <select className='common_input_filed' onChange={(e) => setPerPageCount(e.target.value)}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>

                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={listDataArr?.data?.totalcount}
                            pageSize={listDataArr?.data?.perpage_count}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                    :
                    null
                }
            </div>
        </section>
    )
}

export default UserList