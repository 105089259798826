import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PointsListing, PointsUpdateAction } from '../../../features/CommonSlice';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { FieldText } from "../../Components/InputText/InputText";
import { Button } from "../../Components/Button/Button";
import NoDataFound from "./../../Components/NoDataFound/NoDataFound";

const PointsManager = () => {
    const [loader, setLoader] = useState(false)
    const [fieldloader, setFieldLoader] = useState(false)
    const [sportsId, setSportsId] = useState(1);
    const [listDataArr, setListDataArr] = useState({});
    const [selectedSportTabName, setSelectedSportTabName] = useState("T10")

    useEffect(() => {
        setLoader(true)
        PointsListing(sportsId, (response) => {
            if (response?.status === true) {
                setListDataArr(response?.data)
            }
            setLoader(false)
        })();
    }, [PointsListing, sportsId]);

    const t10YupFields = ['run', 'fours', 'sixes', 'thirty_run', 'half_century', 'duck', 'wicket', 'two_wicket', 'three_wicket', 'maiden_over', 'catch', 'three_catch', 'stumping', 'directrunout', 'runoutstumping', 'captain', 'vice_captain', 'starting11'];
    const t20YupFields = ['run', 'fours', 'sixes', 'thirty_run', 'half_century', 'century', 'duck', 'wicket', 'three_wicket', 'four_wicket', 'five_wicket', 'maiden_over', 'catch', 'three_catch', 'stumping', 'directrunout', 'runoutstumping', 'captain', 'vice_captain', 'starting11'];
    const ODIYupFields = ['run', 'fours', 'sixes', 'half_century', 'century', 'duck', 'wicket', 'four_wicket', 'five_wicket', 'maiden_over', 'catch', 'three_catch', 'stumping', 'directrunout', 'runoutstumping', 'captain', 'vice_captain', 'starting11'];
    const TestYupFields = ['run', 'fours', 'sixes', 'half_century', 'century', 'duck', 'wicket', 'four_wicket', 'five_wicket', 'catch', 'stumping', 'directrunout', 'runoutstumping', 'captain', 'vice_captain', 'starting11'];

    return (
        <section className='mainSection'>
            <div className='title_breadcrumb_section'>
                <div className='title_page'>Points Details</div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Points details</li>
                    </ol>
                </nav>
            </div>

            <div className='common_section_main'>
                <div className='tab_main_section_list'>
                    <Tabs defaultActiveKey={1} onSelect={(e) => setSportsId(Number(e))}>
                        <Tab eventKey={1} title="Cricket">
                            {Object.keys(listDataArr)?.length > 0 ?
                                <Tabs className='second_tab_section' defaultActiveKey={selectedSportTabName} onSelect={(e) => setSelectedSportTabName(e)}>
                                    <Tab eventKey="T10" title="T10">
                                        <Formik
                                            initialValues={{
                                                run: listDataArr["T10"]?.run || "",
                                                fours: listDataArr["T10"]?.fours || "",
                                                sixes: listDataArr["T10"]?.sixes || "",
                                                thirty_run: listDataArr["T10"]?.thirty_run || "",
                                                half_century: listDataArr["T10"]?.half_century || "",
                                                duck: listDataArr["T10"]?.duck || "",
                                                wicket: listDataArr["T10"]?.wicket || "",
                                                two_wicket: listDataArr["T10"]?.two_wicket || "",
                                                three_wicket: listDataArr["T10"]?.three_wicket || "",
                                                maiden_over: listDataArr["T10"]?.maiden_over || "",
                                                catch: listDataArr["T10"]?.catch || "",
                                                three_catch: listDataArr["T10"]?.three_catch || "",
                                                stumping: listDataArr["T10"]?.stumping || "",
                                                directrunout: listDataArr["T10"]?.directrunout || "",
                                                runoutstumping: listDataArr["T10"]?.runoutstumping || "",
                                                captain: listDataArr["T10"]?.captain || "",
                                                vice_captain: listDataArr["T10"]?.vice_captain || "",
                                                starting11: listDataArr["T10"]?.starting11 || ""
                                            }}
                                            validationSchema={Yup.object().shape(
                                                t10YupFields.reduce((acc, fieldName) => {
                                                    acc[fieldName] = Yup.number().required();
                                                    return acc;
                                                }, {})
                                            )}
                                            onSubmit={(value) => {
                                                setFieldLoader(true);
                                                PointsUpdateAction({ points: { ...value }, match_type: "4" }, (response) => {
                                                    if (response?.status) {
                                                    }
                                                    setFieldLoader(false);
                                                })();
                                            }}
                                        >
                                            {(formik) => {
                                                return (
                                                    <Form autoComplete='off'>
                                                        {/* <FormikFromFunc /> */}
                                                        <div className='content_points'>
                                                            <h3 className='points_heading'> Batting </h3>
                                                            <div className='content_table'>
                                                                <div className='list_name'>  Run: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="run"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.run}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Boundary Bonus </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="fours"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.fours}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Six Bonus </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="sixes"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.sixes}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> 30 Run Bonus: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="thirty_run"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.thirty_run}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'>  50 Run Bonus : </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="half_century"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.half_century}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Dismissal for a duck: <br /> <small> (Batter, Wicket-Keeper, & All-Rounder) </small> </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="duck"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.duck}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='content_points'>
                                                            <h3 className='points_heading'>Bowling</h3>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Wicket (Excluding Run Out) : </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="wicket"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.wicket}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className='content_table'>
                                                                <div className='list_name'> 2 Wicket Bonus: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="two_wicket"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.two_wicket}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> 3 Wicket Bonus: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="three_wicket"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.three_wicket}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className='content_table'>
                                                                <div className='list_name'> Maiden Over: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="maiden_over"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.maiden_over}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='content_points'>
                                                            <h3 className='points_heading'>Fielding</h3>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Catch: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="catch"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.catch}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> 3 Catch Bonus: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="three_catch"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.three_catch}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Stumping: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="stumping"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.stumping}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Run out (Direct hit) </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="directrunout"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.directrunout}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Run out (Not a direct hit) : </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="runoutstumping"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.runoutstumping}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='content_points'>
                                                            <h3 className='points_heading'>Other</h3>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Captain: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="captain"
                                                                        type="text"
                                                                        maxLength={4}
                                                                        value={formik.values.captain}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Vice Captain: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="vice_captain"
                                                                        type="text"
                                                                        maxLength={4}
                                                                        value={formik.values.vice_captain}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> In announced lineups: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="starting11"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.starting11}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mt-5">
                                                            <Button
                                                                className="themeBtn edit_page_btn"
                                                                text='Submit'
                                                                type="submit"
                                                                loader={fieldloader}
                                                                disabled={!(formik.isValid && formik.dirty) || fieldloader}
                                                            />
                                                        </div>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                    </Tab>

                                    <Tab eventKey="T20" title="T20">
                                        <Formik
                                            initialValues={{
                                                run: listDataArr["T20"]?.run || "",
                                                fours: listDataArr["T20"]?.fours || "",
                                                sixes: listDataArr["T20"]?.sixes || "",
                                                thirty_run: listDataArr["T20"]?.thirty_run || "",
                                                half_century: listDataArr["T20"]?.half_century || "",
                                                century: listDataArr["T20"]?.century || "",
                                                duck: listDataArr["T20"]?.duck || "",
                                                wicket: listDataArr["T20"]?.wicket || "",
                                                three_wicket: listDataArr["T20"]?.three_wicket || "",
                                                four_wicket: listDataArr["T20"]?.four_wicket || "",
                                                five_wicket: listDataArr["T20"]?.five_wicket || "",
                                                maiden_over: listDataArr["T20"]?.maiden_over || "",
                                                catch: listDataArr["T20"]?.catch || "",
                                                three_catch: listDataArr["T20"]?.three_catch || "",
                                                stumping: listDataArr["T20"]?.stumping || "",
                                                directrunout: listDataArr["T20"]?.directrunout || "",
                                                runoutstumping: listDataArr["T20"]?.runoutstumping || "",
                                                captain: listDataArr["T20"]?.captain || "",
                                                vice_captain: listDataArr["T20"]?.vice_captain || "",
                                                starting11: listDataArr["T20"]?.starting11 || ""
                                            }}
                                            validationSchema={Yup.object().shape(
                                                t20YupFields.reduce((acc, fieldName) => {
                                                    acc[fieldName] = Yup.number().required();
                                                    return acc;
                                                }, {})
                                            )}
                                            onSubmit={(value) => {
                                                setFieldLoader(true);
                                                PointsUpdateAction({ points: { ...value }, match_type: "1" }, (response) => {
                                                    if (response?.status) {
                                                    }
                                                    setFieldLoader(false);
                                                })();
                                            }}
                                        >
                                            {(formik) => {
                                                return (
                                                    <Form autoComplete='off'>
                                                        {/* <FormikFromFunc /> */}
                                                        <div className='content_points'>
                                                            <h3 className='points_heading'> Batting </h3>
                                                            <div className='content_table'>
                                                                <div className='list_name'>  Run: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="run"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.run}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Boundary Bonus </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="fours"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.fours}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Six Bonus </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="sixes"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.sixes}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> 30 Run Bonus: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="thirty_run"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.thirty_run}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'>  50 Run Bonus : </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="half_century"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.half_century}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'>  100 Run Bonus : </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="century"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.century}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Dismissal for a duck: <br /> <small> (Batter, Wicket-Keeper, & All-Rounder) </small> </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="duck"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.duck}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='content_points'>
                                                            <h3 className='points_heading'>Bowling</h3>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Wicket (Excluding Run Out) : </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="wicket"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.wicket}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className='content_table'>
                                                                <div className='list_name'> 3 Wicket Bonus: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="three_wicket"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.three_wicket}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> 4 Wicket Bonus: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="four_wicket"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.four_wicket}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> 5 Wicket Bonus: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="five_wicket"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.five_wicket}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className='content_table'>
                                                                <div className='list_name'> Maiden Over: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="maiden_over"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.maiden_over}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='content_points'>
                                                            <h3 className='points_heading'>Fielding</h3>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Catch: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="catch"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.catch}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> 3 Catch Bonus: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="three_catch"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.three_catch}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Stumping: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="stumping"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.stumping}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Run out (Direct hit) </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="directrunout"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.directrunout}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Run out (Not a direct hit) : </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="runoutstumping"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.runoutstumping}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='content_points'>
                                                            <h3 className='points_heading'>Other</h3>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Captain: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="captain"
                                                                        type="text"
                                                                        maxLength={4}
                                                                        value={formik.values.captain}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Vice Captain: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="vice_captain"
                                                                        type="text"
                                                                        maxLength={4}
                                                                        value={formik.values.vice_captain}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> In announced lineups: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="starting11"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.starting11}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <Button
                                                                className="themeBtn edit_page_btn"
                                                                text='Submit'
                                                                type="submit"
                                                                loader={fieldloader}
                                                                disabled={!(formik.isValid && formik.dirty) || fieldloader}
                                                            />
                                                        </div>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                    </Tab>

                                    <Tab eventKey="ODI" title="ODI">
                                        <Formik
                                            initialValues={{
                                                run: listDataArr["ODI"]?.run || "",
                                                fours: listDataArr["ODI"]?.fours || "",
                                                sixes: listDataArr["ODI"]?.sixes || "",
                                                half_century: listDataArr["ODI"]?.half_century || "",
                                                century: listDataArr["ODI"]?.century || "",
                                                duck: listDataArr["ODI"]?.duck || "",
                                                wicket: listDataArr["ODI"]?.wicket || "",
                                                four_wicket: listDataArr["ODI"]?.four_wicket || "",
                                                five_wicket: listDataArr["ODI"]?.five_wicket || "",
                                                maiden_over: listDataArr["ODI"]?.maiden_over || "",
                                                catch: listDataArr["ODI"]?.catch || "",
                                                three_catch: listDataArr["ODI"]?.three_catch || "",
                                                stumping: listDataArr["ODI"]?.stumping || "",
                                                directrunout: listDataArr["ODI"]?.directrunout || "",
                                                runoutstumping: listDataArr["ODI"]?.runoutstumping || "",
                                                captain: listDataArr["ODI"]?.captain || "",
                                                vice_captain: listDataArr["ODI"]?.vice_captain || "",
                                                starting11: listDataArr["ODI"]?.starting11 || ""
                                            }}
                                            validationSchema={Yup.object().shape(
                                                ODIYupFields.reduce((acc, fieldName) => {
                                                    acc[fieldName] = Yup.number().required();
                                                    return acc;
                                                }, {})
                                            )}
                                            onSubmit={(value) => {
                                                setFieldLoader(true);
                                                PointsUpdateAction({ points: { ...value }, match_type: "2" }, (response) => {
                                                    if (response?.status) {
                                                    }
                                                    setFieldLoader(false);
                                                })();
                                            }}
                                        >
                                            {(formik) => {
                                                return (
                                                    <Form autoComplete='off'>
                                                        {/* <FormikFromFunc /> */}
                                                        <div className='content_points'>
                                                            <h3 className='points_heading'> Batting </h3>
                                                            <div className='content_table'>
                                                                <div className='list_name'>  Run: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="run"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.run}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Boundary Bonus </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="fours"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.fours}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Six Bonus </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="sixes"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.sixes}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'>  50 Run Bonus : </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="half_century"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.half_century}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'>  100 Run Bonus : </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="century"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.century}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Dismissal for a duck: <br /> <small> (Batter, Wicket-Keeper, & All-Rounder) </small> </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="duck"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.duck}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='content_points'>
                                                            <h3 className='points_heading'>Bowling</h3>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Wicket (Excluding Run Out) : </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="wicket"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.wicket}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> 4 Wicket Bonus: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="four_wicket"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.four_wicket}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> 5 Wicket Bonus: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="five_wicket"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.five_wicket}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className='content_table'>
                                                                <div className='list_name'> Maiden Over: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="maiden_over"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.maiden_over}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='content_points'>
                                                            <h3 className='points_heading'>Fielding</h3>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Catch: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="catch"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.catch}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> 3 Catch Bonus: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="three_catch"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.three_catch}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Stumping: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="stumping"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.stumping}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Run out (Direct hit) </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="directrunout"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.directrunout}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Run out (Not a direct hit) : </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="runoutstumping"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.runoutstumping}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='content_points'>
                                                            <h3 className='points_heading'>Other</h3>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Captain: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="captain"
                                                                        type="text"
                                                                        maxLength={4}
                                                                        value={formik.values.captain}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Vice Captain: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="vice_captain"
                                                                        type="text"
                                                                        maxLength={4}
                                                                        value={formik.values.vice_captain}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> In announced lineups: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="starting11"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.starting11}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <Button
                                                                className="themeBtn edit_page_btn"
                                                                text='Submit'
                                                                type="submit"
                                                                loader={fieldloader}
                                                                disabled={!(formik.isValid && formik.dirty) || fieldloader}
                                                            />
                                                        </div>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                    </Tab>

                                    <Tab eventKey="TEST" title="TEST">
                                        <Formik
                                            initialValues={{
                                                run: listDataArr["TEST"]?.run || "",
                                                fours: listDataArr["TEST"]?.fours || "",
                                                sixes: listDataArr["TEST"]?.sixes || "",
                                                half_century: listDataArr["TEST"]?.half_century || "",
                                                century: listDataArr["TEST"]?.century || "",
                                                duck: listDataArr["TEST"]?.duck || "",
                                                wicket: listDataArr["TEST"]?.wicket || "",
                                                four_wicket: listDataArr["TEST"]?.four_wicket || "",
                                                five_wicket: listDataArr["TEST"]?.five_wicket || "",
                                                catch: listDataArr["TEST"]?.catch || "",
                                                stumping: listDataArr["TEST"]?.stumping || "",
                                                directrunout: listDataArr["TEST"]?.directrunout || "",
                                                runoutstumping: listDataArr["TEST"]?.runoutstumping || "",
                                                captain: listDataArr["TEST"]?.captain || "",
                                                vice_captain: listDataArr["TEST"]?.vice_captain || "",
                                                starting11: listDataArr["TEST"]?.starting11 || ""
                                            }}
                                            validationSchema={Yup.object().shape(
                                                TestYupFields.reduce((acc, fieldName) => {
                                                    acc[fieldName] = Yup.number().required();
                                                    return acc;
                                                }, {})
                                            )}
                                            onSubmit={(value) => {
                                                setFieldLoader(true);
                                                PointsUpdateAction({ points: { ...value }, match_type: "3" }, (response) => {
                                                    if (response?.status) {
                                                    }
                                                    setFieldLoader(false);
                                                })();
                                            }}
                                        >
                                            {(formik) => {
                                                return (
                                                    <Form autoComplete='off'>
                                                        {/* <FormikFromFunc /> */}
                                                        <div className='content_points'>
                                                            <h3 className='points_heading'> Batting </h3>
                                                            <div className='content_table'>
                                                                <div className='list_name'>  Run: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="run"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.run}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Boundary Bonus </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="fours"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.fours}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Six Bonus </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="sixes"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.sixes}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'>  50 Run Bonus : </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="half_century"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.half_century}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'>  100 Run Bonus : </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="century"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.century}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Dismissal for a duck: <br /> <small> (Batter, Wicket-Keeper, & All-Rounder) </small> </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="duck"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.duck}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='content_points'>
                                                            <h3 className='points_heading'>Bowling</h3>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Wicket (Excluding Run Out) : </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="wicket"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.wicket}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> 4 Wicket Bonus: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="four_wicket"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.four_wicket}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> 5 Wicket Bonus: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="five_wicket"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.five_wicket}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='content_points'>
                                                            <h3 className='points_heading'>Fielding</h3>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Catch: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="catch"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.catch}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Stumping: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="stumping"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.stumping}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Run out (Direct hit) </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="directrunout"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.directrunout}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Run out (Not a direct hit) : </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="runoutstumping"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.runoutstumping}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='content_points'>
                                                            <h3 className='points_heading'>Other</h3>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Captain: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="captain"
                                                                        type="text"
                                                                        maxLength={4}
                                                                        value={formik.values.captain}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> Vice Captain: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="vice_captain"
                                                                        type="text"
                                                                        maxLength={4}
                                                                        value={formik.values.vice_captain}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='content_table'>
                                                                <div className='list_name'> In announced lineups: </div>
                                                                <div className='list_input'>
                                                                    <FieldText
                                                                        name="starting11"
                                                                        type="text"
                                                                        maxLength={2}
                                                                        value={formik.values.starting11}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <Button
                                                                className="themeBtn edit_page_btn"
                                                                text='Submit'
                                                                type="submit"
                                                                loader={fieldloader}
                                                                disabled={!(formik.isValid && formik.dirty) || fieldloader}
                                                            />
                                                        </div>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                    </Tab>
                                </Tabs>
                                :
                                null
                            }
                        </Tab>
                        <Tab eventKey={2} title="Football">
                            <NoDataFound />
                        </Tab>
                    </Tabs>
                </div>
            </div>

        </section>
    )
}

export default PointsManager