import React, { useEffect, useState } from "react";
import { ContestDetailsAction } from "../../../features/CommonSlice";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { Table } from 'react-bootstrap';
import NoDataFound from "../../Components/NoDataFound/NoDataFound";

const ContestDetails = () => {
    const { id } = useParams()
    const [loader, setLoader] = useState(false);
    const [contestDetails, setContestDetails] = useState({});

    useEffect(() => {
        setLoader(true)
        ContestDetailsAction({ id }, (response) => {
            if (response?.status === true) {
                setContestDetails(response)
            }
            setLoader(false)
        })();
    }, [ContestDetailsAction])

    return (
        !loader ?
            <div className="addLeagueBlock">
                <div className='title_breadcrumb_section'>
                    <div className='title_page'>Contest Detail</div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/admin/contest-manager/list">Contest List</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Contest detail</li>
                        </ol>
                    </nav>
                </div>

                <div className="common_section_main">

                <div className="detailFormBlock">
                    <div className="view_user_list_details">
                        <div className="row g-md-5 g-4">
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Category Name :</label>
                                    <span> {contestDetails?.data?.category?.category_name ? contestDetails?.data?.category?.category_name : "-"} </span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Game Mode Name :</label>
                                    <span> {contestDetails?.data?.gamemode?.mode_name ? contestDetails?.data?.gamemode?.mode_name : "-"}</span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Contest Mode :</label>
                                    <span> {contestDetails?.data?.contest_mode ?
                                        contestDetails?.data?.contest_mode == 1 ? "Cash Contest" : "Coin Contest"
                                        : "-"} </span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Contest type :</label>
                                    <span> {contestDetails?.data?.contest_type ? contestDetails?.data?.contest_type : "-"} </span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Contest size (Team size) :</label>
                                    <span> {contestDetails?.data?.contest_size ? contestDetails?.data?.contest_size : "-"} </span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label> Winning amount :</label>
                                    <span> {contestDetails?.data?.winning_amount ? contestDetails?.data?.winning_amount : "-"} </span>
                                </div>
                            </div>

                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Admin commission (%) :</label>
                                    <span> {contestDetails?.data?.admin_commission ? `${contestDetails?.data?.admin_commission}%` : "-"} </span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Entry Fee :</label>
                                    <span> {contestDetails?.data?.entry_fee ? contestDetails?.data?.entry_fee : "-"} </span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Created Date:</label>
                                    <span> {contestDetails?.data?.createdAt ? moment(contestDetails?.data?.createdAt).format("DD-MM-YYYY") : "-"} </span>
                                </div>
                            </div>

                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Auto Create :</label>
                                    <span> {contestDetails?.data?.auto_create == 0 ? "No" : "Yes"} </span>
                                </div>
                            </div>

                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Join with multiple teams :</label>
                                    <span> {contestDetails?.data?.multiple_team == 0 ? "No" : "Yes"} </span>
                                </div>
                            </div>

                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Confirmed winning :</label>
                                    <span> {contestDetails?.data?.confirmed_winning == 0 ? "No" : "Yes"} </span>
                                </div>
                            </div>

                            <div className="col-12">
                                <Link to="/admin/contest-manager/list" className="themeBtn view_page_btn"> Back </Link>
                            </div>
                        </div>
                    </div>

                    <div className="price_breakup_section">
                    <div className="row">
                        <div className="col-12">
                            <h4> Price Breakup </h4>
                        </div>
                        <div className="col-12">
                            <Table responsive className="themeTable">
                                <thead>
                                    <tr>
                                        <th> #  </th>
                                        <th> Name </th>
                                        <th> Start rank </th>
                                        <th> End rank </th>
                                        <th> Percentage </th>
                                        <th> Price </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contestDetails?.data?.contest_breakups?.length > 0 ?
                                        contestDetails?.data?.contest_breakups.map((value, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{value?.name}</td>
                                                    <td> {value?.start} </td>
                                                    <td> {value?.end} </td>
                                                    <td> {value?.percentage}% </td>
                                                    <td className="text-end"> {value?.price} </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <>
                                             <tr>
                                    <td colSpan={7} className='not_found_data_td'>
                                        <NoDataFound />
                                    </td>
                                </tr> 
                                        </>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            :
            <div>Loading...</div>
    )
}

export default ContestDetails