import React, { useEffect, useState } from 'react';
import './header.scss'
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

const Header = () => {
    // let bodyParm = document.getElementsByTagName('body')[0];
    const [folded, foldedAction] = useState(false)
    const toggleFolded = () => {
        foldedAction(!folded);
    };

    useEffect(() => {
        if (folded) {
            document.body.classList.add('is-folded')
        } else {
            document.body.classList.remove('is-folded')
        }
    }, [folded])

    return (
        <div className="dash_header">
            <div className="dash_logo">

                <Link to="/admin/dashboard" className="dashIconFold">
                    <div className="notFolded">
                        <img src="/images/logo.png" alt="" />
                    </div>

                    <div className="folded ">
                        <img src="/images/dash_logo_fav.svg" alt="" />
                    </div>
                </Link>
            </div>
            <div className="nav_dash_wrap">
                <div className="nav_dash_wrpLeft">
                    <button className="navbar-toggler dashIconFold" type="button" onClick={toggleFolded}>
                        <span className="navbar-toggler-icon"></span>
                    </button>

                </div>
                {/* <div className="nav_dash_wrpRight">
                    <Dropdown className='nav_right_notification dropdown'>
                        <Dropdown.Toggle className='rightMenuBtn newNotiMsg'>
                            <IoMdNotificationsOutline/>
                            <span className='noticount'>2</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='dropdown-menu dropdown-menu-end'>
                            <div className="notiHeader_top">
                                <div className="notiHeaderTopHeading">
                                    <i className="fal fa-bell"></i>
                                    <span className="">Notification</span>
                                </div>
                            </div>
                            <ul className="notificationListDrop">
                                <li>
                                    <a href="#!" className="dropdown-item">
                                        <div className="notiListCard">
                                            <div className="notiListImg">
                                                <img src="/images/Arsenal.png" alt="" />
                                            </div>
                                            <div className="notiListContent">
                                                <p>Channel: <span>Social Relationship</span> request to join has be
                                                    accepted.</p>
                                                <small>04 April, 2021 | 04:00 PM</small>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#!" className="dropdown-item">
                                        <div className="notiListCard">
                                            <div className="notiListImg">
                                                <img src="/images/Arsenal.png" alt="" />
                                            </div>
                                            <div className="notiListContent">
                                                <p>Channel: <span>Social Relationship</span> request to join has be
                                                    accepted.</p>
                                                <small>04 April, 2021 | 04:00 PM</small>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#!" className="dropdown-item">
                                        <div className="notiListCard">
                                            <div className="notiListImg">
                                                <img src="/images/Arsenal.png" alt="" />
                                            </div>
                                            <div className="notiListContent">
                                                <p>Channel: <span>Social Relationship</span> request to join has be
                                                    accepted.</p>
                                                <small>04 April, 2021 | 04:00 PM</small>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#!" className="dropdown-item">
                                        <div className="notiListCard">
                                            <div className="notiListImg">
                                                <img src="/images/Arsenal.png" alt="" />
                                            </div>
                                            <div className="notiListContent">
                                                <p>Channel: <span>Social Relationship</span> request to join has be
                                                    accepted.</p>
                                                <small>04 April, 2021 | 04:00 PM</small>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#!" className="dropdown-item">
                                        <div className="notiListCard">
                                            <div className="notiListImg">
                                                <img src="/images/Arsenal.png" alt="" />
                                            </div>
                                            <div className="notiListContent">
                                                <p>Channel: <span>Social Relationship</span> request to join has be
                                                    accepted.</p>
                                                <small>04 April, 2021 | 04:00 PM</small>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#!" className="dropdown-item">
                                        <div className="notiListCard">
                                            <div className="notiListImg">
                                                <img src="/images/Arsenal.png" alt="" />
                                            </div>
                                            <div className="notiListContent">
                                                <p>Channel: <span>Social Relationship</span> request to join has be
                                                    accepted.</p>
                                                <small>04 April, 2021 | 04:00 PM</small>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#!" className="dropdown-item">
                                        <div className="notiListCard">
                                            <div className="notiListImg">
                                                <img src="/images/Arsenal.png" alt="" />
                                            </div>
                                            <div className="notiListContent">
                                                <p>Channel: <span>Social Relationship</span> request to join has be
                                                    accepted.</p>
                                                <small>04 April, 2021 | 04:00 PM</small>
                                            </div>
                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </Dropdown.Menu>
                    </Dropdown>
                </div> */}
            </div>
        </div>
    );
};

export default Header;
