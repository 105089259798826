import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "../landing.scss";

import { Pagination, Autoplay } from 'swiper/modules';

function HowToPlaySlider() {

    const pagination = {
        el: ".swiper-pagination-how",
        clickable: true,
        renderBullet: function (index, className) {
            return '<div class="' + className + '">' + 0 + (index + 1) + "</div>";
        },
    };
    return (
        <>

            <section className="howToPlay" id="howPlayId">
                <div className="container">
                    <div className="row h-100">
                        <h2 className="howToPlayHeading">Amazing Features</h2>

                        <div className="hotToPlay_col_1">
                            <div className="mobileMaskParent">
                                <img src="/images/website/mobile_mask.svg" alt="" />
                                <div className="swiperParentRelate">
                                    <div className="swiper howSwiper">
                                        <Swiper
                                            pagination={pagination}
                                            modules={[Pagination, Autoplay]}
                                            className="mySwiper"
                                            speed={100}
                                            autoplay={{
                                                delay: 5000,
                                                disableOnInteraction: false,
                                            }}
                                        >
                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    <div className="swiperCard_how">
                                                        <img src="/images/website/screen_1.png" alt="" />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    <div className="swiperCard_how">
                                                        <img src="/images/website/screen_2.png" alt="" />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="swiper-slide">
                                                    <div className="swiperCard_how">
                                                        <img src="/images/website/screen_3.png" alt="" />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hotToPlay_col_2">
                            <div className="howPlay_contentBlock">
                                <h3 className="commonHeading">AFL Special features </h3>
                                <p>
                                With multiple game modes to choose from, including the Reverse fantasy mode or as we call it The Bad Apples, you'll never run out of opportunities to showcase your skills and earn real money prizes. Plus, our unique reward coin gives you the flexibility to enter additional contests and maximize your winnings.
                                </p>
                            </div>
                            <div className="swiper-pagination-how"></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HowToPlaySlider;