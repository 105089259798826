import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import TableHead from '../../Components/TableHead/TableHead';
import { StateListAction, StateStatusUpdateAction } from '../../../features/CommonSlice';
import NoDataFound from "./../../Components/NoDataFound/NoDataFound";
import { Link } from 'react-router-dom';

const StatesList = () => {
    const [statusUpdate, setStatusUpdate] = useState(1)
    const [listDataArr, setListDataArr] = useState({});

    useEffect(() => {
        StateListAction((response) => {
            if (response?.status === true) {
                setListDataArr(response)
            }
        })();
    }, [StateListAction, statusUpdate])

    const handleStatusUpdate = (id) => {
        StateStatusUpdateAction({ id }, (response) => {
            if (response?.status === true) {
                setStatusUpdate(statusUpdate + 1)
            }
        })();
    }

    let tableHeader = [
        { label: "#", key: "", sort: false },
        { label: "State Name", key: "", sort: false },
        { label: "ISO", key: "", sort: false },
        { label: "Status", key: "", sort: false }
    ]
    return (
        <section className='mainSection'>

            <div className='title_breadcrumb_section'>
                <div className='title_page'>State</div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">State list</li>
                    </ol>
                </nav>
            </div>

            <div className='common_section_main'>
                <Table responsive className="themeTable">
                    <TableHead data={tableHeader} />
                    <tbody>
                        {listDataArr?.data?.length > 0 ?
                            listDataArr?.data?.map((value, index) => (
                                <tr key={index}>
                                    <td> {(index + 1)}. </td>
                                    <td> {value?.name ? value?.name : "-"} </td>
                                    <td> {value?.iso ? value?.iso : "-"} </td>
                                    <td className='text-end'>
                                        <button
                                            type='button'
                                            className={`table_btn ${value?.status == 1 ? "active" : "inactive"}`}
                                            onClick={() => handleStatusUpdate(value.id)}
                                        >
                                            {value?.status == 1 ? "Active" : "Inactive"}
                                        </button>
                                    </td>
                                </tr>
                            ))
                            :
                            <>
                                <tr>
                                    <td colSpan={7} className='not_found_data_td'>
                                        <NoDataFound />
                                    </td>
                                </tr>
                            </>
                        }
                    </tbody>
                </Table>
            </div>
        </section>
    )
}

export default StatesList