import React , { useState } from 'react';
import { BiCheck } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import './login.scss';
import LoginSlider from '../../Components/LoginSlider/loginslider';


const Verification = () => {
    

    return (
        <>
           <div className='loginBg'>
                <div className='loginRow'>
                    <div className='loginCol_2'>
                        <div className='sliderLoginBlock'>
                            <div className='loginLogo'>
                                <img  alt="" src='images/logo.svg'/>
                            </div>
                           <LoginSlider/>
                        </div>
                    </div>
                    <div className='loginCol_1'>
                        <div className='loginFormBlock'>
                            <div className='loginLogo'>
                                <img  alt="" src='images/logo.svg'/>
                            </div>

                            <div className='loginForm'>
                               
                               
                               {/* successfully block sent email verifcation */}
                               
                                <div className='successFulBlock my-auto d-none'>
                                    <div className='successfulIcon'>
                                        <BiCheck/>
                                    </div>
                                    <h4>SUCCESSFUL</h4>
                                    <p>OTP has been sent to your email to verify your account.</p>
                                    <Link to='/complete-setup'  className='themeBtn mt-40' >CONTINUE</Link>
                                </div>

                                {/* verification block  */}
                                <div className=' my-auto'>
                                     <div className='loginTitle'>Verification</div>
                                     <div className='loginDesciription'>Enter the four-digit OTP which was sent to your Email Address.</div>

                                    <div className='inputGroupVerify'>
                                        <input type='text'  placeholder='*'/>
                                        <input type='text'  placeholder='*'/>
                                        <input type='text'  placeholder='*'/>
                                        <input type='text'  placeholder='*'/>
                                    </div>

                                    <div className='resendLine'>
                                        Resend code in 0:59s
                                    </div>
                                    <Link to='/complete-setup'  className='themeBtn mt-40' >CONTINUE</Link>
                                </div>

                               
                                
                                <div className='dontHaveLogin'>
                                    Didn’t receive an OTP?  <Link to="/verification">Resend</Link>
                                </div>
                            </div>
                        </div>
                        

                        <span className='tringleSparkle'></span>
                        <span className='tringleSparkle tringleSparkle_2'></span>
                        <span className='tringleSparkle tringleSparkle_3'></span>
                        <span className='tringleLongSparkle'></span>
                        <span className='tringleLongSparkle longSparke_2'></span>
                        <img src="/images/dotsSparkle.svg" className='dotSparkle' alt=""/>
                    </div>
                </div>
           </div>
        </>
    )
}

export default Verification;