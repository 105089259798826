
import React, { useEffect, useState } from 'react';
import { cmsDetailsAction } from '../../../features/webCommonSlice';
import moment from 'moment';

const Rules = () => {
    const [loader, setLoader] = useState(false)
    const [cmsDetails, setCmsDetails] = useState({});

    useEffect(() => {
        setLoader(true)
        cmsDetailsAction({ slug: "rules" }, (response) => {
            if (response?.status === true) {
                setCmsDetails(response?.data)
            }
            setLoader(false)
        })();
    }, [cmsDetailsAction])

    return (
        <>
            <div className='cms_landing_page'>
                <div className='banner'>
                    {cmsDetails.title}
                    {/* {cmsDetails?.updatedAt ? moment(cmsDetails.updatedAt).format("YYYY-MM-DD") : "-"} */}
                </div>
                <div className='container'>
                    {!loader ?
                        <div className='cms_content_text'>
                            <div dangerouslySetInnerHTML={{ __html: cmsDetails.description }}></div>
                        </div>
                        :
                        "loading..."
                    }
                </div>
            </div>
        </>
    )
}

export default Rules