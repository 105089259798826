import { Table, Tabs, Tab } from 'react-bootstrap';
import TableHead from '../../Components/TableHead/TableHead';
import { MdModeEditOutline } from 'react-icons/md';
import { BiSearch } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { SeriesListAction, TeamListAction } from '../../../features/CommonSlice';
import NoDataFound from "./../../Components/NoDataFound/NoDataFound";
import { Pagination } from "./../../Components/Hooks/Pagination"
import { HasConsecutiveSpaces, RemoveEmptyObjKey, debounceSearch, isValidHttpUrl } from '../../../utils/Function';
import { Link } from 'react-router-dom';
import { server } from '../../../common/Apis/axiosBaseURL';
import TeamEdit from './Edit';
import CommonModal from '../../Components/Modal/CommonModal';

const TeamsList = () => {
    const [statusUpdate, setStatusUpdate] = useState(1)
    const [loader, setLoader] = useState(false)
    const [listDataArr, setListDataArr] = useState({});
    const [seriesListArr, setSeriesListArr] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [perPageCount, setPerPageCount] = useState(30);
    const [searchString, setSearchString] = useState("");
    const [selectedTabID, setSelectedTabID] = useState(1);
    const [seriesID, setSeriesID] = useState("");
    const [editSeriesModalOpen, setEditSeriesModalOpen] = useState(false);
    const [selectedSeriesData, setSelectedSeriesData] = useState("");

    useEffect(() => {
        let payload = {
            page: seriesID ? 1 : currentPage,
            pageSize: perPageCount,
            searchText: searchString,
            sports_id: selectedTabID,
            series_id: seriesID,
        }
        setLoader(true)
        TeamListAction(RemoveEmptyObjKey(payload), (response) => {
            if (response?.status === true) {
                setListDataArr(response)
            }
            setLoader(false)
        })();
    }, [TeamListAction, perPageCount, currentPage, searchString, selectedTabID, seriesID, statusUpdate])


    useEffect(() => {
        SeriesListAction({ page: 0, sports_id: selectedTabID }, (response) => {
            if (response?.status === true) {
                setSeriesListArr(response?.data)
            }
        })();
    }, [SeriesListAction, selectedTabID])

    const handleFilters = debounceSearch((e) => {
        if (HasConsecutiveSpaces(e.target.value)) return <></>;
        setSearchString(e.target.value)
    }, 300);

    let tableHeader = [
        { label: "#", key: "", sort: false },
        { label: "Team Name", key: "", sort: false },
        { label: "Team Short Name", key: "", sort: false },
        { label: "Flag", key: "", sort: false },
        { label: "Action", key: "", sort: false }
    ]
    return (
        <>
            <section className='mainSection'>

                <div className='title_breadcrumb_section'>
                    <div className='title_page'>Teams List</div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Teams list</li>
                        </ol>
                    </nav>
                </div>

                <div className='common_section_main'>
                    <div className='tab_main_section_list'>
                        <Tabs defaultActiveKey={1} onSelect={(e) => setSelectedTabID(Number(e))}>
                            {["Cricket", "Football"].map((value, index) => (
                                <Tab eventKey={index + 1} title={value} key={index}>
                                    <div className='searchAddRowTable mt-5'>
                                        <div className='inputGroupSearch search_input'>
                                            <input type='text' className='form-control' placeholder='Search here ..' onChange={handleFilters} />
                                            <button type='button'><BiSearch /></button>
                                        </div>

                                        <div className='inputGroupSearch search_input'>
                                            <select className='form-control select_white' onChange={(e) => setSeriesID(e.target.value)}>
                                                <option value=""> Select Series </option>
                                                {seriesListArr?.map((value, index) => (
                                                    <option value={value?.cid} key={index}>{value?.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <Table responsive className="themeTable">
                                        <TableHead data={tableHeader} />
                                        <tbody>
                                            {!loader ?
                                                listDataArr?.data?.list?.length > 0 ?
                                                    listDataArr?.data?.list?.map((value, index) => (
                                                        <tr key={index}>
                                                            <td> {(currentPage - 1) * Number(listDataArr?.data?.perpage_count) + (index + 1)}. </td>
                                                            <td> {value?.team_name ? value?.team_name : "-"} </td>
                                                            <td> {value?.team_short_name ? value?.team_short_name : "-"} </td>
                                                            <td>
                                                                <div className='table_flag_img'>
                                                                    {
                                                                        isValidHttpUrl(value?.logo_url) ?
                                                                            <img src={value?.logo_url} width={50} height={50} className="img-fluid" alt={value?.team_name} />
                                                                            :
                                                                            <img src={`${server.baseURL}/${value?.logo_url}`} width={50} height={50} className="img-fluid" alt={value?.team_name} />
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="btnTableGroup">
                                                                    <button type="button" onClick={() => {
                                                                        setEditSeriesModalOpen(true)
                                                                        setSelectedSeriesData(value)
                                                                    }}>
                                                                        <MdModeEditOutline />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <>
                                                        <tr>
                                                            <td colSpan={7} className='not_found_data_td'>
                                                                <NoDataFound />
                                                            </td>
                                                        </tr>
                                                    </>
                                                :
                                                <tr>
                                                    <td colSpan={5}>Loading...</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>

                                    {listDataArr?.data?.list?.length > 0 ?
                                        <div className='pagination_entries_section'>
                                            <div className='entries_text'>
                                                <select className='common_input_filed' onChange={(e) => setPerPageCount(e.target.value)}>
                                                    <option value={30}>30</option>
                                                    <option value={60}>60</option>
                                                    <option value={80}>80</option>
                                                    <option value={100}>100</option>
                                                </select>
                                            </div>

                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={listDataArr?.data?.totalcount}
                                                pageSize={listDataArr?.data?.perpage_count}
                                                onPageChange={(page) => setCurrentPage(page)}
                                            />
                                        </div>
                                        :
                                        null
                                    }
                                </Tab>
                            ))}
                        </Tabs>
                    </div>
                </div>


            </section>

            <CommonModal
                show={editSeriesModalOpen}
                onHide={() => setEditSeriesModalOpen(false)}
                title="Edit Team"
                body={
                    <TeamEdit hide={setEditSeriesModalOpen} data={selectedSeriesData} setStatusUpdate={setStatusUpdate} />
                }
            />
        </>
    )
}

export default TeamsList