import { Table } from 'react-bootstrap';
import TableHead from '../../Components/TableHead/TableHead';
import { MdModeEditOutline } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import { FAQDeleteAction, FAQListAction } from '../../../features/CommonSlice';
import NoDataFound from "./../../Components/NoDataFound/NoDataFound";
import { Link, useNavigate } from 'react-router-dom';
import { BsTrash3 } from 'react-icons/bs';
import CommonModal from '../../Components/Modal/CommonModal';
import DeleteModal from '../../Components/Modal/DeleteModal';
import { BiPlus } from 'react-icons/bi';

const FAQsList = () => {
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [listDataArr, setListDataArr] = useState({});
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteContestID, setDeleteContestID] = useState("");
    const [statusUpdate, setStatusUpdate] = useState(1)

    useEffect(() => {
        setLoader(true)
        FAQListAction((response) => {
            if (response?.status === true) {
                setListDataArr(response)
            }
            setLoader(false)
        })();
    }, [FAQListAction, statusUpdate])

    const handleDeleteContest = () => {
        FAQDeleteAction({ id: deleteContestID }, (response) => {
            if (response?.status === true) {
                setStatusUpdate(statusUpdate + 1)
                setDeleteContestID("")
                setDeleteModalOpen(false)
            }
        })();
    }

    let tableHeader = [
        { label: "#", key: "", sort: false },
        { label: "Title", key: "", sort: false },
        { label: "Description", key: "", sort: false },
        { label: "Action", key: "", sort: false }
    ]
    return (
        <>
            <section className='mainSection'>
                <div className='title_breadcrumb_section'>
                    <div className='title_page'>FAQs Manager</div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">FAQs Manager</li>
                        </ol>
                    </nav>
                </div>

                <div className='common_section_main'>
                    <div className='searchAddRowTable'>
                        <button className='themeBtn_2' onClick={() => navigate("/admin/content-management-system/faq/add")}>
                            <BiPlus /> Add New
                        </button>
                    </div>
                    <Table responsive className="themeTable remove-word-wrap">
                        <TableHead data={tableHeader} />
                        <tbody>
                            {!loader ?
                                listDataArr?.data?.length > 0 ?
                                    listDataArr?.data?.map((value, index) => (
                                        <tr key={index}>
                                            <td> {(index + 1)}. </td>
                                            <td style={{width: "40%"}}> <div className='text-ellipsis'>{value?.question ? value?.question : "-"}</div> </td>
                                            <td style={{width: "40%"}}> <div className='text-ellipsis'>{value?.answer ? value?.answer : "-"}</div> </td>
                                            <td>
                                                <div className="btnTableGroup">
                                                    <button type="button" onClick={() => navigate(`/admin/content-management-system/faq/edit/${value?.id}`, { state: { ...value, mode: "edit" } })}>
                                                        <MdModeEditOutline />
                                                    </button>

                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setDeleteModalOpen(true)
                                                            setDeleteContestID(value.id)
                                                        }}
                                                    > <BsTrash3 /></button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <>
                                        <tr>
                                            <td colSpan={7} className='not_found_data_td'>
                                                <NoDataFound />
                                            </td>
                                        </tr>
                                    </>
                                :
                                <tr>
                                    <td colSpan={5}>Loading...</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                </div>
            </section>

            <CommonModal
                show={deleteModalOpen}
                onHide={() => {
                    setDeleteModalOpen(false)
                    setDeleteContestID("")
                }}
                modalClass="logout_modal"
                body={
                    <DeleteModal hide={setDeleteModalOpen} onSubmit={handleDeleteContest}
                        title="Are you delete?"
                        text="Are you sure you want to delete faq?"
                    />
                }
            />
        </>
    )
}

export default FAQsList