import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import TheHeader from '../views/Components/TheHeader/Header';
import TheFooter from '../views/Components/TheFooter/Footer';
import TheSidebar from '../views/Components/TheSidebar/Sidebar';
import "./layout.scss";


export default function TheLayout() {
	// const { pathname } = useLocation();
	// useEffect(() => {
	// 	window.scrollTo(0, 0);
	// }, [pathname]);

	return (
		<div className="app" >
			<div className="dashBoard_overLay"></div>
			<div className="layout">
				<TheHeader />
				<TheSidebar />
				<div className="page_container">
					<div className="main_content">
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	);
}
