import React from 'react';
import "../landing.scss";
import { SwiperSlide, Swiper } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

function ClientSlider() {
    return (
        <>
            <section className="clientSection">
                <h2 className="sr-only">&nbsp;</h2>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="swiper clientSwiper">

                                <Swiper
                                    modules={[Autoplay]}
                                    slidesPerView={6}
                                    spaceBetween={104}
                                    speed={100}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    breakpoints= {{
                                        "@0.00": {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        "@0.75": {
                                            slidesPerView: 4,
                                            spaceBetween: 40,
                                            centeredSlides: true,
                                             loop: true,
                                        },
                                        "@1.00": {
                                            slidesPerView: 5,
                                            spaceBetween:60,
                                            centeredSlides: true,
                                             loop: true,
                                        },
                                        "@1.50": {
                                            slidesPerView: 6,
                                            spaceBetween: 104
                                        }
                                    }}
                                    className="mySwiper"
                                >
                                    <SwiperSlide>
                                        <div className="swiper-slide">
                                            <div className="clientLogo_card">
                                                <img src="/images/website/client_1_logo.png" alt="" />
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="swiper-slide">
                                            <div className="clientLogo_card">
                                                <img src="/images/website/client_2_logo.png" alt="" />
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="swiper-slide">
                                            <div className="clientLogo_card">
                                                <img src="/images/website/client_3_logo.png" alt="" />
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="swiper-slide">
                                            <div className="clientLogo_card">
                                                <img src="/images/website/client_4_logo.png" alt="" />
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="swiper-slide">
                                            <div className="clientLogo_card">
                                                <img src="/images/website/client_5_logo.png" alt="" />
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="swiper-slide">
                                            <div className="clientLogo_card">
                                                <img src="/images/website/client_6_logo.png" alt="" />
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ClientSlider;