import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import TableHead from '../../Components/TableHead/TableHead';
import { MdModeEditOutline } from 'react-icons/md';
import { BiPlus } from 'react-icons/bi';
import { GameModeListAction, GameModeStatusUpdateAction } from '../../../features/CommonSlice';
import NoDataFound from "./../../Components/NoDataFound/NoDataFound";
import { Link } from 'react-router-dom';
import CommonModal from '../../Components/Modal/CommonModal';
import moment from 'moment';
import GameModeAddEdit from './AddEdit';
import { server } from '../../../common/Apis/axiosBaseURL';

const GameModeList = () => {
    const [statusUpdate, setStatusUpdate] = useState(1)
    const [listDataArr, setListDataArr] = useState({});
    const [viewEditGameModeModalOpen, setViewEditGameModeModalOpen] = useState(false);
    const [selectedGameModeData, setSelectedGameModeData] = useState("");

    useEffect(() => {
        GameModeListAction((response) => {
            if (response?.status === true) {
                setListDataArr(response)
                setSelectedGameModeData("")
            }
        })();
    }, [GameModeListAction, statusUpdate])

    const handleStatusUpdate = (id) => {
        GameModeStatusUpdateAction({ id }, (response) => {
            if (response?.status === true) {
                setStatusUpdate(statusUpdate + 1)
            }
        })();
    }

    let tableHeader = [
        { label: "#", key: "", sort: false },
        { label: "Name", key: "", sort: false },
        { label: "Icon", key: "", sort: false },
        { label: "Sequence No", key: "", sort: false },
        { label: "Description", key: "", sort: false },
        { label: "Status", key: "", sort: false },
        // { label: "Created", key: "", sort: false },
        { label: "Action", key: "", sort: false }
    ]

    return (
        <>
            <section className='mainSection'>

                <div className='title_breadcrumb_section'>
                    <div className='title_page'>GameMode List</div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">GameMode list</li>
                        </ol>
                    </nav>
                </div>

                <div className='common_section_main'>

                <div className='searchAddRowTable'>
                    <button className='themeBtn_2' onClick={() => setViewEditGameModeModalOpen(true)}>
                        <BiPlus /> Add New
                    </button>
                </div>

                <div className='tab_main_section_list'>
                    <Table responsive className="themeTable">
                        <TableHead data={tableHeader} />
                        <tbody>
                            {listDataArr?.data?.length > 0 ?
                                listDataArr?.data?.map((value, index) => (
                                    <tr key={index}>
                                        <td> {(index + 1)}. </td>
                                        <td> {value?.mode_name ? value?.mode_name : "-"} </td>
                                        <td>
                                            <div className='table_img_box'>
                                                <img src={`${server.baseURL}/${value?.image}`} width={100} height={80} className="img-fluid" alt="Icon" />
                                            </div>
                                        </td>
                                        <td> {value?.sequence ? value?.sequence : "-"} </td>
                                        <td> {value?.description ? value?.description : "-"}{value?.description?.length > 69 ? "..." : ""} </td>
                                        <td>
                                            {value?.id == 1 || value?.id == 2 ?
                                                null :
                                                <button
                                                    type='button'
                                                    className={`table_btn ${value?.status == 1 ? "active" : "inactive"}`}
                                                    onClick={() => handleStatusUpdate(value.id)}
                                                >
                                                    {value?.status == 1 ? "Active" : "Inactive"}
                                                </button>}
                                        </td>
                                        {/* <td> {value?.createdAt ? moment(value?.createdAt).format("DD-MM-YYYY hh:mm") : "-"} </td> */}
                                        <td>
                                            <div className="btnTableGroup">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setViewEditGameModeModalOpen(true)
                                                        setSelectedGameModeData({ ...value, mode: "edit" })
                                                    }}
                                                >
                                                    <MdModeEditOutline />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                :
                                <>
                                    <tr>
                                        <td colSpan={7} className='not_found_data_td'>
                                            <NoDataFound />
                                        </td>
                                    </tr>
                                </>
                            }
                        </tbody>
                    </Table>
                </div>
                </div>

            </section>

            <CommonModal
                show={viewEditGameModeModalOpen}
                onHide={() => {
                    setViewEditGameModeModalOpen(false)
                    setSelectedGameModeData("")
                }}
                title={`${selectedGameModeData?.mode ? "Edit" : "Create"} Game Mode`}
                body={
                    <GameModeAddEdit hide={setViewEditGameModeModalOpen} data={selectedGameModeData} setStatusUpdate={setStatusUpdate} />
                }
            />
        </>
    )
}

export default GameModeList