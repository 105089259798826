var CryptoJS = require("crypto-js");

export const moduleAccessArr = [
    // { label: "Dashboard", value: "Dashboard" },
    { label: "Admin Staff", value: "Admin Staff" },
    { label: "Users Manager", value: "Users Manager" },
    { label: "Series Manager", value: "Series Manager" },
    { label: "CMS Manager", value: "CMS Manager" },
    { label: "Game Mode Manager", value: "Game Mode Manager" },
    { label: "Category Manager", value: "Category Manager" },
    { label: "Teams Manager", value: "Teams Manager" },
    { label: "Mobile Banner Change", value: "Mobile Banner Change" },
    { label: "Player Manager", value: "Player Manager" },
    { label: "Settngs", value: "Settngs" },
    { label: "Contest Manager", value: "Contest Manager" },
    { label: "Upcoming Contest", value: "Upcoming Contest" },
    { label: "Points Manager", value: "Points Manager" },
    { label: "Wallet Manager", value: "Wallet Manager" },
    { label: "State Manager", value: "State Manager" },
    { label: "Contact Support", value: "Contact Support" },
]

export const AllModuleAccessArr = () => {
    return moduleAccessArr.map(item => item.value);
}

export const getUserSettings = () => {
    var token = CryptoJS.AES.decrypt(localStorage.afl_security_data, process.env.REACT_APP_JWT_SECRET);
    var decryptedData = JSON.parse(token.toString(CryptoJS.enc.Utf8));
}

export const debounceSearch = (func, delay) => {
    let timerId;
    return function (...args) {
        clearTimeout(timerId);
        timerId = setTimeout(() => func.apply(this, args), delay);
    };
};

export const HasConsecutiveSpaces = (str) => {
    const string = /  +/.test(str);
    return string
}

export const RemoveEmptyObjKey = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v));
}

export const isValidHttpUrl = (string) => {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

export const calculatePercentage = (value, percentage) => {
    return (value / 100) * percentage;
}