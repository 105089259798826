import React, { useState } from 'react';
import "../../Components/Modal/CommonModal.scss";
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { FieldText } from '../../Components/InputText/InputText';
import { Button } from '../../Components/Button/Button';
import { SeriesUpdateAction } from '../../../features/CommonSlice';

const SeriesEdit = ({ data, hide, setStatusUpdate}) => {
    const [loader, setLoader] = useState(false)
    return (
        <div className='delete_modal_main'>
            <div className=''>
                <Formik
                    initialValues={{
                        id: data?.cid ? data?.cid : '',
                        short_name: data?.short_name ? data?.short_name : '',
                    }}
                    validationSchema={Yup.object().shape({
                        short_name: Yup.string().required('Short name is required!'),
                    })}
                    onSubmit={(value) => {
                        setLoader(true)
                        SeriesUpdateAction(value, (response) => {
                            if (response?.status) {
                                hide(false)
                                setStatusUpdate(prevCount => prevCount + 1);
                            }
                            setLoader(false)
                        })();
                    }}
                >
                    {(formik) => {
                        return (
                            <Form autoComplete='off'>
                                <FieldText
                                    name="short_name"
                                    type="text"
                                    placeholder="Short Name"
                                    showlabel={true}
                                    label="Short Name"
                                    value={formik.values.short_name}
                                />



                                <div className='tow_button_card'>
                                    <button className='themeBtn w-auto m-0' type='button' onClick={() => hide(false)}> No</button>
                                    <Button
                                        className="themeBtn w-auto"
                                        text='Submit'
                                        type="submit"
                                        loader={loader}
                                        disabled={!(formik.isValid && formik.dirty) || loader}
                                    />
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default SeriesEdit;