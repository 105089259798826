import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom';
import { setCurrentUser } from './features/Auth/authSlice';
import { store } from './features/store';

import { PrivateRoutes } from './common/Layouts/PrivateRoutes';
import TheLayout from './containers/TheLayout';
import AuthLayout from './containers/AuthLayout';
import PageNotFound from './views/pages/PageNotFound/PageNotFound';

import Login from './views/pages/Authentication/login';
import Dashboard from './views/pages/Dashboard/Dashboard';
import ForgotPassword from './views/pages/Authentication/ForgotPassword';
import Verification from './views/pages/Authentication/Verification';
import ResetPassword from './views/pages/Authentication/ResetPassword';
import StaffList from "./views/pages/AdminStaff/StaffList"
import UserList from './views/pages/Users/List';
import UsersDetails from './views/pages/Users/Details';
import UserEdit from './views/pages/Users/Edit';
import TeamsList from './views/pages/Teams/List';
import SeriesList from './views/pages/Series/List';
import SeriesMatchesList from './views/pages/Series/Matches';
import CMSList from './views/pages/CMS/List';
import CMSEdit from './views/pages/CMS/Edit';
import BannerList from './views/pages/Banner/List';
import BannerAddEdit from './views/pages/Banner/AddEdit';
import BannerView from './views/pages/Banner/View';
import PlayersList from './views/pages/Players/List';
import Profile from './views/pages/Profile/Profile';
import Settings from './views/pages/Settings/Settings';
import WithdrawRequest from './views/pages/WithdrawRequest/WithdrawRequest';
import Transactions from './views/pages/Transactions/Transactions';
import CategoryList from './views/pages/Category/List';
import GameModeList from './views/pages/GameMode/List';
import SubAdminAddEdit from './views/pages/AdminStaff/AddEdit';
import ContestList from './views/pages/Contest/List';
import ContestAdd from './views/pages/Contest/Add';
import ContestDetails from './views/pages/Contest/View';
import UpcomingContest from './views/pages/UpcomingContest/UpcomingContest';
import PointsManager from './views/pages/PointsManager/PointsManager';
import WalletManager from './views/pages/WalletManager/WalletManager';
import TDS from './views/pages/TDS/tds';
import NotificationsSend from './views/pages/Notifications/NotificationsSend';
import NotificationsReceived from './views/pages/Notifications/NotificationsReceived';
import ContactUsList from './views/pages/ContactUsList/List';
import ContestBreakup from './views/pages/Contest/Breakup';
import UpcomingContestAssign from './views/pages/UpcomingContest/ContestAssign';
import ContestAssignedList from './views/pages/UpcomingContest/ContestAssignedList';
import ContestAssignedDetails from './views/pages/UpcomingContest/ContestAssignedDetails';
import ChangePassword from './views/pages/Profile/ChangePassword';
import LandingLayouts from './landingcommon/LandingLayouts/LandingLayouts';
import Home from './views/LandingPage/Home/Home';
import AboutUs from './views/LandingPage/cms/AboutUs';
import CricketPointSystem from './views/LandingPage/cms/CricketPointSystem';
import PrivacyPolicy from './views/LandingPage/cms/PrivacyPolicy';
import TermsConditions from './views/LandingPage/cms/TermsConditions';
import HowToPlay from './views/LandingPage/cms/HowToPlay';
import Legality from './views/LandingPage/cms/Legality';
import Rules from './views/LandingPage/cms/Rules';
import RefundsAndCancellations from './views/LandingPage/cms/RefundsAndCancellations';
import ContactUs from './views/LandingPage/cms/ContactUs';
import FairPlayPolicy from './views/LandingPage/cms/FairPlayPolicy';
import FAQs from './views/LandingPage/cms/FAQs';
import StatesList from './views/pages/State/List';
import FAQsList from './views/pages/CMS/faq_list';
import FAQAddEdit from './views/pages/CMS/FAQAddEdit';
import ContactSupport from './views/pages/ContactSupport/ContactSupport';
import ContactDetails from './views/pages/ContactSupport/ContactDetails';
import ScrollToTop from './views/pages/ScrollEffects/ScrollToTop';

var CryptoJS = require("crypto-js");


function App() {
    useEffect(() => {
        if (localStorage.afl_security_data) {
            var token = CryptoJS.AES.decrypt(localStorage.afl_security_data, process.env.REACT_APP_JWT_SECRET);
            var decryptedData = JSON.parse(token.toString(CryptoJS.enc.Utf8));
            store.dispatch(setCurrentUser(decryptedData));
        }
    }, [])


    return (
        <Router>
             <ScrollToTop />
            <Routes>
                {/* PageNotFound */}
                <Route path="*" element={<PageNotFound />} />

                <Route element={<AuthLayout />}>
                    <Route path="/login" exact element={<Login />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/new-password" element={<ResetPassword />} />
                    <Route path="/verification" element={<Verification />} />
                </Route>

                <Route element={<LandingLayouts />}>
                    <Route path="/" exact element={<Home />} />
                    <Route path="/about-us" exact element={<AboutUs />} />
                    <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
                    <Route path="/terms-conditions" exact element={<TermsConditions />} />
                    <Route path="/how-it-works" exact element={<HowToPlay />} />
                    <Route path="/legality" exact element={<Legality />} />
                    <Route path="/rules" exact element={<Rules />} />
                    <Route path="/refund-cancellation" exact element={<RefundsAndCancellations />} />
                    <Route path="/contact-us" exact element={<ContactUs />} />
                    <Route path="/fair-and-play" exact element={<FairPlayPolicy />} />
                    <Route path="/faq" exact element={<FAQs />} />
                    <Route path="/points-system" exact element={<CricketPointSystem />} />
                </Route>


                <Route element={<PrivateRoutes />}>
                    <Route path='admin' element={<TheLayout />}>
                        <Route path="" element={<Navigate to="/admin/dashboard" />} />
                        <Route path='dashboard' index element={<Dashboard />} />

                        {/* User Management */}
                        <Route path='user/list' index element={<UserList />} />
                        <Route path='user/detail/:id' index element={<UsersDetails />} />
                        <Route path='user/edit/:id' index element={<UserEdit />} />

                        {/* Team Management */}
                        <Route path='teams-manager/list' index element={<TeamsList />} />

                        {/* Series Management */}
                        <Route path='series-manager/list' index element={<SeriesList />} />
                        <Route path='series-manager/:sports_id/:series_id/matches/list' index element={<SeriesMatchesList />} />

                        {/* CMS Management */}
                        <Route path='content-management-system/list' index element={<CMSList />} />
                        <Route path='content-management-system/faq/list' index element={<FAQsList />} />
                        <Route path='content-management-system/faq/add' index element={<FAQAddEdit />} />
                        <Route path='content-management-system/faq/edit/:id' index element={<FAQAddEdit />} />
                        <Route path='content-management-system/edit/:id' index element={<CMSEdit />} />

                        {/* Banner Management */}
                        <Route path='banner-manager/list' index element={<BannerList />} />
                        <Route path='banner-manager/add' index element={<BannerAddEdit />} />
                        <Route path='banner-manager/edit/:id' index element={<BannerAddEdit />} />
                        <Route path='banner-manager/view/:id' index element={<BannerView />} />

                        {/* Players Management */}
                        <Route path='player-manager/list' index element={<PlayersList />} />

                        {/* Category Management */}
                        <Route path='category-manager/list' index element={<CategoryList />} />

                        {/* Game Mode Management */}
                        <Route path='game-mode-manager/list' index element={<GameModeList />} />


                        {/* Contest Management */}
                        <Route path='contest-manager/list' index element={<ContestList />} />
                        <Route path='contest-manager/add' index element={<ContestAdd />} />
                        <Route path='contest-manager/detail/:id' index element={<ContestDetails />} />
                        <Route path='contest-manager/contest-breakup/:id' index element={<ContestBreakup />} />

                        {/* Upcoming contest Management */}
                        <Route path='upcoming-contest/list' index element={<UpcomingContest />} />
                        <Route path='upcoming-contest/contest/assign/:id' index element={<UpcomingContestAssign />} />
                        <Route path='upcoming-contest/contest/assigned/list/:id' index element={<ContestAssignedList />} />
                        <Route path='upcoming-contest/contest/assigned/detail/:match_id/:id' index element={<ContestAssignedDetails />} />


                        {/* Profile Management */}
                        <Route path='profile' index element={<Profile />} />
                        <Route path='profile/change-password' index element={<ChangePassword />} />


                        {/* Setting Management */}
                        <Route path='settings' index element={<Settings />} />

                        {/* Contact Support Management */}
                        <Route path='contact-support' index element={<ContactSupport />} />
                        <Route path='contact-support/contact-details/:id' index element={<ContactDetails />} />

                        <Route path='wallet-manager/list' index element={<WalletManager />} />

                        <Route path='withdraw-request' index element={<WithdrawRequest />} />
                        <Route path='transactions' index element={<Transactions />} />
                        <Route path='points-manager' index element={<PointsManager />} />
                        <Route path='tds' index element={<TDS />} />
                        <Route path='notifications-send' index element={<NotificationsSend />} />
                        <Route path='notifications-received' index element={<NotificationsReceived />} />
                        <Route path='contact-us-list' index element={<ContactUsList />} />

                        {/* Game Mode Management */}
                        <Route path='subadmin/list' index element={<StaffList />} />
                        <Route path='subadmin/add' index element={<SubAdminAddEdit />} />
                        <Route path='subadmin/edit/:id' index element={<SubAdminAddEdit />} />

                        {/* State Management */}
                        <Route path='state-manager/list' index element={<StatesList />} />
                    </Route>
                </Route>
            </Routes>
        </Router>
    );
}

export default App;
