import React, { useEffect, useState } from "react";
import { UserDetailsAction } from "../../../features/CommonSlice";
import { Link, useParams } from "react-router-dom";

const UsersDetails = () => {
    const { id } = useParams()
    const [loader, setLoader] = useState(false);
    const [userDetails, setUserDetails] = useState({});

    useEffect(() => {
        setLoader(true)
        UserDetailsAction({ id }, (response) => {
            if (response?.status === true) {
                setUserDetails(response)
            }
            setLoader(false)
        })();
    }, [UserDetailsAction])

    return (
        !loader ?
            <div className="addLeagueBlock">
                <div className='title_breadcrumb_section'>
                    <div className='title_page'>User Detail</div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/admin/user/list">User List</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">User detail</li>
                        </ol>
                    </nav>
                </div>
                <div className="common_section_main">

                <div className="detailFormBlock">
                    <div className="view_user_list_details">
                        <div className="row g-md-5 g-4">
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>First Name :</label>
                                    <span> {userDetails?.data?.first_name ? userDetails?.data?.first_name : "-"} </span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Last Name :</label>
                                    <span> {userDetails?.data?.last_name ? userDetails?.data?.last_name : "-"}</span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Email :</label>
                                    <span> {userDetails?.data?.email ? userDetails?.data?.email : "-"} </span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Phone Number :</label>
                                    <span> {userDetails?.data?.phone ? userDetails?.data?.phone : "-"} </span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Gender :</label>
                                    <span> {userDetails?.data?.gender == 1 ? "Female" : "Male"} </span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Date of Birth :</label>
                                    <span> {userDetails?.data?.date_of_bith ? userDetails?.data?.date_of_bith : "-"} </span>
                                </div>
                            </div>

                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label> Referral Code :</label>
                                    <span> {userDetails?.data?.refer_id ? userDetails?.data?.refer_id : "-"} </span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Country Name :</label>
                                    <span> {userDetails?.data?.country ? userDetails?.data?.country : "-"} </span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>State Name :</label>
                                    <span> {userDetails?.data?.state ? userDetails?.data?.state : "-"} </span>
                                </div>
                            </div>

                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>City Name :</label>
                                    <span> {userDetails?.data?.city ? userDetails?.data?.city : "-"} </span>
                                </div>
                            </div>

                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Postal Code :</label>
                                    <span> {userDetails?.data?.postal_code ? userDetails?.data?.postal_code : "-"} </span>
                                </div>
                            </div>

                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Bonus Amount :</label>
                                    <span> {userDetails?.data?.bonus_amount ? userDetails?.data?.bonus_amount : "0"} </span>
                                </div>
                            </div>

                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Cash Balance :</label>
                                    <span> {userDetails?.data?.cash_balance ? userDetails?.data?.cash_balance : "0"} </span>
                                </div>
                            </div>

                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label> Winning Balance :</label>
                                    <span> {userDetails?.data?.winning_balance ? userDetails?.data?.winning_balance : "0"} </span>
                                </div>
                            </div>

                            <div className="col-12">
                                <Link to="/admin/user/list" className="themeBtn view_page_btn">Close</Link>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            :
            <div>Loading...</div>
    )
}

export default UsersDetails