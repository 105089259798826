import React from 'react';
import { Link } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';

const ContactUsList = () => {

    return (
        <div className='addLeagueBlock notifications_send'>
            <div className='title_breadcrumb_section'>
                <div className='title_page'>Contact Us List</div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Contact us list</li>
                    </ol>
                </nav>
            </div>
        </div>
    )
}

export default ContactUsList