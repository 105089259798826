import { Table } from 'react-bootstrap';
import TableHead from '../../Components/TableHead/TableHead';
import { MdModeEditOutline } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import { CMSListAction } from '../../../features/CommonSlice';
import NoDataFound from "./../../Components/NoDataFound/NoDataFound";
import { Link, useNavigate } from 'react-router-dom';

const CMSList = () => {
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [listDataArr, setListDataArr] = useState({});

    useEffect(() => {
        setLoader(true)
        CMSListAction((response) => {
            if (response?.status === true) {
                setListDataArr(response)
            }
            setLoader(false)
        })();
    }, [CMSListAction])


    let tableHeader = [
        { label: "#", key: "", sort: false },
        { label: "Title", key: "", sort: false },
        { label: "Description", key: "", sort: false },
        { label: "Action", key: "", sort: false }
    ]
    return (
        <section className='mainSection'>

            <div className='title_breadcrumb_section'>
                <div className='title_page'>CMS Manager</div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">CMS Manager</li>
                    </ol>
                </nav>
            </div>

            <div className='common_section_main'>
            <Table responsive className="themeTable">
                <TableHead data={tableHeader} />
                <tbody>
                    {!loader ?
                        listDataArr?.data?.length > 0 ?
                            listDataArr?.data?.map((value, index) => (
                                <tr key={index}>
                                    <td> {(index + 1)}. </td>
                                    <td> {value?.title ? value?.title : "-"} </td>
                                    <td>
                                        {value?.description ?
                                            <div dangerouslySetInnerHTML={{ __html: value?.description }} />
                                            : "-"}
                                    </td>
                                    <td>
                                        <div className="btnTableGroup">
                                            <button type="button" onClick={() => navigate(`/admin/content-management-system/edit/${value?.id}`)}>
                                                <MdModeEditOutline />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                            :
                            <>
                                    <tr>
                                    <td colSpan={7} className='not_found_data_td'>
                                        <NoDataFound />
                                    </td>
                                </tr>
                            </>
                        :
                        <tr>
                            <td colSpan={5}>Loading...</td>
                        </tr>
                    }
                </tbody>
            </Table>
            </div>
        </section>
    )
}

export default CMSList