import React from 'react';
import { Link } from 'react-router-dom';
import { BiReset, BiSearch } from "react-icons/bi";

const WithdrawRequest = () => {
    return (
        <section className='mainSection'>

            <div className='title_breadcrumb_section'>
                <div className='title_page'>Withdraw List</div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Withdraw list</li>
                    </ol>
                </nav>
            </div>
            <div className='common_section_main'>

            <form className='searchAddRowTable withdraw_request_page grid_row_section'>
                <div className='inputGroupSearch'>
                    <input
                        placeholder="Enter Name"
                        type="text"
                        name="name"
                        className="form-control"
                    />
                </div>

                <div className='inputGroupSearch'>
                    <input
                        placeholder="Email Address"
                        type="email"
                        name="email"
                        className="form-control"
                    />
                </div>

                <div className='inputGroupSearch'>
                    <input
                        placeholder="Phone Number"
                        type="number"
                        name="phone"
                        className="form-control"
                    />
                </div>

                <div className='inputGroupSearch date_input'>
                    <input
                        placeholder="Request From"
                        type="date"
                        name="email"
                        className="form-control"
                    />
                </div>

                <div className='inputGroupSearch date_input'>
                    <input
                        placeholder="Request To"
                        type="date"
                        name="email"
                        className="form-control"
                    />
                </div>

                <div className='flexBtnGroup list_top_filter'>
                    <button className='themeBtn_2' type='button'>
                        <BiSearch />
                        Search
                    </button>

                    <button className='themeBtn_2' type='button'>
                        <BiReset />
                        Reset
                    </button>
                </div>

            </form>
            </div>
        </section>
    )
}

export default WithdrawRequest