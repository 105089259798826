import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsTrash3 } from 'react-icons/bs';
import { ToastOverError } from '../../../common/Toast/ToastOver';
import { calculatePercentage } from '../../../utils/Function';
import { Button } from '../../Components/Button/Button';
import { ContestPriceBreakUpAction } from '../../../features/CommonSlice';

const ContestBreakup = () => {
    const [loader, setLoader] = useState(false)
    const { state } = useLocation();
    const navigate = useNavigate()
    const [formValues, setFormValues] = useState([
        { start: 1, end: "", percentage: "", price: "", percentage_seprate: "", price_seprate: "" }
    ]);

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
        let lastEndValue = newFormValues[newFormValues?.length - 2]
        if (e.target.name == "end") {
            if (newFormValues?.length == 1) {
                newFormValues[i]["price_seprate"] = (Number(e.target.value) * Number(newFormValues[i]["price"]))
                newFormValues[i]["percentage_seprate"] = (Number(e.target.value) * Number(newFormValues[i]["percentage"]))
            } else if (newFormValues?.length > 1) {
                newFormValues[i]["price_seprate"] = ((Number(e.target.value) - Number(lastEndValue?.end)) * Number(newFormValues[i]["price"]))
                newFormValues[i]["percentage_seprate"] = ((Number(e.target.value) - Number(lastEndValue?.end)) * Number(newFormValues[i]["percentage"]))
            }
        }
        if (e.target.name === "percentage") {
            newFormValues[i]["price"] = calculatePercentage(Number(state?.winning_amount), Number(e.target.value))
            if (newFormValues?.length == 1) {
                newFormValues[i]["price_seprate"] = (Number(newFormValues[i]["end"]) * Number(newFormValues[i]["price"]))
                newFormValues[i]["percentage_seprate"] = (Number(newFormValues[i]["end"]) * Number(e.target.value))
            } else if (newFormValues?.length > 1) {
                newFormValues[i]["price_seprate"] = ((Number(newFormValues[i]["end"]) - Number(lastEndValue?.end)) * Number(newFormValues[i]["price"]))
                newFormValues[i]["percentage_seprate"] = ((Number(newFormValues[i]["end"]) - Number(lastEndValue?.end)) * Number(e.target.value))
            }
        }

        if (additionValues(formValues, "percentage_seprate") > 100) {
            ToastOverError("Total price is greater than winning price and total percentage is greater than 100%.")
        }
    };

    const additionValues = (data, key) => {
        let total = data.reduce((sum, item) => Number(sum) + Number(item[key]), 0);
        return total.toFixed(2)
    }

    const blankObjectValue = (data) => {
        return Object.values(data[data.length - 1]).every(value => value !== "");
    }

    let addFormFields = () => {
        let ObjChecker = blankObjectValue(formValues)
        let lastEndValue = formValues[formValues?.length - 1]
        if (ObjChecker) {
            if (additionValues(formValues, "percentage_seprate") < 100 && additionValues(formValues, "price_seprate") < Number(state?.winning_amount)) {
                setFormValues([
                    ...formValues,
                    { start: (Number(lastEndValue?.end) + 1), end: "", percentage: "", price: "", percentage_seprate: "", price_seprate: "" }
                ]);
            } else {
                ToastOverError("No you can not add another row now")
            }
        } else {
            ToastOverError("Please fill this row first.")
        }
    };

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
    };

    const onSubmit = () => {
        if (additionValues(formValues, "percentage_seprate") == 100) {
            setLoader(true)
            let data = {
                breakup: formValues,
                id: state.id
            }
            ContestPriceBreakUpAction(data, (response) => {
                if (response?.status == true) {
                    navigate(`/admin/contest-manager/list`)
                }
                setLoader(false)
            })()
        } else {
            ToastOverError("Total percentage must be equal to 100.")
        }
    }

    return (
        <div className="addLeagueBlock">
            <div className='title_breadcrumb_section'>
                <div className='title_page'> Price Breakdown </div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/contest-manager/list"> Contest </Link></li>
                        <li className="breadcrumb-item active" aria-current="page"> Price Breakdown </li>
                    </ol>
                </nav>
            </div>

            <div className='common_section_main'>
                <div className="addLeagueForm_block add_staff_page user_list_edit">
                    <div className="formAddBlock">
                        <div className="contest-breakup-head">
                            <div className="item"> Contest size : <strong> {state?.contest_size} </strong></div>
                            <div className="item"> Winning price (INR): <strong> {state?.winning_amount} </strong></div>
                            <div className="item"> Entry fee (INR) : <strong> {state?.entry_fee} </strong></div>
                        </div>

                        <button
                            onClick={() => addFormFields()}
                            type='button'
                            className='add_btn_button'
                        > Add row </button>

                        <Table responsive className="themeTable">
                            <thead>
                                <tr>
                                    <th> #  </th>
                                    <th> Start rank </th>
                                    <th> End rank </th>
                                    <th> Percentage <br /> (each) </th>
                                    <th> Price <br /> (each) </th>
                                    <th> Total percentage <br /> (<span>{additionValues(formValues, "percentage_seprate")}</span>)  </th>
                                    <th> Total price <br /> (<span>{additionValues(formValues, "price_seprate")}</span>)  </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {formValues.map((element, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <input
                                                    required
                                                    type="number"
                                                    // onChange={(e) => handleChange(index, e)}
                                                    value={element.start || ""}
                                                    name="start"
                                                    readOnly="readonly"
                                                    className='form-control'
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    required
                                                    type="number"
                                                    onChange={(e) => handleChange(index, e)}
                                                    value={element.end || ""}
                                                    name="end"
                                                    className='form-control'
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    required
                                                    type="number"
                                                    onChange={(e) => handleChange(index, e)}
                                                    value={element.percentage || ""}
                                                    name="percentage"
                                                    className='form-control'
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    required
                                                    type="number"
                                                    onChange={(e) => handleChange(index, e)}
                                                    value={element.price || ""}
                                                    name="price"
                                                    className='form-control'
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    required
                                                    type="number"
                                                    onChange={(e) => handleChange(index, e)}
                                                    value={element.percentage_seprate || ""}
                                                    name="percentage_seprate"
                                                    className='form-control'
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    required
                                                    type="number"
                                                    onChange={(e) => handleChange(index, e)}
                                                    value={element.price_seprate || ""}
                                                    name="price_seprate"
                                                    className='form-control'
                                                />
                                            </td>
                                            <td className='text-end'>
                                                {index !== 0 &&
                                                    <button
                                                        className='delete_btn'
                                                        type="button"
                                                        onClick={() => removeFormFields(index)}
                                                    >
                                                        <BsTrash3 />
                                                    </button>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>

                        <Button
                            className="themeBtn edit_page_btn breakup_btn"
                            text='Submit'
                            type="submit"
                            loader={loader}
                            disabled={loader}
                            onClick={onSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContestBreakup