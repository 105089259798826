import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';


function TheHeader() {

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [isSticky, setIsSticky] = useState(false);
    const [swichState, setSwichState] = useState(false)
    const [menuOpened, setMenuOpened] = useState(false);

    useEffect(() => {
        function handleScroll() {
            const currentScrollPos = window.pageYOffset;
            const header = document.getElementById("header");
            const HeadH = document.getElementById("header").offsetHeight;

            if (currentScrollPos > prevScrollPos) {
                header.classList.remove("scroll-up");
            } else {
                header.classList.add("scroll-up");
            }

            if (currentScrollPos < HeadH * 1.5) {
                header.classList.remove("is-sticky");
            } else {
                header.classList.add("is-sticky");
            }

            setPrevScrollPos(currentScrollPos);
            setIsSticky(currentScrollPos >= HeadH * 1.5);
        }

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [prevScrollPos]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        if (swichState) {
            document.body.classList.add('sidenavopen');
        } else {
            document.body.classList.remove('sidenavopen')
        }
    }, [swichState]);

    const handletoggleButton = () => {
        setSwichState(!swichState)
    }

    const handleClick = () => {
      setMenuOpened(!menuOpened);
      const headerCollapse = document.getElementById('header').querySelector('.collapse:not(.show)');
      headerCollapse.classList.toggle('menu-show');
      document.body.classList.toggle('scroll-off');
    };


    return (
        <>
            <header id="header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="navbar navbar-expand-lg">
                                {/* <button onClick={handletoggleButton} className="navbar-toggler" type="button">
                                    <span className="navbar-toggler-icon" />
                                </button> */}
                                <button className={`navbar-toggler ${menuOpened ? 'menu-opened' : ''}`} onClick={handleClick}>
                                <span className="navbar-toggler-icon" />
                                </button>
                                <Link className="navbar-brand d-lg-none" to="/">
                                    <img src="/images/logo.png" alt="" />
                                </Link>
                                <div className="collapse navbar-collapse navbar_header_web">
                                    <ul className="navbar-nav ">
                                        <li className="nav-item"><NavLink onClick={handleClick} className='nav-link' to="/">Home</NavLink></li>
                                        <li className="nav-item"><NavLink onClick={handleClick} className='nav-link' to="/about-us">About</NavLink></li>
                                        <li className="nav-item"><NavLink onClick={handleClick} className='nav-link' to="/how-it-works">How to Play</NavLink></li>
                                    </ul>
                                    <Link className="navbar-brand d-none d-lg-block" to="/">
                                        <img className='logo_landing' src="/images/logo.png" alt="" />
                                    </Link>
                                    <ul className="navbar-nav ">
                                        <li className="nav-item"><NavLink onClick={handleClick} className="nav-link" to="/faq">FAQ</NavLink></li>
                                        <li className='nav-item'><NavLink onClick={handleClick} className="nav-link" to="/rules">Rules</NavLink></li>
                                        <li className='nav-item'><NavLink onClick={handleClick} className="nav-link" to="/points-system">Points System</NavLink></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default TheHeader;