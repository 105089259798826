import React from "react"

const CategoryView = ({ data, hide }) => {
    return (
        <div className='delete_modal_main'>
            <div className="name_cate">Category Name:  <span>{data?.category_name}</span></div>
            <div className="name_cate">Description:  <span>{data?.description}</span> </div>
            <div className="name_cate">sequence:  <span>{data?.sequence}</span></div>
            <div className="name_cate">status:  <button type='button' className={`table_btn ${data?.status == 1 ? "active" : "inactive"}`}> {data?.status == 1 ? "Active" : "Inactive"} </button></div>
        </div>
    )
}

export default CategoryView