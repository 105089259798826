import { Table, Tabs, Tab } from 'react-bootstrap';
import TableHead from '../../Components/TableHead/TableHead';
import { MdModeEditOutline } from 'react-icons/md';
import { BiSearch } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { PlayerListAction, SeriesListAction, TeamListAction } from '../../../features/CommonSlice';
import NoDataFound from "./../../Components/NoDataFound/NoDataFound";
import { Pagination } from "./../../Components/Hooks/Pagination"
import { HasConsecutiveSpaces, RemoveEmptyObjKey, debounceSearch, isValidHttpUrl } from '../../../utils/Function';
import { Link } from 'react-router-dom';
import { server } from '../../../common/Apis/axiosBaseURL';
// import TeamEdit from './Edit';
import CommonModal from '../../Components/Modal/CommonModal';
import EditPlayerDetails from './EditPlayerDetails';

const PlayersList = () => {
    const [statusUpdate, setStatusUpdate] = useState(1)
    const [loader, setLoader] = useState(false)
    const [listDataArr, setListDataArr] = useState({});
    const [seriesListArr, setSeriesListArr] = useState([])
    const [TeamListArr, setTeamListArr] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [perPageCount, setPerPageCount] = useState(30);
    const [searchPlayerName, setSearchPlayerName] = useState("");
    const [selectedTabID, setSelectedTabID] = useState(1);
    const [playerID, setPlayerID] = useState("");
    const [TeamID, setTeamID] = useState("");
    const [editPlayerModalOpen, setEditPlayerModalOpen] = useState(false);
    const [selectedPlayerData, setSelectedPlayerData] = useState("");

    useEffect(() => {
        if (playerID) {
            let payload = {
                page: playerID ? 1 : currentPage,
                pageSize: perPageCount,
                searchPlayerName: searchPlayerName,
                sports_id: selectedTabID,
                series_id: playerID,
                team_id: TeamID
            }
            setLoader(true)
            PlayerListAction(RemoveEmptyObjKey(payload), (response) => {
                if (response?.status === true) {
                    setListDataArr(response)
                }
                setLoader(false)
            })();
        }
    }, [PlayerListAction, perPageCount, currentPage, searchPlayerName, selectedTabID, playerID, statusUpdate, TeamID])


    useEffect(() => {
        SeriesListAction({ page: 0, sports_id: selectedTabID }, (response) => {
            if (response?.status === true) {
                setSeriesListArr(response?.data)
            }
        })();
    }, [SeriesListAction, selectedTabID])

    const handleFilters = debounceSearch((e) => {
        const { name, value } = e.target
        if (HasConsecutiveSpaces(value)) return <></>;
        if (name == "player_search") setSearchPlayerName(value)
        if (name == "series_id") {
            setTeamListArr([])
            setPlayerID(value)
            setTeamID("")
            TeamListAction({ page: 0, sports_id: selectedTabID, series_id: value }, (response) => {
                if (response?.status === true) {
                    setTeamListArr(response?.data)
                }
            })();
        }
        if (name == "team_id") setTeamID(value)
    }, 300);

    let tableHeader = [
        { label: "#", key: "", sort: false },
        { label: "Name", key: "", sort: false },
        { label: "Short Name", key: "", sort: false },
        { label: "Image", key: "", sort: false },
        { label: "Role", key: "", sort: false },
        { label: "Team", key: "", sort: false },
        { label: "Credit", key: "", sort: false },
        { label: "Action", key: "", sort: false }
    ]
    return (
        <>
            <section className='mainSection'>

                <div className='title_breadcrumb_section'>
                    <div className='title_page'> Player List</div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Player list</li>
                        </ol>
                    </nav>
                </div>

                <div className='common_section_main'>
                <div className='tab_main_section_list'>
                    <Tabs defaultActiveKey={1} onSelect={(e) => setSelectedTabID(Number(e))}>
                        {["Cricket", "Football"].map((value, index) => (
                            <Tab eventKey={index + 1} title={value} key={index}>
                                <div className='searchAddRowTable mt-5'>
                                    <div className='inputGroupSearch search_input'>
                                        <select className='form-control select_white' name='series_id' onChange={handleFilters}>
                                            <option value=""> Select Series </option>
                                            {seriesListArr?.map((value, index) => (
                                                <option value={value?.cid} key={index}>{value?.name}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className='inputGroupSearch search_input'>
                                        <select className='form-control select_white' name='team_id' onChange={handleFilters}>
                                            <option value=""> Select Team </option>
                                            {TeamListArr?.map((value, index) => (
                                                <option value={value?.team_id} key={index}>{value?.team_name}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className='inputGroupSearch search_input'>
                                        <input type='text' name="player_search" className='form-control' placeholder='Search by player name ..' onChange={handleFilters} />
                                        <button type='button'><BiSearch /></button>
                                    </div>
                                </div>

                                <Table responsive className="themeTable">
                                    <TableHead data={tableHeader} />
                                    <tbody>
                                        {!loader ?
                                            listDataArr?.data?.list?.length > 0 ?
                                                listDataArr?.data?.list?.map((value, index) => (
                                                    <tr key={index}>
                                                        <td> {(currentPage - 1) * Number(listDataArr?.data?.perpage_count) + (index + 1)}. </td>
                                                        <td> {value?.player_name ? value?.player_name : "-"} </td>
                                                        <td> {value?.short_name ? value?.short_name : "-"} </td>
                                                        <td>
                                                            {
                                                                isValidHttpUrl(value?.player_record?.image) ?
                                                                    <img src={value?.player_record?.image} width={60} height={60} className="img-fluid" alt={value?.player_name} />
                                                                    :
                                                                    value?.player_record?.image ?
                                                                        <img src={`${server.baseURL}/${value?.player_record?.image}`} width={60} height={60} className="img-fluid" alt={value?.player_name} />
                                                                        :
                                                                        <img src="/images/player-placeholder.jpeg" width={60} height={60} alt={value?.player_name} className="img-fluid" />
                                                            }
                                                        </td>
                                                        <td>
                                                            {value?.player_role == "bat" ? "Batsman" :
                                                                value?.player_role == "all" ? "Allrounder" :
                                                                    value?.player_role == "bowl" ? "Bowler" :
                                                                        value?.player_role == "wk" ? "Wicketkeeper" :
                                                                            ""}
                                                        </td>
                                                        <td> {value?.team_name ? value?.team_name : "-"} </td>
                                                        <td> {value?.player_record?.player_credit ? value?.player_record?.player_credit : "-"}</td>
                                                        <td>
                                                            <div className="btnTableGroup">
                                                                <button type="button" onClick={() => {
                                                                    setEditPlayerModalOpen(true)
                                                                    setSelectedPlayerData(value)
                                                                }}>
                                                                    <MdModeEditOutline />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                                :
                                                <>
                                                    <tr>
                                                        <td colSpan={7} className='not_found_data_td'>
                                                            <NoDataFound />
                                                        </td>
                                                    </tr>
                                                </>
                                            :
                                            <tr>
                                                <td colSpan={7}>Loading...</td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>

                                {listDataArr?.data?.list?.length > 0 ?
                                    <div className='pagination_entries_section'>
                                        <div className='entries_text'>
                                            <select className='common_input_filed' onChange={(e) => setPerPageCount(e.target.value)}>
                                                <option value={10}>10</option>
                                                <option value={25}>25</option>
                                                <option value={50}>50</option>
                                                <option value={100}>100</option>
                                            </select>
                                        </div>

                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={listDataArr?.data?.totalcount}
                                            pageSize={listDataArr?.data?.perpage_count}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        />
                                    </div>
                                    :
                                    null
                                }
                            </Tab>
                        ))}
                    </Tabs>
                </div>
                </div>


            </section>

            <CommonModal
                show={editPlayerModalOpen}
                onHide={() => setEditPlayerModalOpen(false)}
                title="Edit Player"

                body={
                    <EditPlayerDetails hide={setEditPlayerModalOpen} data={selectedPlayerData} setStatusUpdate={setStatusUpdate} />
                }
            />
        </>
    )
}

export default PlayersList