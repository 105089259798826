import React, { useEffect, useState } from "react"
import { Form, Formik, useFormikContext } from 'formik';
import * as Yup from "yup";
import { FieldText } from "../../Components/InputText/InputText";
import { Button } from "../../Components/Button/Button";
import { CategoryCreateAction, CategoryUpdateAction } from "../../../features/CommonSlice";

const CategoryAddEdit = ({ data, hide, setStatusUpdate }) => {
    const [loader, setLoader] = useState(false)
    const [formDataSaved, setFormDataSaved] = useState(false);

    const FormikFromFunc = () => {
        const formikFrom = useFormikContext();
        useEffect(() => {
            if (!formDataSaved && data) {
                formikFrom.setValues({
                    sequence: data?.sequence,
                    category_name: data?.category_name,
                    description: data?.description
                })
                setFormDataSaved(true)
            }
        }, [data])
    }

    return (
        <div className='delete_modal_main'>
            <div className=''>
                <Formik
                    initialValues={{
                        sequence: "",
                        category_name: "",
                        description: ""
                    }}
                    validationSchema={Yup.object().shape({
                        sequence: Yup.number().required("Sequence is required!"),
                        description: Yup.string().required("Description is required!"),
                        category_name: Yup.string().required("Category name is required!"),
                    })}
                    onSubmit={(value) => {
                        setLoader(true)
                        if (data?.mode == "edit") {
                            CategoryUpdateAction({ ...value, sequence: value?.sequence.toString(), id: data?.id }, (response) => {
                                if (response?.status === true) {
                                    hide(false)
                                    setStatusUpdate(prevCount => prevCount + 1);
                                }
                                setLoader(false)
                            })()
                        } else {
                            CategoryCreateAction(value, (response) => {
                                if (response?.status === true) {
                                    hide(false)
                                    setStatusUpdate(prevCount => prevCount + 1);
                                }
                                setLoader(false)
                            })()
                        }
                    }}
                >
                    {(formik) => {
                        return (
                            <Form autoComplete='off'>
                                <FormikFromFunc />
                                <div className="row g-3 g-md-4">
                                    <div className="col-12">
                                        <FieldText
                                            name="category_name"
                                            type="text"
                                            placeholder="Category Name"
                                            value={formik.values.category_name}
                                            label="Category Name"
                                            showlabel={true}
                                        />
                                    </div>

                                    <div className="col-12">
                                        <FieldText
                                            name="description"
                                            type="text"
                                            placeholder="Description"
                                            value={formik.values.description}
                                            label="Description"
                                            showlabel={true}
                                        />
                                    </div>

                                    <div className="col-12">
                                        <FieldText
                                            name="sequence"
                                            type="text"
                                            placeholder="Sequence Number"
                                            value={formik.values.sequence}
                                            label="Sequence"
                                            showlabel={true}
                                        />
                                    </div>


                                    <div className="col-12">
                                        <Button
                                            className="themeBtn edit_page_btn"
                                            text='Submit'
                                            type="submit"
                                            loader={loader}
                                            disabled={!(formik.isValid && formik.dirty) || loader}
                                        />
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default CategoryAddEdit