import { Table } from 'react-bootstrap';
import TableHead from '../../Components/TableHead/TableHead';
import { MdModeEditOutline } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import { BannerDeleteAction, BannerListAction, BannerStatusUpdateAction } from '../../../features/CommonSlice';
import NoDataFound from "./../../Components/NoDataFound/NoDataFound";
import { Link, useNavigate } from 'react-router-dom';
import { isValidHttpUrl } from '../../../utils/Function';
import { server } from '../../../common/Apis/axiosBaseURL';
import { BsEyeFill, BsTrash3 } from 'react-icons/bs';
import { BiPlus } from 'react-icons/bi';
import CommonModal from '../../Components/Modal/CommonModal';
import DeleteModal from '../../Components/Modal/DeleteModal';
import moment from 'moment';

const BannerList = () => {
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [listDataArr, setListDataArr] = useState({});
    const [statusUpdate, setStatusUpdate] = useState(1);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteBannerID, setDeleteBannerID] = useState("")

    useEffect(() => {
        setLoader(true)
        BannerListAction((response) => {
            if (response?.status === true) {
                setListDataArr(response)
            }
            setLoader(false)
        })();
    }, [BannerListAction, statusUpdate])


    const handleStatusUpdate = (id) => {
        BannerStatusUpdateAction({ id }, (response) => {
            if (response?.status === true) {
                setStatusUpdate(statusUpdate + 1)
            }
        })();
    }

    let tableHeader = [
        { label: "#", key: "", sort: false },
        { label: "Image", key: "", sort: false },
        { label: "Sequence", key: "", sort: false },
        { label: "Series", key: "", sort: false },
        { label: "Status", key: "", sort: false },
        { label: "Modified", key: "", sort: false },
        { label: "Action", key: "", sort: false }
    ]

    const handleDeleteBanner = () => {
        BannerDeleteAction({ id: deleteBannerID }, (response) => {
            if (response?.status === true) {
                setStatusUpdate(statusUpdate + 1)
                setDeleteBannerID("")
                setDeleteModalOpen(false)
            }
        })();
    }
    return (
        <>
            <section className='mainSection'>
                <div className='title_breadcrumb_section'>
                    <div className='title_page'>Banner List</div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Banner list</li>
                        </ol>
                    </nav>
                </div>

                <div className='common_section_main'>
                    <div className='searchAddRowTable'>
                        <button className='themeBtn_2' onClick={() => navigate("/admin/banner-manager/add")}>
                            <BiPlus /> Add New
                        </button>
                    </div>
                    <Table responsive className="themeTable">
                        <TableHead data={tableHeader} />
                        <tbody>
                            {!loader ?
                                listDataArr?.data?.length > 0 ?
                                    listDataArr?.data?.map((value, index) => (
                                        <tr key={index}>
                                            <td> {(index + 1)}. </td>
                                            <td>
                                                <div className='table_img_box'>
                                                    {
                                                        isValidHttpUrl(value?.image) ?
                                                            <img src={value?.image} width={100} height={80} className="img-fluid" alt="Banner" />
                                                            :
                                                            <img src={`${server.baseURL}/${value?.image}`} width={100} height={80} className="img-fluid" alt="Banner" />
                                                    }
                                                </div>
                                            </td>
                                            <td> {value?.sequence ? value?.sequence : "-"} </td>
                                            <td> {value?.series ? value?.series?.name : "-"} </td>
                                            <td>
                                                <button
                                                    type='button'
                                                    className={`table_btn ${value?.status == 1 ? "active" : "inactive"}`}
                                                    onClick={() => handleStatusUpdate(value.id)}
                                                >
                                                    {value?.status == 1 ? "Active" : "Inactive"}
                                                </button>
                                            </td>
                                            <td> {value?.updatedAt ? moment(value?.updatedAt).format("DD-MM-YYYY hh:mm") : "-"} </td>
                                            <td>
                                                <div className="btnTableGroup">
                                                    <button type="button" onClick={() => navigate(`/admin/banner-manager/edit/${value?.id}`, { state: { ...value, mode: "edit" } })}>
                                                        <MdModeEditOutline />
                                                    </button>
                                                    <button type="button" onClick={() => navigate(`/admin/banner-manager/view/${value?.id}`, { state: value })}><BsEyeFill /></button>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setDeleteModalOpen(true)
                                                            setDeleteBannerID(value.id)
                                                        }}
                                                    > <BsTrash3 /></button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <>
                                        <tr>
                                            <td colSpan={7} className='not_found_data_td'>
                                                <NoDataFound />
                                            </td>
                                        </tr>
                                    </>
                                :
                                <tr>
                                    <td colSpan={6}>Loading...</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                </div>
            </section>

            <CommonModal
                show={deleteModalOpen}
                onHide={() => {
                    setDeleteModalOpen(false)
                    setDeleteBannerID("")
                }}
                modalClass="logout_modal"
                body={
                    <DeleteModal hide={setDeleteModalOpen} onSubmit={handleDeleteBanner}
                        title="Are you delete?"
                        text="Are you sure you want to delete banner?"
                    />

                }
            />
        </>
    )
}

export default BannerList