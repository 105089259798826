import React from 'react';
import TheHeader from '../../views/LandingComponents/TheHeader/TheHeader';
import { Outlet } from 'react-router-dom';
import TheFooter from '../../views/LandingComponents/TheFooter/TheFooter';
import "../../../src/views/LandingPage/landing.scss";
import "../../../src/views/LandingPage/responsive.scss";

function LandingLayouts() {
    return (
        <>
            <div className='landing_layouts_section'>
                <div className='herder_main'>
                    <TheHeader />
                </div>
                <div className='landing_content'>
                    <Outlet />
                </div>
                <div className='main_footer'>
                    <TheFooter />
                </div>
            </div>
        </>
    );
}

export default LandingLayouts;