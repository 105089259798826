import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import TableHead from '../../Components/TableHead/TableHead';
import { BiPlus } from 'react-icons/bi';
import { CategoryListAction, ContestDeleteAction, ContestListAction, ContestStatusUpdateAction, GameModeListAction } from '../../../features/CommonSlice';
import NoDataFound from "./../../Components/NoDataFound/NoDataFound";
import { Link, useNavigate } from 'react-router-dom';
import CommonModal from '../../Components/Modal/CommonModal';
import { BsEyeFill, BsTrash3 } from 'react-icons/bs';
import moment from 'moment';
import DeleteModal from '../../Components/Modal/DeleteModal';
import { IoPricetagOutline } from 'react-icons/io5';

const ContestList = () => {
    const navigate = useNavigate();
    const [statusUpdate, setStatusUpdate] = useState(1)
    const [listDataArr, setListDataArr] = useState({});
    const [categoryListData, setCategoryListData] = useState([]);
    const [selectedCategoryID, setSelectedCategoryID] = useState("");
    const [gameModeListData, setGameModeListData] = useState([]);
    const [selectedGameModeID, setSelectedGameModeID] = useState("")

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteContestID, setDeleteContestID] = useState("")


    useEffect(() => {
        ContestListAction({ category_id: selectedCategoryID, game_mode_id: selectedGameModeID, listing: "main" }, (response) => {
            if (response?.status === true) {
                setListDataArr(response)
            }
        })();
    }, [ContestListAction, statusUpdate, selectedCategoryID, selectedGameModeID])

    useEffect(() => {
        CategoryListAction("", (response) => {
            if (response?.status === true) {
                setCategoryListData(response?.data)
            }
        })();
    }, [CategoryListAction])

    useEffect(() => {
        GameModeListAction((response) => {
            if (response?.status === true) {
                setGameModeListData(response?.data)
            }
        })();
    }, [GameModeListAction])

    const handleStatusUpdate = (id) => {
        ContestStatusUpdateAction({ id }, (response) => {
            if (response?.status === true) {
                setStatusUpdate(statusUpdate + 1)
            }
        })();
    }

    const handleDeleteContest = () => {
        ContestDeleteAction({ id: deleteContestID }, (response) => {
            if (response?.status === true) {
                setStatusUpdate(statusUpdate + 1)
                setDeleteContestID("")
                setDeleteModalOpen(false)
            }
        })();
    }

    let tableHeader = [
        { label: "#", key: "", sort: false },
        { label: "Game Mode", key: "", sort: false },
        { label: "Category name", key: "", sort: false },
        { label: "Contest Mode", key: "", sort: false },
        { label: "Winning amount INR", key: "", sort: false },
        { label: "Contest size", key: "", sort: false },
        { label: "Contest type", key: "", sort: false },
        { label: "Entry fee INR", key: "", sort: false },
        { label: "Bonus coin", key: "", sort: false },
        { label: "Status", key: "", sort: false },
        { label: "Created", key: "", sort: false },
        { label: "Action", key: "", sort: false }
    ]

    return (
        <>
            <section className='mainSection'>
                <div className='title_breadcrumb_section'>
                    <div className='title_page'>Contest List</div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Contest list</li>
                        </ol>
                    </nav>
                </div>

                <div className='common_section_main'>

                <div className='searchAddRowTable'>
                    <button className='themeBtn_2' onClick={() => navigate("/admin/contest-manager/add")}>
                        <BiPlus /> Add New
                    </button>
                </div>

                <div className='tab_main_section_list'>
                    <div className='searchAddRowTable'>
                        <div className='inputGroupSearch search_input'>
                            <select className='form-control select_white' name='category_id' onChange={(e) => setSelectedGameModeID(e.target.value)}>
                                <option value=""> Select Game Mode Name </option>
                                {gameModeListData?.filter(data => data.status)?.map((value, index) => (
                                    <option value={value?.id} key={index}>{value?.mode_name}</option>
                                ))}
                            </select>
                        </div>
                        <div className='inputGroupSearch search_input'>
                            <select className='form-control select_white' name='category_id' onChange={(e) => setSelectedCategoryID(e.target.value)}>
                                <option value=""> Select Category Name </option>
                                {categoryListData?.filter(data => data.status)?.map((value, index) => (
                                    <option value={value?.id} key={index}>{value?.category_name}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <Table responsive className="themeTable">
                        <TableHead data={tableHeader} />
                        <tbody>
                            {listDataArr?.data?.length > 0 ?
                                listDataArr?.data?.map((value, index) => (
                                    <tr key={index}>
                                        <td> {(index + 1)}. </td>
                                        <td> {value?.gamemode?.mode_name ? value?.gamemode?.mode_name : "-"} </td>
                                        <td> {value?.category?.category_name ? value?.category?.category_name : "-"} </td>
                                        <td> {value?.contest_mode == 1 ? "Cash Contest" : "Coin Contest"} </td>
                                        <td> {value?.winning_amount ? value?.winning_amount : "-"} </td>
                                        <td> {value?.contest_size ? value?.contest_size : "-"} </td>
                                        <td> {value?.contest_type ? value?.contest_type : "-"} </td>
                                        <td> {value?.entry_fee ? value?.entry_fee : "-"} </td>
                                        <td> {value?.bonus_coin ? value?.bonus_coin : "-"} </td>
                                        <td>
                                            <button
                                                type='button'
                                                className={`table_btn ${value?.status == 1 ? "active" : "inactive"}`}
                                                onClick={() => handleStatusUpdate(value.id)}
                                            >
                                                {value?.status == 1 ? "Active" : "Inactive"}
                                            </button>
                                        </td>
                                        <td> {value?.createdAt ? moment(value?.createdAt).format("DD-MM-YYYY") : "-"} </td>
                                        <td>
                                            <div className="btnTableGroup">
                                                {value?.price_breakup == 0 && value?.contest_type == "Paid" ?
                                                    <button
                                                        type="button"
                                                        onClick={() => navigate(`/admin/contest-manager/contest-breakup/${value?.id}`, { state: value })}
                                                    >
                                                        <IoPricetagOutline />
                                                    </button>
                                                    :
                                                    null
                                                }
                                                <button
                                                    type="button"
                                                    onClick={() => navigate(`/admin/contest-manager/detail/${value?.id}`)}
                                                >
                                                    <BsEyeFill />
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setDeleteModalOpen(true)
                                                        setDeleteContestID(value.id)
                                                    }}
                                                > <BsTrash3 /></button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                :
                                <>
                                    <tr>
                                        <td colSpan={7} className='not_found_data_td'>
                                            <NoDataFound />
                                        </td>
                                    </tr>
                                </>
                            }
                        </tbody>
                    </Table>
                </div>
                </div>

            </section>

            {/* <CommonModal
                show={viewEditCategoryModalOpen}
                onHide={() => {
                    setViewEditCategoryModalOpen(false)
                    setSelectedCategoryData("")
                }}
                title={`${selectedCategoryData?.mode ? selectedCategoryData?.mode : "Create"} Category`}
                body={
                    <CategoryAddEdit hide={setViewEditCategoryModalOpen} data={selectedCategoryData} setStatusUpdate={setStatusUpdate} />
                }
            />

            <CommonModal
                show={categoryDetailModalOpen}
                onHide={() => {
                    setCategoryDetailModalOpen(false)
                    setSelectedCategoryData("")
                }}
                title={`View Category`}
                body={
                    <CategoryView hide={setCategoryDetailModalOpen} data={selectedCategoryData} />
                }
            /> */}

            <CommonModal
                show={deleteModalOpen}
                onHide={() => {
                    setDeleteModalOpen(false)
                    setDeleteContestID("")
                }}
                modalClass="logout_modal"
                body={
                    <DeleteModal hide={setDeleteModalOpen} onSubmit={handleDeleteContest}
                        title="Are you delete?"
                        text="Are you sure you want to delete contest?"
                    />
                }
            />
        </>
    )
}

export default ContestList