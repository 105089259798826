import React, { useState } from 'react';
import "../../Components/Modal/CommonModal.scss";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from "yup";
import { FieldText } from '../../Components/InputText/InputText';
import { Button } from '../../Components/Button/Button';
import { TeamUpdateAction } from '../../../features/CommonSlice';
import TextErrorMsg from '../../Components/InputText/TextErrorMsg';
import { ToastOverError } from '../../../common/Toast/ToastOver';
import { server } from '../../../common/Apis/axiosBaseURL';
import { isValidHttpUrl } from '../../../utils/Function';

const TeamEdit = ({ data, hide, setStatusUpdate }) => {
    const [loader, setLoader] = useState(false)
    const SUPPORTED_FILE_FORMAT = ["image/jpeg", "image/jpg", "image/png"]
    return (
        <div className='delete_modal_main'>
            <div className=''>
                <Formik
                    initialValues={{
                        id: data?.team_id ? data?.team_id : '',
                        team_short_name: data?.team_short_name ? data?.team_short_name : '',
                        logo_url: data?.logo_url ? data?.logo_url : '',
                    }}
                    validationSchema={Yup.object().shape({
                        team_short_name: Yup.string().required('Short name is required!'),
                        logo_url: Yup.mixed().nullable().notRequired()
                            .test(
                                "FILE_FORMAT",
                                "Unsupported file format",
                                (value) => !value || (value && (typeof value === "object" ? SUPPORTED_FILE_FORMAT.includes(value?.type) : true))
                            ),
                    })}
                    onSubmit={(value) => {
                        setLoader(true)
                        const formData = new FormData();
                        for (const key in value) {
                            formData.append(key, value[key]);
                        }
                        TeamUpdateAction(formData, (response) => {
                            if (response?.status) {
                                hide(false)
                                setStatusUpdate(prevCount => prevCount + 1);
                            }
                            setLoader(false)
                        })();
                    }}
                >
                    {(formik) => {
                        return (
                            <Form autoComplete='off'>
                                <FieldText
                                    name="team_short_name"
                                    type="text"
                                    placeholder="Short Name"
                                    showlabel={true}
                                    label="Short Name"
                                    value={formik.values.team_short_name}
                                />


                                <div className="col-12 col-md-6 mt-4">
                                    <label className='commmon_label'> Logo Image </label>
                                    <div className='banner_img_box'>
                                        <Field name="logo_url">
                                            {({ form, field }) => {
                                                return (
                                                    <>
                                                        <input name={field.name} type="file"
                                                            onChange={(e) => {
                                                                if (SUPPORTED_FILE_FORMAT.includes(e.target.files[0]?.type)) {
                                                                    form.setFieldValue(field.name, e.target.files[0])
                                                                } else {
                                                                    form.setFieldValue(field.name, "")
                                                                    e.target.value = null
                                                                    ToastOverError("Unsupported file format")
                                                                }
                                                            }} />
                                                    </>
                                                )
                                            }}
                                        </Field>
                                        <ErrorMessage name="logo_url" component={TextErrorMsg} />
                                    </div>
                                </div>
                                <div className='edit_img_modal'>
                                    {
                                        (typeof formik?.values?.logo_url == "string") ?
                                            isValidHttpUrl(formik?.values?.logo_url) ?
                                                <img src={formik?.values?.logo_url} className="img-fluid" alt="Logo" />
                                                :
                                                <img src={`${server.baseURL}/${formik?.values?.logo_url}`} className="img-fluid" alt="Logo" />
                                            :
                                            ""
                                    }
                                </div>

                                <div className='tow_button_card'>
                                    <button className='themeBtn w-auto m-0' type='button' onClick={() => hide(false)}> No</button>
                                    <Button
                                        className="themeBtn w-auto"
                                        text='Submit'
                                        type="submit"
                                        loader={loader}
                                        disabled={!(formik.isValid && formik.dirty) || loader}
                                    />
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default TeamEdit;