import React from 'react';
import "../Style.scss";
import { Link } from 'react-router-dom';

const TDS = () => {


    return (
        <>
            <section className='mainSection'>
                <div className='title_breadcrumb_section'>
                    <div className='title_page'> TDS List</div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">TDS list</li>
                        </ol>
                    </nav>
                </div>
            </section>

        </>
    )
}

export default TDS;
