import { Table } from 'react-bootstrap';
import TableHead from '../../Components/TableHead/TableHead';
import { BiReset, BiSearch } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { WalletUserListing } from '../../../features/CommonSlice';
import NoDataFound from "./../../Components/NoDataFound/NoDataFound";
import { Pagination } from "./../../Components/Hooks/Pagination"
import { HasConsecutiveSpaces, RemoveEmptyObjKey, debounceSearch } from '../../../utils/Function';
import { Link } from 'react-router-dom';
import CommonModal from '../../Components/Modal/CommonModal';
import { FaRegEdit } from "react-icons/fa";
import WalletManagerEdit from './Edit';

const WalletManager = () => {
    const [statusUpdate, setStatusUpdate] = useState(1)
    const [listDataArr, setListDataArr] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [perPageCount, setPerPageCount] = useState(30);
    const [editSeriesModalOpen, setEditSeriesModalOpen] = useState(false);
    const [selectedSeriesData, setSelectedSeriesData] = useState("");

    const [searchName, setSearchName] = useState("");
    const [searchEmail, setSearchEmail] = useState("");
    const [searchPhone, setSearchPhone] = useState("");

    useEffect(() => {
        let payload = {
            page: currentPage,
            pageSize: perPageCount,
            name: searchName,
            email: searchEmail,
            phone: searchPhone,
        }
        WalletUserListing(RemoveEmptyObjKey(payload), (response) => {
            if (response?.status === true) {
                setListDataArr(response)
            }
        })();
    }, [WalletUserListing, perPageCount, currentPage, searchName, searchEmail, searchPhone, statusUpdate])

    const handleFilters = debounceSearch((e) => {
        console.log("e.target.value", e.target.value)
        if (HasConsecutiveSpaces(e.target.value)) return <></>;
        if (e.target.name == "name") setSearchName(e.target.value)
        if (e.target.name == "email") setSearchEmail(e.target.value)
        if (e.target.name == "phone") setSearchPhone(e.target.value)
    }, 300);

    let tableHeader = [
        { label: "#", key: "", sort: false },
        { label: "User Name", key: "", sort: false },
        { label: "User Email", key: "", sort: false },
        { label: "User Phone", key: "", sort: false },
        { label: "Deposit Amount", key: "", sort: false },
        { label: "Coins Amount", key: "", sort: false },
        { label: "Bonus Amount", key: "", sort: false },
        { label: "Winning Amount", key: "", sort: false },
    ]

    return (
        <>
            <section className='mainSection'>

                <div className='title_breadcrumb_section'>
                    <div className='title_page'>Wallet Manager</div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Teams list</li>
                        </ol>
                    </nav>
                </div>


                <div className='common_section_main'>
                    <form className='searchAddRowTable responsive_common'
                        onSubmit={(e) => {
                            e.preventDefault()
                            setStatusUpdate(statusUpdate + 1)
                        }}
                    >
                        <div className='inputGroupSearch'>
                            <input
                                placeholder="Enter Name"
                                type="text"
                                name="name"
                                className="form-control"
                                onChange={handleFilters}
                            />
                        </div>

                        <div className='inputGroupSearch'>
                            <input
                                placeholder="Email Address"
                                type="email"
                                name="email"
                                className="form-control"
                                onChange={handleFilters}
                            />
                        </div>

                        <div className='inputGroupSearch'>
                            <input
                                placeholder="Phone Number"
                                type="number"
                                name="phone"
                                className="form-control"
                                onChange={handleFilters}
                            />
                        </div>

                        <div className='flexBtnGroup ms-auto list_top_filter'>
                            <button className='themeBtn_2' type='submit'>
                                <BiSearch />
                                Search
                            </button>

                            {/* <button className='themeBtn_2' type='button'
                                onClick={() => {
                                    setSearchName("")
                                    setSearchEmail("")
                                    setSearchPhone("")
                                    setStatusUpdate(statusUpdate + 1)
                                }}
                            >
                                <BiReset />
                                Reset
                            </button> */}
                        </div>

                    </form>

                    <Table responsive className="themeTable">
                        <TableHead data={tableHeader} />
                        <tbody>
                            {listDataArr?.data?.list?.length > 0 ?
                                listDataArr?.data?.list?.map((value, index) => (
                                    <tr key={index}>
                                        <td> {(currentPage - 1) * Number(listDataArr?.data?.perpage_count) + (index + 1)}. </td>
                                        <td>
                                            {value?.first_name ? <>
                                                {`${value?.first_name} ${value?.last_name}`}
                                            </> : "-"}
                                        </td>
                                        <td> {value?.email ? value?.email : "-"} </td>
                                        <td> {value?.phone ? value?.phone : "-"} </td>
                                        <td>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                {value?.cash_balance ? value?.cash_balance : 0}
                                                <button type="button" className='edit_btn' onClick={() => {
                                                    setEditSeriesModalOpen(true)
                                                    setSelectedSeriesData(value)
                                                }}>
                                                    <FaRegEdit />
                                                </button>
                                            </div>
                                        </td>
                                        <td> {value?.coin_balance ? value?.coin_balance : 0} </td>
                                        <td> {value?.bonus_amount ? value?.bonus_amount : 0} </td>
                                        <td> {value?.winning_balance ? value?.winning_balance : 0} </td>
                                    </tr>
                                ))
                                :
                                <>
                                    <tr>
                                        <td colSpan={8} className='not_found_data_td'>
                                            <NoDataFound />
                                        </td>
                                    </tr>
                                </>
                            }
                        </tbody>
                    </Table>

                    {listDataArr?.data?.list?.length > 0 ?
                        <div className='pagination_entries_section'>
                            <div className='entries_text'>
                                <select className='common_input_filed' onChange={(e) => setPerPageCount(e.target.value)}>
                                    <option value={30}>30</option>
                                    <option value={60}>60</option>
                                    <option value={80}>80</option>
                                    <option value={100}>100</option>
                                </select>
                            </div>

                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={listDataArr?.data?.totalcount}
                                pageSize={listDataArr?.data?.perpage_count}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                        </div>
                        :
                        null
                    }
                </div>
            </section>

            <CommonModal
                show={editSeriesModalOpen}
                onHide={() => setEditSeriesModalOpen(false)}
                title={`Update Wallet`}
                body={
                    <WalletManagerEdit hide={setEditSeriesModalOpen} data={selectedSeriesData} setStatusUpdate={setStatusUpdate} />
                }
            />
        </>
    )
}

export default WalletManager