import React, { useEffect, useState } from "react";
import { ConstactSupportTicketDetailAction } from "../../../features/CommonSlice";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { Table } from 'react-bootstrap';
import NoDataFound from "../../Components/NoDataFound/NoDataFound";

const ContactDetails = () => {
    const { id } = useParams()
    const [loader, setLoader] = useState(false);
    const [ticketDetail, setTicketDetail] = useState({});

    useEffect(() => {
        setLoader(true)
        ConstactSupportTicketDetailAction(id , (response) => {
            if (response?.status === true) {
                setTicketDetail(response)
            }
            setLoader(false)
        })();
    }, [ConstactSupportTicketDetailAction])
    return (
        !loader ?
            <div className="addLeagueBlock">
                <div className='title_breadcrumb_section'>
                    <div className='title_page'>Contact Support Detail</div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/admin/contact-support"> contact support</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">contact support detail</li>
                        </ol>
                    </nav>
                </div>

                <div className="common_section_main">

                <div className="detailFormBlock">
                    <div className="view_user_list_details">
                        <div className="row g-md-5 g-4">
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>First Name :</label>
                                    <span> {ticketDetail?.data?.first_name ? ticketDetail?.data?.first_name : "-"} </span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Last Name :</label>
                                    <span> {ticketDetail?.data?.last_name ? ticketDetail?.data?.last_name : "-"}</span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Email :</label>
                                    <span> {ticketDetail?.data?.email ?
                                        ticketDetail?.data?.email
                                        : "-"} </span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Phone Number :</label>
                                    <span> {ticketDetail?.data?.phone ? ticketDetail?.data?.phone : "-"} </span>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="detailBlock">
                                    <label>Message:</label>
                                    <span> {ticketDetail?.data?.message ? ticketDetail?.data?.message : ""} </span>
                                </div>
                            </div>

                            <div className="col-12">
                                <Link to="/admin/contact-support" className="themeBtn view_page_btn"> Back </Link>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            :
            <div>Loading...</div>
    )
}

export default ContactDetails