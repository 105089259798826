
import { authHeader } from "../common/Apis/authHeader";
import axiosBaseURL from "../common/Apis/axiosBaseURL";
import HandleError from "../common/Apis/HandleError";
import { ToastOverError, ToastOverSuccess } from "../common/Toast/ToastOver";


///// Dashboard Management Callback Action ///
export const DashboardAction = (callBack) => async () => {
    try {
        const data = await axiosBaseURL.get('/admin/dashboard', {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};

///// User Management Callback Action ///
export const UserListAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/user/list', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const UserStatusUpdateAction = (value, callback) => async () => {
    try {
        const data = await axiosBaseURL.post(`admin/user/status-update`, value, {
            headers: authHeader(),
        });
        callback(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callback(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const UserDetailsAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/user/view', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const UserUpdateDetailsAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/user/update', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};

///// Teams Management Callback Action ///
export const TeamListAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/team/list', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const TeamUpdateAction = (value, callback) => async () => {
    try {
        const data = await axiosBaseURL.post(`admin/team/update`, value, {
            headers: authHeader(null, true),
        });
        callback(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callback(error?.response?.data)
        HandleError(error?.response?.data)
    }
};



///// Series Management Callback Action ///
export const SeriesListAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/series/list', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const SeriesStatusUpdateAction = (value, callback) => async () => {
    try {
        const data = await axiosBaseURL.post(`admin/series/status-update`, value, {
            headers: authHeader(),
        });
        callback(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callback(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const SeriesUpdateAction = (value, callback) => async () => {
    try {
        const data = await axiosBaseURL.post(`admin/series/update`, value, {
            headers: authHeader(),
        });
        callback(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callback(error?.response?.data)
        HandleError(error?.response?.data)
    }
};



///// CMS Management Callback Action ///
export const CMSListAction = (callBack) => async () => {
    try {
        const data = await axiosBaseURL.get('/admin/cms/list', {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const CMSDetailAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/cms/view', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const CMSUpdateDetailsAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/cms/update', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};

///// FAQs Management Callback Action ///
export const FAQListAction = (callBack) => async () => {
    try {
        const data = await axiosBaseURL.get('/admin/cms/faq/list', {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const FAQDeleteAction = (value, callback) => async () => {
    try {
        const data = await axiosBaseURL.post(`admin/cms/faq/delete`, value, {
            headers: authHeader(),
        });
        callback(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callback(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const FAQCreateAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/cms/faq/add', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const FAQUpdateAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/cms/faq/update', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};


///// Banner Management Callback Action ///
export const BannerCreateDetailsAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/banner/add', value, {
            headers: authHeader(null, true),
        });
        callBack(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const BannerListAction = (callBack) => async () => {
    try {
        const data = await axiosBaseURL.get('/admin/banner/list', {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const BannerUpdateDetailsAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/banner/update', value, {
            headers: authHeader(null, true),
        });
        callBack(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const BannerStatusUpdateAction = (value, callback) => async () => {
    try {
        const data = await axiosBaseURL.post(`admin/banner/status-update`, value, {
            headers: authHeader(),
        });
        callback(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callback(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const BannerDeleteAction = (value, callback) => async () => {
    try {
        const data = await axiosBaseURL.post(`admin/banner/delete`, value, {
            headers: authHeader(),
        });
        callback(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callback(error?.response?.data)
        HandleError(error?.response?.data)
    }
};




///// Player Management Callback Action ///
export const PlayerListAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/player/list', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const PlayerUpdateAction = (value, callback) => async () => {
    try {
        const data = await axiosBaseURL.post(`admin/player/update`, value, {
            headers: authHeader(null, true),
        });
        callback(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callback(error?.response?.data)
        HandleError(error?.response?.data)
    }
};

///// Matches Management Callback Action ///
export const MatchesListAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/match/list', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const MatchesStatusUpdateAction = (value, callback) => async () => {
    try {
        const data = await axiosBaseURL.post(`admin/match/status-update`, value, {
            headers: authHeader(),
        });
        callback(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callback(error?.response?.data)
        HandleError(error?.response?.data)
    }
};



///// Category Management Callback Action ///
export const CategoryListAction = (search, callBack) => async () => {
    try {
        const data = await axiosBaseURL.get(`/admin/category/list?searchText=${search}`, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const CategoryCreateAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/category/add', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const CategoryUpdateAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/category/update', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const CategoryStatusUpdateAction = (value, callback) => async () => {
    try {
        const data = await axiosBaseURL.post(`admin/category/status-update`, value, {
            headers: authHeader(),
        });
        callback(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callback(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const CategoryDeleteAction = (value, callback) => async () => {
    try {
        const data = await axiosBaseURL.post(`admin/category/delete`, value, {
            headers: authHeader(),
        });
        callback(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callback(error?.response?.data)
        HandleError(error?.response?.data)
    }
};



///// Game Mode Management Callback Action ///
export const GameModeListAction = (callBack) => async () => {
    try {
        const data = await axiosBaseURL.get(`/admin/gamemode/list`, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const GameModeStatusUpdateAction = (value, callback) => async () => {
    try {
        const data = await axiosBaseURL.post(`admin/gamemode/status-update`, value, {
            headers: authHeader(),
        });
        callback(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callback(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const GameModeCreateAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/gamemode/add', value, {
            headers: authHeader(null, true),
        });
        callBack(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const GameModeUpdateAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/gamemode/update', value, {
            headers: authHeader(null, true),
        });
        callBack(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};


///// Subadmin Management Callback Action ///
export const SubadminListAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post(`/admin/subadmin/list`, value, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const SubadminStatusUpdateAction = (value, callback) => async () => {
    try {
        const data = await axiosBaseURL.post(`admin/subadmin/status-update`, value, {
            headers: authHeader(),
        });
        callback(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callback(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const SubadminDeleteAction = (value, callback) => async () => {
    try {
        const data = await axiosBaseURL.post(`admin/subadmin/delete`, value, {
            headers: authHeader(),
        });
        callback(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callback(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const SubadminCreateAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/subadmin/create', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const SubadminUpdateAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/subadmin/update', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const SubadminDetailAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/subadmin/view', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};



///// Setting Management Callback Action ///
export const SettinsListAction = (callBack) => async () => {
    try {
        const data = await axiosBaseURL.get(`/admin/setting/list`, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const SettingsUpdateAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/setting/update', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};



///// Contest Management Callback Action ///
export const ContestListAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post(`/admin/contest/list`, value, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const ContestDeleteAction = (value, callback) => async () => {
    try {
        const data = await axiosBaseURL.post(`admin/contest/delete`, value, {
            headers: authHeader(),
        });
        callback(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callback(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const ContestStatusUpdateAction = (value, callback) => async () => {
    try {
        const data = await axiosBaseURL.post(`admin/contest/status-update`, value, {
            headers: authHeader(),
        });
        callback(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callback(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const ContestCreateAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/contest/create', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const ContestDetailsAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/contest/detail', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const ContestPriceBreakUpAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/contest/price-break-up', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};



///// Schedule/Upcoming Management Action ///
export const ScheduleListAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/schedule-contest/list', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};

export const ScheduleAssignmentAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post('/admin/schedule-contest/assignment', value, {
            headers: authHeader(),
        });
        callBack(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const ScheduleAssignedListAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post(`/admin/schedule-contest/assigned/list`, value, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};

export const ScheduleMatchJoinedTeamListing = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post(`/admin/schedule-contest/match/joined-team-list`, value, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};

export const ContactSupportListAction = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post(`/admin/contact_support_list`, value, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};










///// Wallet Management Callback Action ///
export const WalletUserListing = (value, callBack) => async () => {
    try {
        const data = await axiosBaseURL.post(`/admin/wallet/user/list`, value, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const WalletBalanceUpdateAction = (value, callback) => async () => {
    try {
        const data = await axiosBaseURL.post(`admin/wallet/update`, value, {
            headers: authHeader(),
        });
        callback(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callback(error?.response?.data)
        HandleError(error?.response?.data)
    }
};



///// Points Management Callback Action ///
export const PointsListing = (id, callBack) => async () => {
    try {
        const data = await axiosBaseURL.get(`/admin/points/list?sports_id=${id}`, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const PointsUpdateAction = (value, callback) => async () => {
    try {
        const data = await axiosBaseURL.post(`admin/points/cricket/update`, value, {
            headers: authHeader(),
        });
        callback(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callback(error?.response?.data)
        HandleError(error?.response?.data)
    }
};



///// State Management Callback Action ///
export const StateListAction = (callBack) => async () => {
    try {
        const data = await axiosBaseURL.get('/admin/state/list', {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};
export const StateStatusUpdateAction = (value, callback) => async () => {
    try {
        const data = await axiosBaseURL.post(`admin/state/status-update`, value, {
            headers: authHeader(),
        });
        callback(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callback(error?.response?.data)
        HandleError(error?.response?.data)
    }
};

export const ContactUsSubmit = (value, callBack) => async () => {
    console.log("value",value)
    try {
        const data = await axiosBaseURL.post('/user/contact/query-submit', value, {
            //headers: authHeader(),
        });
        callBack(data?.data)
        ToastOverSuccess(data?.data?.message)
    } catch (error) {
        console.log("error",error)
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};

export const ConstactSupportTicketDetailAction = (id, callBack) => async () => {
    try {
        const data = await axiosBaseURL.get(`/admin/contact_support_ticket_view/${id}`, {
            headers: authHeader(),
        });
        callBack(data?.data)
    } catch (error) {
        ToastOverError(error?.response?.data?.message)
        callBack(error?.response?.data)
        HandleError(error?.response?.data)
    }
};