import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Table } from "react-bootstrap"
import TableHead from "../../Components/TableHead/TableHead";
import { ScheduleMatchJoinedTeamListing } from "../../../features/CommonSlice";
import NoDataFound from "../../Components/NoDataFound/NoDataFound";

const ContestAssignedDetails = () => {
    const { id, match_id } = useParams();
    const { state } = useLocation();
    const [listDataArr, setListDataArr] = useState([]);
    let tableHeader = [
        { label: "#", key: "", sort: false },
        { label: "Full Name", key: "", sort: false },
        { label: "Team Name", key: "", sort: false },
        { label: "Phone No", key: "", sort: false },
        { label: "Points", key: "", sort: false },
        { label: "Rank", key: "", sort: false },
        { label: "Amount", key: "", sort: false },
        // { label: "Action", key: "", sort: false }
    ]

    useEffect(() => {
        ScheduleMatchJoinedTeamListing({ match_id, contest_id: id }, (response) => {
            if (response?.status === true) {
                setListDataArr(response?.data)
            }
        })();
    }, [ScheduleMatchJoinedTeamListing])

    return (
        <div className="addLeagueBlock">
            <div className='title_breadcrumb_section'>
                <div className='title_page'> Contest Details </div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/upcoming-contest/list"> Upcoming Contest </Link></li>
                        <li className="breadcrumb-item active" aria-current="page"> Added Contest Detail </li>
                    </ol>
                </nav>
            </div>
            <div className="common_section_main">
            <div className="detailFormBlock">
                <div className="view_user_list_details">
                    <div className="row g-md-5 g-4">
                        <div className="col-12 col-xl-6">
                            <div className="detailBlock">
                                <label>Category :</label>
                                <span> {state.category?.category_name ? state.category?.category_name : "-"}  </span>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="detailBlock">
                                <label>Contest Mode :</label>
                                <span> {state?.gamemode?.mode_name ? state?.gamemode?.mode_name : "-"} </span>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="detailBlock">
                                <label>Contest Mode :</label>
                                <span> {state?.contest_mode ?
                                    state?.contest_mode == 1 ? "Cash Contest" : "Coin Contest"
                                    : "-"} </span>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="detailBlock">
                                <label>Contest type :</label>
                                <span> {state?.contest_type ? state?.contest_type : "-"} </span>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="detailBlock">
                                <label>Max Team Size :</label>
                                <span> {state?.contest_size ? state?.contest_size : "-"} </span>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="detailBlock">
                                <label>Teams Joined :</label>
                                <span>  {listDataArr?.length} </span>
                            </div>
                        </div>

                        <div className="col-12 col-xl-6">
                            <div className="detailBlock">
                                <label>Entries Left :</label>
                                <span> {state?.contest_size ? state?.contest_size - listDataArr?.length : "-"} </span>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="detailBlock">
                                <label> Winning amount :</label>
                                <span> {state?.winning_amount ? state?.winning_amount : "-"} </span>
                            </div>
                        </div>

                        <div className="col-12 col-xl-6">
                            <div className="detailBlock">
                                <label>Entry Fee :</label>
                                <span> {state?.entry_fee ? state?.entry_fee : "-"} </span>
                            </div>
                        </div>

                        {/* <div className="col-12 col-xl-6">
                            <div className="detailBlock">
                                <label>Winner Team Size :</label>
                                <span> 0000 </span>
                            </div>
                        </div> */}

                        <div className="col-12 col-xl-6">
                            <div className="detailBlock">
                                <label>Starts At:</label>
                                <span> {state?.updatedAt ? moment(state?.updatedAt).format("DD-MM-YYYY") : "-"} </span>
                            </div>
                        </div>


                        <div className="col-12 col-xl-6">
                            <div className="detailBlock">
                                <label>Contest Invite Code :</label>
                                <span> {state?.match_contest?.invite_code ? state?.match_contest?.invite_code : "-"} </span>
                            </div>
                        </div>

                        <div className="col-12">
                            <Link to="/admin/upcoming-contest/list" className="themeBtn view_page_btn">Close</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className='title_breadcrumb_section'>
                <div className='title_page'> Joined Team </div>
            </div>
            <Table responsive className="themeTable">
                <TableHead data={tableHeader} />
                <tbody>
                    {listDataArr?.length > 0 ?
                        listDataArr?.map((value, index) => (
                            <tr key={index}>
                                <td> {index + 1} </td>
                                <td> {value?.user?.first_name ? `${value?.user?.first_name} ${value?.user?.last_name}` : "-"} </td>
                                <td> {value?.user?.team_name ? value?.user?.team_name : "-"} </td>
                                <td> {value?.user?.phone ? value?.user?.phone : "-"} </td>
                                <td> {value?.player_team?.points ? value?.player_team?.points : "-"} </td>
                                <td> {value?.rank ? value?.rank : "-"} </td>
                                <td> {value?.winning_amount ? value?.winning_amount : "-"} </td>
                                {/* <td>
                                    <div className="btnTableGroup">
                                        <button
                                            type="button"
                                            onClick={() => navigate(`/admin/upcoming-contest/contest/assigned/detail/${id}/${value?.id}`, { state: value })}
                                        >
                                            <BsEyeFill />
                                        </button>
                                    </div>
                                </td> */}
                            </tr>
                        ))
                        :
                        <>
                            <tr>
                                <td colSpan={7} className='not_found_data_td'>
                                    <NoDataFound />
                                </td>
                            </tr>
                        </>
                    }
                </tbody>
            </Table>

            </div>
        </div>
    )
}

export default ContestAssignedDetails