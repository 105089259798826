
import React, { useEffect, useState } from 'react';
import { FAQsListingAction } from '../../../features/webCommonSlice';
import Accordion from 'react-bootstrap/Accordion';
import "./FAQ.scss";
import NoDataFound from '../../Components/NoDataFound/NoDataFound';

const FAQs = () => {
    const [loader, setLoader] = useState(false)
    const [faqListing, setFAQsListing] = useState([]);

    useEffect(() => {
        setLoader(true)
        FAQsListingAction((response) => {
            if (response?.status === true) {
                setFAQsListing(response?.data)
            }
            setLoader(false)
        })();
    }, [FAQsListingAction])

    return (
        <>
            <div className='cms_landing_page'>
                <div className='banner'> Frequently Asked Questions </div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            {!loader ?
                                faqListing?.length > 0 ?
                                    <Accordion defaultActiveKey={0}>
                                        {faqListing?.map((value, index) => (
                                            <Accordion.Item key={index} eventKey={index} alt={index}>
                                                <Accordion.Header> {value?.question} </Accordion.Header>
                                                <Accordion.Body> {value?.answer} </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                    :
                                    <NoDataFound />
                                :
                                <div className='text-dark'> loading... </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FAQs