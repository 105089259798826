import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import TableHead from '../../Components/TableHead/TableHead';
import { BiPlus } from 'react-icons/bi';
import { ContactSupportListAction } from '../../../features/CommonSlice';
import NoDataFound from "../../Components/NoDataFound/NoDataFound";
import { Link, useNavigate } from 'react-router-dom';
import CommonModal from '../../Components/Modal/CommonModal';
import { BsEyeFill, BsTrash3 } from 'react-icons/bs';
import moment from 'moment';
import DeleteModal from '../../Components/Modal/DeleteModal';
import { IoPricetagOutline } from 'react-icons/io5';
import { Pagination } from "./../../Components/Hooks/Pagination"
const ContactSupport = () => {
    const navigate = useNavigate();
    const [ContactListData, setContactListData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [perPageCount, setPerPageCount] = useState(15);


    useEffect(() => {
        let payload = {
            // name: searchName,
            // email: searchEmail,
            // phone: searchPhone,
            pageSize: perPageCount,
            page: currentPage,
        }
        ContactSupportListAction(payload, (response) => {
            if (response?.status === true) {
                setContactListData(response)
            }
        })();
    }, [ContactSupportListAction,perPageCount, currentPage])


    // const handleDeleteContest = () => {
    //     ContestDeleteAction({ id: deleteContestID }, (response) => {
    //         if (response?.status === true) {
    //             setStatusUpdate(statusUpdate + 1)
    //             setDeleteContestID("")
    //             setDeleteModalOpen(false)
    //         }
    //     })();
    // }

    let tableHeader = [
        { label: "#", key: "", sort: false },
        { label: "Ticket Id", key: "", sort: false },
        { label: "First Name", key: "", sort: false },
        { label: "Last Name", key: "", sort: false },
        { label: "Message", key: "", sort: false },
        { label: "Email", key: "", sort: false },
        { label: "Phone Number", key: "", sort: false },
        { label: "Created", key: "", sort: false },
        { label: "Action", key: "", sort: false },
    ]

    return (
        <>
            <section className='mainSection'>
                <div className='title_breadcrumb_section'>
                    <div className='title_page'>Contact Support</div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">contact support</li>
                        </ol>
                    </nav>
                </div>

                <div className='common_section_main'>

                <div className='tab_main_section_list'>
                    <Table responsive className="themeTable">
                        <TableHead data={tableHeader} />
                        <tbody>
                            {ContactListData?.data?.list?.length > 0 ?
                                ContactListData?.data?.list?.map((value, index) => (
                                    <tr key={index}>
                                        <td> {(currentPage - 1) * Number(ContactListData?.data?.perpage_count) + (index + 1)}. </td>
                                        <td> {value?.ticket_id ? value?.ticket_id : "-"} </td>
                                        <td> {value?.first_name ? value?.first_name : "-"} </td>
                                        <td> {value?.last_name ? value?.last_name : "-"} </td>
                                        <td> {value?.message ? value?.message : "-"} </td>
                                        <td> {value?.email ? value?.email : "-"} </td>
                                        <td> {value?.phone ? value?.phone : "-"} </td>
                                        <td> {value?.createdAt ? moment(value?.createdAt).format("YYYY-MM-DD") : "-"} </td>
                                        <td>
                                            <div className="btnTableGroup">
                                                <button
                                                    type="button"
                                                    onClick={() => navigate(`/admin/contact-support/contact-details/${value?.id}`)}
                                                >
                                                    <BsEyeFill />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                :
                                <>
                                    <tr>
                                        <td colSpan={7} className='not_found_data_td'>
                                            <NoDataFound />
                                        </td>
                                    </tr>
                                </>
                            }
                        </tbody>
                    </Table>
                    {ContactListData?.data?.list?.length > 0 ?
                    <div className='pagination_entries_section'>
                        <div className='entries_text'>
                            <select className='common_input_filed' onChange={(e) => setPerPageCount(e.target.value)}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>

                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={ContactListData?.data?.totalcount}
                            pageSize={ContactListData?.data?.perpage_count}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                    :
                    null
                }
                </div>
                </div>

            </section>

            {/* <CommonModal
                show={deleteModalOpen}
                onHide={() => {
                    setDeleteModalOpen(false)
                    setDeleteContestID("")
                }}
                modalClass="logout_modal"
                body={
                    <DeleteModal hide={setDeleteModalOpen} onSubmit={handleDeleteContest}
                        title="Are you delete?"
                        text="Are you sure you want to delete contest?"
                    />
                }
            /> */}
        </>
    )
}

export default ContactSupport