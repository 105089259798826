import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import "../landing.scss";

import { Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

function FeatureSlider() {
    return (
        <>


            <section className="featureSection">
                <h2 className="commonHeading">Features</h2>
                <div className="featureBack_title featureHeadingAnime">Features</div>

                <div className="featrueCard_row">
                    <div className="swiper featureSwiper">

                        <Swiper
                            modules={[Autoplay]}
                            slidesPerView={2}
                            speed={100}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                              }}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="featureCard">
                                        <div className="featureCard_col">
                                            <img src="/images/website/feature_1.jpg" alt="" />
                                        </div>
                                        <div className="featureCard_col">
                                            <div className="featureCard_col_content">
                                                <div className="featureTopTitles">
                                                    <h4>Easy Login</h4>
                                                    <p>Register using your mobile number and login via OTP</p>
                                                </div>
                                                <h3 className="mt-auto">01</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="featureCard">
                                        <div className="featureCard_col">
                                            <img src="/images/website/feature_2.jpg" alt="" />
                                        </div>
                                        <div className="featureCard_col">
                                            <div className="featureCard_col_content">
                                                <div className="featureTopTitles">
                                                    <h4>Upcoming Contests</h4>
                                                    <p>See all the live upcoming matches and create your top fantasy team and win big prizes</p>
                                                </div>
                                                <h3 className="mt-auto">02</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="featureCard">
                                        <div className="featureCard_col">
                                            <img src="/images/website/feature_3.jpg" alt="" />
                                        </div>
                                        <div className="featureCard_col">
                                            <div className="featureCard_col_content">
                                                <div className="featureTopTitles">
                                                    <h4>Play with extra coins</h4>
                                                    <p>Get reward coins on participating in the contests, use the coins to play more cash contests and win big or multiply your coins by playing coin contests</p>
                                                </div>
                                                <h3 className="mt-auto">03</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="featureCard">
                                        <div className="featureCard_col">
                                            <img src="/images/website/feature_4.jpg" alt="" />
                                        </div>
                                        <div className="featureCard_col">
                                            <div className="featureCard_col_content">
                                                <div className="featureTopTitles">
                                                    <h4>Private contest</h4>
                                                    <p>Create a private contest and play with your friends.</p> 
                                                </div>
                                                <h3 className="mt-auto">04</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="featureCard">
                                        <div className="featureCard_col">
                                            <img src="/images/website/feature_5.jpg" alt="" />
                                        </div>
                                        <div className="featureCard_col">
                                            <div className="featureCard_col_content">
                                                <div className="featureTopTitles">
                                                    <h4> Create super team</h4>
                                                    <p>Checkout our interactive team creator and select your players and create your top team. </p>
                                                </div>
                                                <h3 className="mt-auto">05</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="featureCard">
                                        <div className="featureCard_col">
                                            <img src="/images/website/feature_6.jpg" alt="" />
                                        </div>
                                        <div className="featureCard_col">
                                            <div className="featureCard_col_content">
                                                <div className="featureTopTitles">
                                                    <h4>Preview</h4>
                                                    <p>Check your team lineup before the big game or edit it to make the last minute changes</p>
                                                </div>
                                                <h3 className="mt-auto">06</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="featureCard">
                                        <div className="featureCard_col">
                                            <img src="/images/website/feature_7.jpg" alt="" />
                                        </div>
                                        <div className="featureCard_col">
                                            <div className="featureCard_col_content">
                                                <div className="featureTopTitles">
                                                    <h4>Live leaderboard</h4>
                                                    <p>Track your team's performance and you get real time ranking on the contest leaderboard.</p>
                                                </div>
                                                <h3 className="mt-auto">07</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                        </Swiper>
                    </div>


                </div>
            </section>
        </>
    );
}

export default FeatureSlider;