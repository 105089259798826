import React, { useState } from "react"
import TextErrorMsg from "../../Components/InputText/TextErrorMsg"
import { WalletBalanceUpdateAction } from "../../../features/CommonSlice"
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from "yup";
import { FieldText } from "../../Components/InputText/InputText";
import { Button } from "../../Components/Button/Button";
import { ToastOverError } from "../../../common/Toast/ToastOver";

const WalletManagerEdit = ({ data, hide, setStatusUpdate }) => {
    const [loader, setLoader] = useState(false)
    return (
        <div className='delete_modal_main'>
            <div className="name_cate">User Mobile:  <span>{data?.phone}</span></div>
            <div className="name_cate">User Amount:  <span> {data?.cash_balance} </span> </div>
            <div className="name_cate">Wallet Type:  <span>deposit</span></div>

            <div className="row g-4 mt-3">
                <Formik
                    initialValues={{
                        user_id: data?.id,
                        type: "add",
                        cash_balance: ""
                    }}
                    validationSchema={Yup.object().shape({
                        type: Yup.string().required('Type is required!'),
                        cash_balance: Yup.number().required('Amount is required!')
                    })}
                    onSubmit={(value) => {
                        setLoader(true)
                        if (value?.type == "deduct" && (data?.cash_balance - value?.cash_balance) < 0) {
                            ToastOverError("You cannot deduct an amount more than the current cash balance")
                            setLoader(false)
                            return <></>
                        }
                        WalletBalanceUpdateAction(value, (response) => {
                            if (response?.status) {
                                hide(false)
                                setStatusUpdate(prevCount => prevCount + 1);
                            }
                            setLoader(false)
                        })();
                    }}
                >
                    {(formik) => {
                        return (
                            <Form autoComplete='off'>
                                <div className="col-12">
                                    <FieldText
                                        name="cash_balance"
                                        type="text"
                                        placeholder="Amount"
                                        value={formik.values.cash_balance}
                                    />
                                </div>
                                <div className="col-12 mt-4">
                                    <Field
                                        as="select"
                                        name="type"
                                        className="select-control form-control w-100"
                                        value={formik.values.type}
                                    >
                                        {["Add", "Deduct"].map((value, index) => {
                                            return (
                                                <option value={value.toLowerCase()} key={index}> {value} </option>
                                            );
                                        })}
                                    </Field>
                                    <ErrorMessage name="type" component={TextErrorMsg} />
                                </div>

                                <div className='tow_button_card'>
                                    <button className='themeBtn w-auto m-0' type='button' onClick={() => hide(false)}> No</button>
                                    <Button
                                        className="themeBtn w-auto"
                                        text='Update'
                                        type="submit"
                                        loader={loader}
                                        disabled={!(formik.isValid && formik.dirty) || loader}
                                    />
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default WalletManagerEdit